// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/antd/dist/antd.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* a.nav-link:hover{
  background-color: #007bff !important;
} */
/* .nav-link:active,
.nav-link:focus {
  background-color: orange !important;
} */

.player-list .MuiTabs-flexContainer {
  display: flex !important;
  justify-content: space-between !important;
}
.courser {
  cursor: pointer;
}
.ant-picker-dropdown-placement-bottomLeft {
  left: 50% !important;
}

.nav-item {
  width: 100% !important;
}

::-webkit-scrollbar-track {
  display: 0px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  display: 0px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  display: 0px !important;
}

.sidebar::-webkit-scrollbar {
  width: 0px !important;
  /* Adjust this value to set the width of the scrollbar */
  background-color: transparent;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

.sidebar:hover {
  overflow-y: hidden;
}

.sidebar:hover::-webkit-scrollbar-thumb {
  background-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;GAEG;AACH;;;GAGG;;AAGH;EACE,wBAAwB;EACxB,yCAAyC;AAC3C;AACA;EACE,eAAe;AACjB;AACA;EACE,oBAAoB;AACtB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;AACzB;;AAEA,WAAW;AACX;EACE,uBAAuB;AACzB;;AAEA,oBAAoB;AACpB;EACE,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;EACrB,wDAAwD;EACxD,6BAA6B;AAC/B;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":["/* a.nav-link:hover{\n  background-color: #007bff !important;\n} */\n/* .nav-link:active,\n.nav-link:focus {\n  background-color: orange !important;\n} */\n@import \"~antd/dist/antd.css\";\n\n.player-list .MuiTabs-flexContainer {\n  display: flex !important;\n  justify-content: space-between !important;\n}\n.courser {\n  cursor: pointer;\n}\n.ant-picker-dropdown-placement-bottomLeft {\n  left: 50% !important;\n}\n\n.nav-item {\n  width: 100% !important;\n}\n\n::-webkit-scrollbar-track {\n  display: 0px !important;\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n  display: 0px !important;\n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n  display: 0px !important;\n}\n\n.sidebar::-webkit-scrollbar {\n  width: 0px !important;\n  /* Adjust this value to set the width of the scrollbar */\n  background-color: transparent;\n}\n\n.sidebar::-webkit-scrollbar-thumb {\n  background-color: rgba(0, 0, 0, 0.2);\n}\n\n.sidebar:hover {\n  overflow-y: hidden;\n}\n\n.sidebar:hover::-webkit-scrollbar-thumb {\n  background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
