import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AddIcon from "@mui/icons-material/Add";
// import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import Pagination from "../../Pagination/Pagination";
import { useAppContext } from "../../Lib/ContextLib";
// import { API_URL } from "../../config";
import Loader from "../Loader/Loader";
// import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
// import CloseIcon from "@mui/icons-material/Close";
import DataServices from "../../services/requestApi";
import { toast } from "react-toastify";

export default function RestrictedArea() {
  const { showPage, setShowPage } = useAppContext();
  const [alert, setAlert] = React.useState(null);
  const [tableData, setTableData] = React.useState([]);
  const [rowValue, setRowValue] = React.useState();
  const [openValue, setOpenValue] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  const [searchTourName] = React.useState();
  // const [file, setFile] = React.useState();
  // const [dragImage, setDragImage] = React.useState(true);
  const [showPrePage] = React.useState(10);
  // const [image, setImage] = React.useState(null);
  // const [fileBaseData, setFileBaseData] = React.useState(null);
  // const [imageFile, setImageFile] = React.useState();
  const [buttonLoader, setbuttonLoader] = React.useState(false);
  // const [changeImages, setChangeImages] = React.useState("0");
  const [pagination, setPagination] = React.useState({
    start: 0,
    end: showPrePage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const LoginSchema = Yup.object().shape({
    state: Yup.string().required("required"),
    country: Yup.string().required("required"),
    city: Yup.string().required("required"),
    note: Yup.string().required("required"),
  });

  React.useEffect(() => {
    getBranch();
  }, []);

  const getBranch = async () => {
    try {
      setLoader(true);
      const res = await DataServices.AllRestrictedAreaList();
      if (res.data.status) {
        setTableData(res.data.data);
        setLoader(false);
      }
    } catch (error) {
      toast.error("ERROR");
      console.log("ERROR::: ", error);
    }
  };

  const searchingData = [];
  let data = [];
  console.log("tableData 111 ---", tableData, searchTourName);
  if (searchTourName) {
    console.log("tableData 222 ---", tableData, tableData.length > 0);
    data = tableData.filter((item) => {
      return Object.values(item?.tourName)
        .join("")
        .includes(searchTourName.toLowerCase());
    });
    console.log("data 111 ---", data);
    data?.map((dataItem) => searchingData.push(dataItem));
  } else {
    tableData.map((dataItem) => searchingData.push(dataItem));
  }

  const clickEditButton = (item) => {
    setShowPage(true);
    console.log("item edit value :::", item.imageUrl);
    setRowValue(item);
    if (item.imageUrl) {
      // setDragImage(false);
      // setImage(item.imageUrl);
    } else {
      // setDragImage(true);
      // setImage("");
    }
    setOpenValue(2);
  };

  const openAddTournament = (value) => {
    setRowValue();
    console.log("openAddTournament :::", value);
    setShowPage(true);
    setOpenValue(value);
  };

  const updateTournament = async (value) => {
    setbuttonLoader(true);
    try {
      const res = await DataServices.UpdateRestrictedArea(rowValue._id, value);
      console.log("res::: ", res);
      if (res.data.status) {
        successEdit(res.data.message);
        setbuttonLoader(false);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log("error::: ", error);
      toast.error("ERROR");
      setbuttonLoader(false);
    }
    // setDragImage(true);
    // let merged = { ...value, id: rowValue._id };
    // console.log("updateTournament merged value :::", merged);

    // axios
    //   .post(`${API_URL}/api/restrictedarea/update-restrictedarea`, merged)
    //   .then((res) => {
    //     console.log("update :::", res.data);
    //     if (res.data.isValid) {
    //       successAdd();
    //       setbuttonLoader(false);
    //     } else {
    //       warningAlert();
    //       setbuttonLoader(false);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("update err :::", err);
    //     warningAlert();
    //   });
  };

  const addNewTournament = async (value) => {
    try {
      setbuttonLoader(true);
      const res = await DataServices.AddRestrictedArea(value);
      console.log("res::: ", res);
      if (res?.data.status) {
        successAdd(res?.data.message);
        setbuttonLoader(false);
      } else {
        toast.error(res?.data.message);
        setbuttonLoader(false);
      }
    } catch (error) {
      toast.error("ERROR");
      console.log("error::: ", error);
      setbuttonLoader(false);
    }
    // console.log("addNewTournament ---", value);
    // let merged = { ...value };
    // console.log("merged value :::", merged);

    // axios(`${API_URL}/api/restrictedarea/add-restrictedarea`, {
    //   method: "POST",
    //   credentials: "include",
    //   data: merged,
    // })
    //   .then((result) => {
    //     console.log("handleSubmit result :::", result.data);
    //     if (result.data.isValid) {
    //       setbuttonLoader(false);
    //       successAdd();
    //     } else {
    //       warningAlert();
    //       setbuttonLoader(false);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("handleSubmit err :::", err);
    //     warningAlert();
    //   });
  };

  const warningWithConfirmMessage = (e) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title="Are you sure?"
        onConfirm={() => {
          deleteData(e);
          //  setIsPlayerEditButtonClicked(false);
        }}
        onCancel={() => setAlert(null)}
        // confirmBtnCssClass={classes.button + " " + classes.danger}
        // cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      ></SweetAlert>
    );
  };

  const successDeleted = (msg) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title={<h4>{msg}</h4>}
        onConfirm={() => {
          getBranch();
          setAlert(null);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      ></SweetAlert>
    );
  };

  const deleteData = async (e) => {
    try {
      const res = await DataServices.DeleteRestrictedArea(e._id);
      console.log("Delete res::: ", res);
      if (res?.data.status) {
        successDeleted(res?.data.message);
      }
    } catch (error) {}
    // console.log("e===>", e._id);
    // axios
    //   .post(`${API_URL}/api/restrictedarea/delete-restrictedarea/${e._id}`)
    //   .then((res) => {
    //     console.log(res);
    //     console.log(res.data);
    //     getBranch();
    //   });
  };

  const successAdd = (msg) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title={<h4>{msg}</h4>}
        onConfirm={() => {
          getBranch();
          setAlert(null);
          setShowPage(false);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      ></SweetAlert>
    );
  };

  const successEdit = (msg) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title={<h4>{msg}</h4>}
        onConfirm={() => {
          getBranch();
          setAlert(null);
          setShowPage(false);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      ></SweetAlert>
    );
  };

  // const warningAlert = () => {
  //   console.log("donw");
  //   setAlert(
  //     <SweetAlert
  //       warning
  //       style={{ display: "block" }}
  //       title="Something Went Wrong .....!"
  //       onConfirm={() => {
  //         //  getBranch();
  //         setAlert(null);
  //         //  setShowPage(false);
  //         // setIsPlayerEditButtonClicked(false);
  //       }}
  //       confirmBtnBsStyle="success"
  //     ></SweetAlert>
  //   );
  // };

  return (
    <>
      {alert}
      {showPage ? (
        <div class="content p-3 ">
          <div className="card p-3">
            <Box sx={{ width: "100%" }}>
              <Formik
                initialValues={{
                  state: rowValue ? rowValue?.state : "",
                  country: rowValue ? rowValue?.country : "",
                  city: rowValue ? rowValue?.city : "",
                  note: rowValue ? rowValue?.note : "",
                }}
                validationSchema={LoginSchema}
                onSubmit={(values) => {
                  setbuttonLoader(true);
                  console.log(values);

                  if (parseInt(openValue) === 1) {
                    addNewTournament(values);
                  }
                  if (parseInt(openValue) === 2) {
                    updateTournament(values);
                  }
                }}
              >
                {({ touched, errors, isSubmitting, values, setFieldValue }) => (
                  <div>
                    <Form>
                      <div className="form-group">
                        <label htmlFor="password" className="">
                          City
                        </label>
                        <Field
                          type="text"
                          name="city"
                          placeholder="Enter City "
                          className={`mt-2 form-control
                          ${touched.city && errors.city ? "is-invalid" : ""}`}
                        />
                        <ErrorMessage
                          component="div"
                          name="city"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password" className="">
                          State
                        </label>
                        <Field
                          type="text"
                          name="state"
                          placeholder="Enter State Name"
                          className={`mt-2 form-control
                          ${touched.state && errors.state ? "is-invalid" : ""}`}
                        />
                        <ErrorMessage
                          component="div"
                          name="state"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password" className="">
                          Country
                        </label>
                        <Field
                          type="text"
                          name="country"
                          placeholder="Enter Country Name"
                          className={`mt-2 form-control
                          ${
                            touched.country && errors.country
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="country"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="password" className="">
                          Note
                        </label>
                        <Field
                          type="text"
                          name="note"
                          placeholder="Enter Note "
                          className={`mt-2 form-control
                          ${touched.note && errors.note ? "is-invalid" : ""}`}
                        />
                        <ErrorMessage
                          component="div"
                          name="note"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="d-flex">
                        <button
                          style={{ width: "100px" }}
                          type="submit"
                          className="btn btn-primary btn-block mt-4 mr-2"
                          disabled={buttonLoader}
                        >
                          <div
                            className="d-flex"
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              className="d-flex"
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <b>Submit</b>
                            </div>
                            {buttonLoader && (
                              <div>
                                <div
                                  class="spinner-border ml-2 mt-1"
                                  role="status"
                                  style={{ height: "20px", width: "20px" }}
                                ></div>
                              </div>
                            )}
                          </div>
                        </button>
                        <button
                          style={{ width: "100px" }}
                          onClick={() => setShowPage(false)}
                          type="button"
                          className="btn btn-secondary btn-block mt-4 ms-2"
                        >
                          Close
                        </button>
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </Box>
          </div>
        </div>
      ) : (
        <div className="content p-3">
          <div class="card ">
            {/* <div
              class="pl-4 pr-4 pt-4"
              style={{ display: "flex", justifyContent: "space-between" }}
                          > */}
            <div className="row pl-4 pr-4 pt-4">
              <div className="col-5">
                {/* {accessObject?.addRestrictedArea == "true" && (
                  <button
                    style={{ width: "118px" }}
                    onClick={() => {
                      openAddTournament(1);
                      setImage(null);
                      setDragImage(true);
                    }}
                    type="button"
                    className="btn btn-primary btn-block "
                  >
                    <AddIcon />
                    Add New
                  </button>
                )} */}
                <button
                  style={{ width: "118px" }}
                  onClick={() => {
                    openAddTournament(1);
                    // setImage(null);
                    // setDragImage(true);
                  }}
                  type="button"
                  className="btn btn-primary btn-block "
                >
                  <AddIcon />
                  Add New
                </button>
              </div>
              <div className="col-7">
                <h3 class="card-title">
                  <b>Restricted Area List</b>
                </h3>
              </div>
            </div>

            {/* <div style={{ display: "none" }}>
                <b className="mr-2">Tournament Name :</b>
                <input
                  placeholder="Enter Tournament Name"
                  value={searchSubType}
                  onChange={(e) => setSearchTourName(e.target.value)}
                />
              </div> */}
            {/* </div> */}

            <div class="card-body">
              <table id="" class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>SrNo</th>
                    <th>City </th>
                    <th>State </th>
                    <th>Country </th>
                    <th>Note</th>
                    {/* {(accessObject?.editRestrictedArea == "true" ||
                      accessObject?.deleteRestrictedArea == "true") && (
                      <th style={{ textAlign: "center" }}>Action</th>
                    )} */}
                    <th>Action</th>
                  </tr>
                </thead>
                {true ? (
                  <tbody>
                    {!loader ? (
                      <>
                        {searchingData.length > 0 ? (
                          <>
                            {searchingData
                              ?.slice(pagination.start, pagination.end)
                              .map((item, index) => (
                                <>
                                  <tr key={index}>
                                    <td>{index + 1 + pagination.start}</td>
                                    <td>{item.city}</td>
                                    <td>{item.state}</td>
                                    <td>{item.country}</td>
                                    <td>{item.note}</td>

                                    {/* {(accessObject?.editRestrictedArea ==
                                      "true" ||
                                      accessObject?.deleteRestrictedArea ==
                                        "true") && (
                                      <td className="justify-content-evenly ">
                                        <div className="d-flex">
                                          {accessObject?.editRestrictedArea ==
                                            "true" && (
                                            <EditIcon
                                              className="mr-3 courser"
                                              onClick={() =>
                                                clickEditButton(item)
                                              }
                                            />
                                          )}
                                          {accessObject?.deleteRestrictedArea ==
                                            "true" && (
                                            <ClearIcon
                                              className="courser text-danger"
                                              onClick={() =>
                                                warningWithConfirmMessage(item)
                                              }
                                            />
                                          )}
                                        </div>
                                      </td>
                                    )} */}
                                    <td>
                                      <EditIcon
                                        className="mr-3 courser"
                                        onClick={() => clickEditButton(item)}
                                      />

                                      <ClearIcon
                                        className="courser text-danger"
                                        onClick={() =>
                                          warningWithConfirmMessage(item)
                                        }
                                      />
                                    </td>
                                  </tr>
                                </>
                              ))}
                          </>
                        ) : (
                          <>
                            <tr
                              style={{ backgroundColor: "whitesmoke" }}
                              className="text-center"
                            >
                              <td colSpan={6}>
                                <h5>No Data Available</h5>
                              </td>
                            </tr>
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </tbody>
                ) : (
                  <>
                    <tr
                      style={{ backgroundColor: "rgba(0,0,0,.05)" }}
                      className="text-center"
                    >
                      <td colSpan={6}>
                        <h5>Access Denied</h5>
                        {/* <h5>No Data Available</h5> */}
                      </td>
                    </tr>
                  </>
                )}
              </table>
              <div className="mt-4">{loader && <Loader />}</div>
            </div>
            {searchingData.length !== 0 && (
              <Pagination
                showPrePage={showPrePage}
                onPaginationChange={onPaginationChange}
                total={searchingData.length}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
