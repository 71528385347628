import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { API_URL } from "../../config";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import Pagination from "../../Pagination/Pagination";
import { useAppContext } from "../../Lib/ContextLib";
import Loader from "../Loader/Loader";
import Home from "./Home";
import Test from "./Test";
// import VerifiedPlayer from "./VerifiedPlayer";
import RefferalList from "./RefferalList";
import History from "./HistoryList";
// import socketIO from "socket.io-client";
import { useLocation } from "react-router-dom";
import DataServices from "../../services/requestApi";
import { toast } from "react-toastify";
import ClearIcon from "@mui/icons-material/Clear";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PlayerList() {
  const { showPage, setShowPage, accessObject, addNewPlayer, setAddNewPlayer } =
    useAppContext();

  const { state } = useLocation();
  let { data } = state;
  console.log("useLocation :::", data);

  // console.log("accessObject player list :::", accessObject);
  // let socket = socketIO(API_URL, { transports: ["websocket"] });
  const [alert, setAlert] = React.useState(null);
  const [rowValue, setRowValue] = React.useState();
  const [value, setValue] = React.useState(0);
  const [tableData, setTableData] = React.useState([]);
  const [showPrePage, setShowPrePage] = React.useState(10);
  const [loader, setLoader] = React.useState(true);
  const [buttonLoader, setButtonLoader] = React.useState(false);
  const [filteredData, setFilteredData] = React.useState([]);
  const [historyData, setHistoryData] = React.useState([]);
  // const [changeStatus, setChangeStatus] = React.useState("All");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateShowPerpagevalue = (value) => {
    setShowPrePage(value);
    setPagination({ start: 0, end: value });
  };

  const [pagination, setPagination] = React.useState({
    start: 0,
    end: showPrePage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  React.useEffect(() => {
    getPlayerList();
  }, [showPage]);

  const getPlayerList = async () => {
    setLoader(true);
    try {
      const res = await DataServices.AllPlayerList();
      console.log("data::: ", res);
      setLoader(false);
      setTableData(res.data.data);
      setFilteredData(res.data.data);
    } catch (error) {
      toast.error("Error");
    }
  };

  const errorMessageDialog = (message) => {
    setAlert(
      <SweetAlert
        error
        style={{ display: "block" }}
        title={message}
        onConfirm={() => {
          setAlert(null);
          setButtonLoader(false);
        }}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="danger"
      ></SweetAlert>
    );
  };

  // const successDeleted = () => {
  //   console.log("donw");
  //   setAlert(
  //     <SweetAlert
  //       success
  //       style={{ display: "block" }}
  //       title="Deleted!"
  //       onConfirm={() => {
  //         getPlayerList();
  //         setAlert(null);
  //       }}
  //       confirmBtnBsStyle="success"
  //     >
  //       Your Player Record has been deleted
  //     </SweetAlert>
  //   );
  // };

  // const deleteData = (e) => {
  //   console.log("e===>", e._id);
  //   axios.get(`${API_URL}/api/player/delete-player/${e._id}`).then((res) => {
  //     console.log(res);
  //     console.log("res :::", res);
  //     const data = {
  //       playerId: e.playerId,
  //     };
  //     if (res.data.isValid) {
  //       successDeleted();
  //       socket.emit("PlayerDetailsDelete", data);
  //     }
  //     console.log(res.data);
  //   });
  // };

  const successEdit = (message) => {
    console.log("successEdit ---", message);
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title={message}
        onConfirm={() => {
          getPlayerList();
          setAlert(null);
        }}
        confirmBtnBsStyle="success"
      >
        `{message ? message : "Your Player Record has been Edited"}`
      </SweetAlert>
    );
  };

  const clickEditButton = async (item) => {
    setShowPage(true);
    setValue(0);
    console.log("item edit value :::", item);
    setRowValue(item);
    try {
      // AllHistoryList;
      const res = await DataServices.AllHistoryList({
        playerId: item.playerId,
      });
      setHistoryData(res?.data.data);
    } catch (error) {}
    // const getHistoryData = await axios.post(
    //   `${API_URL}/api/history/get-single-player-history`,
    //   {
    //     playerId: item.playerId,
    //   }
    // );
    // console.log("getHistoryData::: ", getHistoryData);
    // setHistoryData(getHistoryData.data.message);
  };

  const LoginSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("email required"),
    password: Yup.string().required("password is required"),
  });

  const successAdd = (message) => {
    console.log("successAdd ---", message);
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title="Added successfully!"
        onConfirm={() => {
          getPlayerList();
          setAlert(null);
          setAddNewPlayer(false);
          setButtonLoader(false);
        }}
        confirmBtnBsStyle="success"
      >
        `{message ? message : "Your Player Record has been Added ..."}`
      </SweetAlert>
    );
  };

  // Event handler for filtering the data
  const handleFilter = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    console.log("searchQuery::: ", searchQuery);
    console.log("tableData::: ", tableData);
    const filtered = tableData.filter((item) => {
      console.log("item::: ", item);
      return (
        item.email?.toLowerCase().includes(searchQuery) ||
        item.playerId?.toLowerCase().includes(searchQuery)
      );
    });
    setFilteredData(filtered);
  };

  // const handleChangeDeviceType = (value) => {
  //   const searchQuery = value;
  //   data = false;
  //   console.log("searchQuery::: ", searchQuery);
  //   if (searchQuery === "All") {
  //     // setFilteredData(tableData);
  //     getPlayerList();
  //   } else if (searchQuery === "android") {
  //     const filteredData = tableData.filter(
  //       (item) => item.deviceType === "android"
  //     );
  //     setFilteredData(filteredData);
  //   } else if (searchQuery === "iphone") {
  //     console.log("iphone true...");
  //     const filteredData = tableData.filter(
  //       (item) => item.deviceType === "iphone"
  //     );
  //     setFilteredData(filteredData);
  //   }
  // };

  const deleteData = async (e) => {
    console.log("e===>", e._id);
    const res = await DataServices.DeletePlayer(e._id);
    if (res.status) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  const warningWithConfirmMessage = (e) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title="Are you sure?"
        onConfirm={() => {
          deleteData(e);
          successDeleted();
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      ></SweetAlert>
    );
  };

  const successDeleted = () => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title="Deleted!"
        onConfirm={() => {
          getPlayerList();
          setAlert(null);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      >
        Your Player Record has been deleted
      </SweetAlert>
    );
  };

  return (
    <>
      {alert}
      {addNewPlayer ? (
        <>
          <div class="content p-3 ">
            <div className="card p-3">
              <Box sx={{ width: "100%" }}>
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    password: "",
                  }}
                  validationSchema={LoginSchema}
                  onSubmit={(values) => {
                    setButtonLoader(true);
                    axios(`${API_URL}/api/player/add-player`, {
                      method: "POST",
                      credentials: "include",
                      data: values,
                    })
                      .then((result) => {
                        console.log("handleSubmit result :::", result.data);
                        if (result.data.isValid === true) {
                          successAdd(result.data.message);
                        } else {
                          errorMessageDialog(result.data.message);
                        }
                      })
                      .catch((err) => {
                        console.log("handleSubmit err :::", err);
                        alert("something went wrong...");
                      });
                  }}
                >
                  {({
                    touched,
                    errors,
                    isSubmitting,
                    values,
                    setFieldValue,
                  }) => (
                    <div>
                      <Form>
                        <div className="form-group">
                          <label htmlFor="password">Name</label>
                          <Field
                            type="text"
                            name="name"
                            placeholder="Name"
                            className={`mt-2 form-control
                          ${touched.name && errors.name ? "is-invalid" : ""}`}
                          />
                          <ErrorMessage
                            component="div"
                            name="name"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="password">Email</label>
                          <Field
                            type="text"
                            name="email"
                            placeholder="email"
                            className={`mt-2 form-control
                          ${touched.email && errors.email ? "is-invalid" : ""}`}
                          />
                          <ErrorMessage
                            component="div"
                            name="email"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="password">Password</label>
                          <Field
                            type="text"
                            name="password"
                            placeholder="Password"
                            className={`mt-2 form-control
                          ${
                            touched.password && errors.password
                              ? "is-invalid"
                              : ""
                          }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="password"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="d-flex">
                          <button
                            style={{ width: "100px" }}
                            type="submit"
                            className="btn btn-primary btn-block mt-4 mr-2"
                            disabled={buttonLoader}
                          >
                            <div
                              className="d-flex"
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                className="d-flex"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <b>Submit</b>
                              </div>
                              {buttonLoader && (
                                <div>
                                  <div
                                    class="spinner-border ml-2 mt-1"
                                    role="status"
                                    style={{ height: "20px", width: "20px" }}
                                  ></div>
                                </div>
                              )}
                            </div>
                          </button>
                          <button
                            style={{ width: "100px" }}
                            onClick={() => setAddNewPlayer(false)}
                            type="button"
                            className="btn btn-secondary btn-block mt-4 ms-2"
                          >
                            Close
                          </button>
                        </div>
                      </Form>
                    </div>
                  )}
                </Formik>
              </Box>
            </div>
          </div>
        </>
      ) : (
        <>
          {showPage ? (
            <div class="content p-3 ">
              <div className="card p-3">
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                    }}
                    className="player-list"
                  >
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      scrollButtons="auto"
                      variant="scrollable"
                    >
                      <Tab
                        label="Home"
                        {...a11yProps(0)}
                        style={{ width: "190px" }}
                      />

                      {/* <Tab
                        label="KYC Player"
                        {...a11yProps(1)}
                        style={{ width: "190px" }}
                      /> */}
                      <Tab
                        label="History List"
                        {...a11yProps(1)}
                        style={{ width: "190px" }}
                      />
                      <Tab
                        label="Refferal List"
                        {...a11yProps(2)}
                        style={{ width: "190px" }}
                      />

                      <Tab
                        label="Test"
                        {...a11yProps(3)}
                        style={{ width: "190px" }}
                      />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                    <Home rowValue={rowValue} />
                  </TabPanel>
                  {/* <TabPanel value={value} index={1}>
                    <VerifiedPlayer rowValue={rowValue} />
                  </TabPanel> */}
                  <TabPanel value={value} index={1}>
                    <History historyData={historyData} />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <RefferalList playerId={rowValue.playerId} />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <Test rowValue={rowValue} successEdit={successEdit} />
                  </TabPanel>
                </Box>
              </div>
            </div>
          ) : (
            <div className="content p-3">
              <div class="card ">
                <div class="pl-4 pr-4 pt-4">
                  <div
                    class="pl-4 pr-4 pt-4"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h3 class="card-title">
                      <b>Player List</b>
                    </h3>
                    <div>
                      {/* Device Type */}
                      {/* <div
                        className="form-group d-flex"
                        style={{ alignItems: "end" }}
                      >
                        <label style={{ width: "180px" }}>
                          <b className="px-2">Device Type</b>
                        </label>
                        <select
                          className={`mt-2 form-control`}
                          onChange={(e) =>
                            handleChangeDeviceType(e.target.value)
                          }
                          value={changeDeviceType}
                        >
                          <option value="All">All</option>
                          <option value="android">Android</option>
                          <option value="iphone">Iphone</option>
                        </select>
                      </div> */}
                    </div>
                    <div>
                      <input
                        class="input-simple"
                        type="text"
                        placeholder="email And player id ..."
                        onChange={handleFilter}
                      />
                    </div>
                  </div>
                </div>
                <div class="card-body" style={{ overflow: "scroll" }}>
                  <table id="" class="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>SrNo</th>
                        <th>Date</th>
                        <th>Id</th>
                        <th>User Name</th>
                        <th>Email</th>
                        <th>Status</th>
                        {/* <th >KYC Status</th> */}
                        <th>Device Type</th>
                        {(accessObject?.playerListEdit === "true" ||
                          accessObject?.playerListDelete === "true") && (
                          <th>Action</th>
                        )}
                      </tr>
                    </thead>
                    {accessObject?.getPlayerList === "true" ? (
                      <>
                        <tbody>
                          {!loader ? (
                            <>
                              {console.log("filteredData::: ", filteredData)}
                              {filteredData.length > 0 ? (
                                <>
                                  {filteredData
                                    ?.slice(pagination.start, pagination.end)
                                    .map((item, index) => {
                                      return (
                                        <>
                                          <tr key={index}>
                                            <td>
                                              {index + 1 + pagination.start}
                                            </td>
                                            <td>
                                              {moment(item?.createdAt).format(
                                                "DD/MM/YYYY"
                                              )}
                                            </td>

                                            <td>{item?.playerId}</td>
                                            <td>{item?.username || "-"}</td>
                                            <td>{item?.email || "-"}</td>

                                            <td>{item?.playerStatus}</td>
                                            {/* <td>
                                              {item.kycStatus
                                                ? item.kycStatus
                                                : "-"}
                                            </td> */}
                                            <td>
                                              {item?.deviceModel
                                                ? item?.deviceModel
                                                : "-"}
                                            </td>
                                            {(accessObject?.playerListEdit ===
                                              "true" ||
                                              accessObject?.playerListDelete ===
                                                "true") && (
                                              <td className="justify-content-evenly ">
                                                <div className="d-flex">
                                                  {accessObject?.playerListEdit ===
                                                    "true" && (
                                                    <EditIcon
                                                      className="mr-3 courser"
                                                      onClick={() =>
                                                        clickEditButton(item)
                                                      }
                                                    />
                                                  )}
                                                  {accessObject?.playerListDelete ===
                                                    "true" && (
                                                    <ClearIcon
                                                      className="courser text-danger"
                                                      onClick={() =>
                                                        warningWithConfirmMessage(
                                                          item
                                                        )
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              </td>
                                            )}
                                          </tr>
                                        </>
                                      );
                                    })}
                                </>
                              ) : (
                                <>
                                  <tr>
                                    <td colSpan={10}>
                                      <h5 className="text-center">
                                        No Data Available
                                      </h5>
                                    </td>
                                  </tr>
                                </>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </tbody>
                      </>
                    ) : (
                      <>
                        <tr style={{ backgroundColor: "rgba(0,0,0,.05)" }}>
                          <td colSpan={8}>
                            <h5 className="text-center">Access Denied</h5>
                          </td>
                        </tr>
                      </>
                    )}
                  </table>
                  {/* {accessObject?.getPlayerList == "true" && (
                    <div className="mt-4">{loader && <Loader />}</div>
                  )} */}
                  <div className="mt-4">{loader && <Loader />}</div>
                </div>
                {accessObject?.getPlayerList === "true" &&
                  filteredData.length !== 0 && (
                    <div>
                      <Pagination
                        updateShowPerpagevalue={updateShowPerpagevalue}
                        showPrePage={showPrePage}
                        onPaginationChange={onPaginationChange}
                        total={filteredData.length}
                      />
                    </div>
                  )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
