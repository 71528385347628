import axiosInstance from "./http-common";

class DataService {
  IsToken() {
    return axiosInstance.get("auth/token-verify");
  }
  //   Login Services
  Login(data) {
    return axiosInstance.post("auth/login", data);
  }
  //Admin
  SignUp(data) {
    return axiosInstance.post("auth/signup", data);
  }
  AllAdminList() {
    return axiosInstance.get("auth/get-all-admin-list");
  }
  //Player
  AllPlayerList() {
    return axiosInstance.get("player/get-all-player-details");
  }
  AllGuestUserList() {
    return axiosInstance.get("player/get-all-guest-player-details");
  }

  AllHistoryList(data) {
    return axiosInstance.post("player/get-player-all-history", data);
  }

  GuestHistoryListById(data) {
    return axiosInstance.post(`player/get-guest-player-details`, data);
  }
  AllReferList(data) {
    return axiosInstance.post("player/get-player-refer-list", data);
  }
  ChangePlayerStatus(id, data) {
    return axiosInstance.patch(`player/update-player-status/${id}`, data);
  }

  DeletePlayer(id) {
    return axiosInstance.delete(`player/delete-player/${id}`);
  }

  DeleteGuestPlayer(id) {
    return axiosInstance.delete(`player/delete-guest-player/${id}`);
  }

  // Game
  AllGameList() {
    return axiosInstance.get("game/get-all-game-details");
  }
  AddNewGame(data) {
    return axiosInstance.post("game/add-new-game-details", data);
  }
  UpdateGame(id, data) {
    return axiosInstance.patch(`game/edit-game-details/${id}`, data);
  }
  DeleteGame(id) {
    return axiosInstance.delete(`game/delete-game-details/${id}`);
  }
  UploadGame(id, data) {
    return axiosInstance.post(`game/${id}/upload`, data);
  }

  //Version

  AllVersionList() {
    return axiosInstance.get("version/get-all-version-details");
  }
  UpdateVersion(id, data) {
    return axiosInstance.patch(`version/edit-version-details/${id}`, data);
  }
  //setting
  GetAllSettingList() {
    return axiosInstance.get("setting/get-all-setting-details");
  }
  UpdateSettingDetails(id, data) {
    return axiosInstance.patch(`setting/edit-setting-details/${id}`, data);
  }
  //spin wheel

  AllSpinWheelList() {
    return axiosInstance.get("spinwheel/get-all-spinwheel-details");
  }
  AddNewSpin(data) {
    return axiosInstance.post("spinwheel/add-new-spinwheel-details", data);
  }
  UpdateSpin(id, data) {
    return axiosInstance.patch(`spinwheel/edit-spinwheel-details/${id}`, data);
  }
  DeleteSpin(id) {
    return axiosInstance.delete(`spinwheel/delete-spinwheel-details/${id}`);
  }

  //Coin Shop

  AllCoinShopList() {
    return axiosInstance.get("coin-shop/get-all-coin-shop-details");
  }
  AddCoinShop(data) {
    return axiosInstance.post("coin-shop/add-new-coin-shop-details", data);
  }
  UpdateCoinShop(id, data) {
    return axiosInstance.patch(`coin-shop/edit-coin-shop-details/${id}`, data);
  }
  DeleteCoinShop(id) {
    return axiosInstance.delete(`coin-shop/delete-coin-shop-details/${id}`);
  }

  //restricted area

  AllRestrictedAreaList() {
    return axiosInstance.get("restricted-area/get-all-restricted-areas");
  }
  AddRestrictedArea(data) {
    return axiosInstance.post("restricted-area/add-new-restricted-area", data);
  }
  UpdateRestrictedArea(id, data) {
    return axiosInstance.patch(
      `restricted-area/update-restricted-area/${id}`,
      data
    );
  }
  DeleteRestrictedArea(id) {
    return axiosInstance.delete(`restricted-area/delete-restricted-area/${id}`);
  }

  //social

  GetAllSocialList() {
    return axiosInstance.get("social/get-all-social-details");
  }
  UpdateSocialDetails(id, data) {
    return axiosInstance.patch(`social/edit-social-details/${id}`, data);
  }

  //game analytics
  GetGameAnalytics(data) {
    return axiosInstance.post("history/get-gameAnalytics", data);
  }
}

const DataServices = new DataService();

export default DataServices;
