import React from "react";
import axios from "axios";
import Pagination from "../../Pagination/Pagination";
import { API_URL } from "../../config";
// import Loader from "../Loader/Loader";
import { useAppContext } from "../../Lib/ContextLib";
import AccordionItem from "./AccordionItem";

export default function IncomeDetails() {
  const { accessObject } = useAppContext();
  const [dataRows, setdataRows] = React.useState([]);
  const [searchPhoneNo] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [showPrePage, setShowPrePage] = React.useState(10);
  const [pagination, setPagination] = React.useState({
    start: 0,
    end: showPrePage,
  });
  const [filteredData, setFilteredData] = React.useState([]);
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const updateShowPerpagevalue = (value) => {
    setShowPrePage(value);
    setPagination({ start: 0, end: value });
  };
  const [expandedItemIndex, setExpandedItems] = React.useState([]);

  const handleToggle = (index) => {
    setExpandedItems((prevIndex) => (prevIndex === index ? null : index));
  };

  React.useEffect(() => {
    getBranch();
  }, []);

  const getBranch = () => {
    setLoader(true);
    axios(`${API_URL}/api/income/get-all-income-details`, {
      method: "GET",
      credentials: "include",
    })
      .then(function (response) {
        setLoader(false);
        console.log("learderBoardDynamic :::", response.data.message);
        setdataRows(response.data.message);
        setFilteredData(response.data.message);
      })
      .catch(function (error) {
        console.log("getBranch error :::", error);
      });
  };

  const searchingData = [];
  let data = [];
  if (searchPhoneNo) {
    data = dataRows.filter((item) => {
      return Object.values(item?.email)
        .join("")
        .includes(searchPhoneNo.toLowerCase());
    });
    data.map((dataItem) => searchingData.push(dataItem));
  } else {
    dataRows.map((dataItem) => searchingData.push(dataItem));
  }

  const handleFilter = (event) => {
    const searchQuery = event.target.value;
    console.log("tableData::: ", dataRows);
    const filtered = dataRows.filter((item) => {
      return item.roundName.includes(searchQuery);
    });
    setFilteredData(filtered);
  };

  return (
    <div>
      <div className="content p-3">
        <div class="card ">
          <div class="pl-4 pr-4 pt-4">
            <div className="row">
              <div className="col-md-4 col-lg-4">
                <h3 class="card-title">
                  <b>Income Details</b>
                </h3>
              </div>
              <div
                className="col-md-4 col-lg-4 d-flex justify-content-start justify-content-lg-center "
                style={{ padding: "10px" }}
              >
                {/* <div className="d-flex">
                  <RangePicker renderExtraFooter={() => "extra footer"} />
                  <button
                    className="btn btn-primary mr-2 ml-2 "
                    onClick={() => {
                      const input = document.getElementById("download-PDF");
                      const opt = {
                        filename: "History.pdf",
                      };
                      html2pdf().set(opt).from(input).save().from().toPdf();
                    }}
                  >
                    PDF
                  </button>
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button btn btn-info"
                    table="table-to-xls"
                    filename="History"
                    sheet="History"
                    buttonText="Excel"
                  />
                </div> */}
              </div>
              <div className="col-md-4 col-lg-4 d-flex justify-content-start justify-content-lg-end">
                <div>
                  <input
                    class="input-simple"
                    type="text"
                    placeholder="Game Name"
                    onChange={(e) => handleFilter(e)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="card-body" style={{ overflow: "scroll" }}>
            <table id="" class="table table-bordered ">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>Sr No</th>
                  <th style={{ textAlign: "center" }}>Date</th>
                  <th style={{ textAlign: "center" }}>Round Name</th>
                  <th style={{ textAlign: "center" }}>Amount</th>
                  <th style={{ textAlign: "center" }}>Action</th>
                </tr>
              </thead>
              {console.log(
                "accessObject?.getIncomeAviatorList::: ",
                accessObject?.getIncomeAviatorList
              )}
              {accessObject?.getIncomeList === "true" ? (
                <>
                  {!loader ? (
                    <>
                      {filteredData.length > 0 ? (
                        <>
                          {filteredData
                            ?.slice(pagination.start, pagination.end)
                            .map((row, index) => (
                              <>
                                <AccordionItem
                                  index={index}
                                  row={row}
                                  paginationStart={pagination.start}
                                  isExpanded={expandedItemIndex === index}
                                  onToggle={() => handleToggle(index)}
                                />
                              </>
                            ))}
                        </>
                      ) : (
                        <>
                          <tr style={{ backgroundColor: "whitesmoke" }}>
                            <td colSpan={6}>
                              <h5 className="text-center">No Data Available</h5>
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  <tr style={{ backgroundColor: "rgba(0,0,0,.05)" }}>
                    <td colSpan={6}>
                      <h5 className="text-center">Access Denied</h5>
                    </td>
                  </tr>
                </>
              )}
              <tr style={{ backgroundColor: "whitesmoke" }}>
                <td colSpan={6}>
                  <h5 className="text-center">No Data Available</h5>
                </td>
              </tr>
            </table>
            {/* {accessObject?.getIncomeList == "true" && (
              <div className="mt-4">{loader && <Loader />}</div>
            )} */}
          </div>
          {accessObject?.getIncomeList === "true" &&
            filteredData.length !== 0 && (
              <Pagination
                updateShowPerpagevalue={updateShowPerpagevalue}
                showPrePage={showPrePage}
                onPaginationChange={onPaginationChange}
                total={filteredData.length}
              />
            )}
        </div>
      </div>
    </div>
  );
}
