import React from "react";
import Pagination from "../../Pagination/Pagination";
import moment from "moment";
// import Loader from "../Loader/Loader";
// import { useEffect } from "react";

export default function HistoryList({ historyData }) {
  // const [loader, setLoader] = React.useState(true);
  const [showPrePage, setShowPrePage] = React.useState(10);
  const [pagination, setPagination] = React.useState({
    start: 0,
    end: showPrePage,
  });
  const [filteredOptions, setFilteredOptions] = React.useState([]);
  // const [sortingValue, setSortingValue] = React.useState("");

  React.useEffect(() => {
    setFilteredOptions(historyData);
  }, [filteredOptions, historyData]);

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const updateShowPerpagevalue = (value) => {
    setShowPrePage(value);
    setPagination({ start: 0, end: value });
  };

  // const handleSelectChange = (event) => {
  //   const selectedValue = event.target.value;
  //   setSortingValue(event.target.value);
  //   console.log("selectedValue::: ", selectedValue);

  //   // Filter the data based on the selected value
  //   if (selectedValue === "addmoney") {
  //     const filteredData = historyData.filter(
  //       (item) => item.note === selectedValue
  //     );
  //     setFilteredOptions(filteredData);
  //   } else if (selectedValue === "withdraw") {
  //     const filteredData = historyData.filter(
  //       (item) => item.note === selectedValue
  //     );
  //     setFilteredOptions(filteredData);
  //   } else if (selectedValue === "transfer") {
  //     const filteredData = historyData.filter(
  //       (item) => item.note === selectedValue
  //     );
  //     setFilteredOptions(filteredData);
  //   } else if (selectedValue === "andarbahar") {
  //     const filteredData = historyData.filter(
  //       (item) => item.gameType === selectedValue
  //     );
  //     setFilteredOptions(filteredData);
  //   } else if (selectedValue === "aviator") {
  //     const filteredData = historyData.filter(
  //       (item) => item.gameType === selectedValue
  //     );
  //     setFilteredOptions(filteredData);
  //   } else if (selectedValue === "carroulette") {
  //     const filteredData = historyData.filter(
  //       (item) => item.gameType === selectedValue
  //     );
  //     setFilteredOptions(filteredData);
  //   } else if (selectedValue === "colorprediction") {
  //     const filteredData = historyData.filter(
  //       (item) => item.gameType === selectedValue
  //     );
  //     setFilteredOptions(filteredData);
  //   } else if (selectedValue === "dragontiger") {
  //     const filteredData = historyData.filter(
  //       (item) => item.gameType === selectedValue
  //     );
  //     setFilteredOptions(filteredData);
  //   } else if (selectedValue === "sevenupdown") {
  //     const filteredData = historyData.filter(
  //       (item) => item.gameType === selectedValue
  //     );
  //     setFilteredOptions(filteredData);
  //   } else if (selectedValue === "teenpatti") {
  //     const filteredData = historyData.filter(
  //       (item) => item.gameType === selectedValue
  //     );
  //     setFilteredOptions(filteredData);
  //   } else if (selectedValue === "chessgamebit") {
  //     const filteredData = historyData.filter(
  //       (item) => item.gameType === selectedValue
  //     );
  //     setFilteredOptions(filteredData);
  //   } else {
  //     setFilteredOptions(historyData);
  //   }
  // };

  return (
    <div style={{ overflow: "scroll" }}>
      <div
        className="form-group"
        style={{ width: "300px", display: "flex", alignItems: "end" }}
      >
        {/* <label htmlFor="password" className="px-2">
          Filter
        </label>
        <select
          name="bot"
          onChange={handleSelectChange}
          className={`mt-2 form-control`}
        >
          <option value="All">All</option>
          <option value="addmoney">Deposit</option>
          <option value="withdraw">Withdraw</option>
          <option value="blockblast">Block Blast</option>
          <option value="candycream">Candy Cream</option>
          <option value="logicline">Logic Line</option>
          <option value="smashblock">Smash Block</option>
          <option value="tictactoe">Tic Tac Toe</option>
          <option value="wordbook">Word Book</option>
          <option value="numberpuzzle2248">Number Puzzle 2248</option>
          <option value="chessgamebit">Chess Game Bit</option>
        </select> */}
      </div>
      <table id="" class="table table-bordered table-striped">
        <thead>
          <tr>
            <th style={{ textAlign: "center" }}>Id</th>
            <th style={{ textAlign: "center" }}>Player Id</th>
            <th style={{ textAlign: "center" }}>Game Name</th>
            <th style={{ textAlign: "center" }}>Transaction Type</th>
            <th style={{ textAlign: "center" }}>Transaction Details</th>
            <th style={{ textAlign: "center" }}>Amount</th>
            {/* {sortingValue === "transfer" && (
              <th style={{ textAlign: "center" }}>Transfer Player Id</th>
            )} */}
            <th style={{ textAlign: "center" }}>Note</th>
            <th style={{ textAlign: "center" }}>Created At</th>
          </tr>
        </thead>
        <tbody>
          <>
            {console.log("filteredOptions::: ", filteredOptions)}
            {filteredOptions.length > 0 ? (
              <>
                {filteredOptions
                  ?.slice(pagination.start, pagination.end)
                  .map((item, index) => {
                    return (
                      <>
                        <tr key={index} style={{ textAlign: "center" }}>
                          <td style={{ textAlign: "center" }}>
                            {index + 1 + pagination.start}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.playerId ? item.playerId : "-"}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.gameName ? item.gameName : "-"}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.transactionType === 0 && "Debit"}
                            {item.transactionType === 1 && "Credit"}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.transactionDetails}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {Math.round(item.amount * 100) / 100}
                          </td>
                          {/* {sortingValue === "transfer" && (
                            <td style={{ textAlign: "center" }}>
                              {item.transferPlayerId === item.playerId
                                ? "Our"
                                : item.transferPlayerId}
                            </td>
                          )} */}
                          <td style={{ textAlign: "center" }}>
                            {/* 0:addmoney', '1: withdraw', '2: bet', '3: win', '4:refund', '5: reward' */}
                            {item.note === 0 && "Addmoney"}
                            {item.note === 1 && "Withdraw"}
                            {item.note === 2 && "Bet"}
                            {item.note === 3 && "Win"}
                            {item.note === 4 && "Refund"}
                            {item.note === 5 && "Reward"}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {moment(item.createdAt).format("DD/MM/YYYY")}
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </>
            ) : (
              <>
                <tr>
                  <td colSpan={8}>
                    <h5 className="text-center">No Data Available</h5>
                  </td>
                </tr>
              </>
            )}
          </>
        </tbody>
      </table>

      {filteredOptions.length !== 0 && (
        <Pagination
          updateShowPerpagevalue={updateShowPerpagevalue}
          showPrePage={showPrePage}
          onPaginationChange={onPaginationChange}
          total={historyData?.length}
        />
      )}
    </div>
  );
}
