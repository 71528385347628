import React from "react";
import * as Yup from "yup";
import axios from "axios";
import { API_URL } from "../../config";
import { Formik, Form, Field, ErrorMessage } from "formik";
import SweetAlert from "react-bootstrap-sweetalert";
import { useAppContext } from "../../Lib/ContextLib";
import socketIO from "socket.io-client";
export default function Test({ rowValue }) {
  let socket = socketIO(API_URL, { transports: ["websocket"] });
  const { setShowPage } = useAppContext();
  const [buttonLoader3, setButtonLoader3] = React.useState(false);
  const [alert, setAlert] = React.useState(null);
  const LoginSchema3 = Yup.object().shape({
    Amount: Yup.number().required("required"),
    note: Yup.string().required("required"),
    TransactionType: Yup.string().required("required"),
    walletType: Yup.string().required("required"),
  });

  const successEditStatus = (msg) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title={msg}
        onConfirm={() => {
          setAlert(null);
          setShowPage(false);
        }}
        confirmBtnBsStyle="success"
      ></SweetAlert>
    );
  };
  const warningStatus = (msg) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title={msg}
        onConfirm={() => {
          setAlert(null);
        }}
        confirmBtnBsStyle="warning"
      ></SweetAlert>
    );
  };
  return (
    <div>
      {alert}
      <Formik
        initialValues={{
          Amount: "",
          note: "test",
          TransactionType: "",
          walletType: "",
        }}
        validationSchema={LoginSchema3}
        onSubmit={(values) => {
          setButtonLoader3(true);
          const testData = {
            amount: values.Amount.toString(),
            transactionType: values.TransactionType,
            walletType: values.walletType,
            id: rowValue._id,
            playerId: rowValue.playerId,
            email: rowValue.email,
            phoneNo: rowValue.email,
          };
          console.log("testData:::::::", testData);

          axios(`${API_URL}/api/history/test-transaction`, {
            method: "POST",
            credentials: "include",
            data: testData,
          })
            .then((result) => {
              console.log("handleSubmit result :::", result.data);
              console.log("result.data::: ", result.data);
              if (result.data.isValid) {
                const data = {
                  playerId: testData.playerId,
                  message: "Transaction",
                };
                socket.emit("PlayerDetailsUpdate", data);
                setButtonLoader3(false);
                successEditStatus(result.data.message);
              } else {
                setButtonLoader3(false);
                warningStatus(result.data.message);
              }
            })
            .catch((err) => {
              console.log("handleSubmit err :::", err);
            });
        }}
      >
        {({ touched, errors, isSubmitting, values, setFieldValue }) => (
          <div>
            <Form>
              <div className="form-group">
                <label htmlFor="password" className="mt-3">
                  Amount
                </label>
                <Field
                  type="number"
                  name="Amount"
                  placeholder="Enter Amount"
                  onChange={(e) => {
                    const re = /^[0-9]*\.?[0-9]*$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setFieldValue("Amount", e.target.value);
                    }
                  }}
                  className={` form-control
                          ${
                            touched.Amount && errors.Amount ? "is-invalid" : ""
                          }`}
                />
                <ErrorMessage
                  component="div"
                  name="Amount"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group">
                <label htmlFor="password" className="">
                  Note
                </label>
                <Field
                  as="select"
                  name="note"
                  disabled
                  className={` form-control
                          ${touched.note && errors.note ? "is-invalid" : ""}`}
                >
                  {/* <option value="">Select Type</option>
                            <option value="join">Join</option>
                            <option value="win">Win</option>
                            <option value="daily">Daily</option> */}
                  <option value="test">Test</option>
                </Field>
                <ErrorMessage
                  component="div"
                  name="note"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label htmlFor="password" className="">
                  Transaction Type
                </label>
                <Field
                  as="select"
                  name="TransactionType"
                  className={` form-control
                          ${
                            touched.TransactionType && errors.TransactionType
                              ? "is-invalid"
                              : ""
                          }`}
                >
                  <option value="">Select Transaction Type</option>
                  <option value="credit">Credit</option>
                  <option value="debit">Debit</option>
                </Field>
                <ErrorMessage
                  component="div"
                  name="TransactionType"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label htmlFor="password" className="">
                  Add Wallet Type
                </label>
                <Field
                  as="select"
                  name="walletType"
                  className={` form-control
                          ${
                            touched.walletType && errors.walletType
                              ? "is-invalid"
                              : ""
                          }`}
                >
                  <option value="">Select Add Balance Type</option>
                  <option value="0">Deposite Balance</option>
                  <option value="1">Withdraw Balance</option>
                  <option value="2">Bonus Balance</option>
                </Field>
                <ErrorMessage
                  component="div"
                  name="walletType"
                  className="invalid-feedback"
                />
              </div>
              <button
                style={{ width: "100px" }}
                type="submit"
                className="btn btn-primary btn-block mt-4 mr-2"
                disabled={buttonLoader3}
              >
                <div
                  className="d-flex"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="d-flex"
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <b>Submit</b>
                  </div>
                  {buttonLoader3 && (
                    <div>
                      <div
                        class="spinner-border ml-2 mt-1"
                        role="status"
                        style={{ height: "20px", width: "20px" }}
                      ></div>
                    </div>
                  )}
                </div>
              </button>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}
