import React from "react";
import axios from "axios";
import Pagination from "../../Pagination/Pagination";
import { API_URL } from "../../config";
// import Loader from "../Loader/Loader";
import { useAppContext } from "../../Lib/ContextLib";
import html2pdf from "html2pdf.js";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import moment from "moment";

export default function History() {
  const { accessObject } = useAppContext();
  const [dataRows, setdataRows] = React.useState([]);
  const [searchPhoneNo, setSearchPhoneNo] = React.useState("");
  const [showPrePage, setShowPrePage] = React.useState(10);
  const [loader, setLoader] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    start: 0,
    end: showPrePage,
  });

  React.useEffect(() => {
    getBranch();
  }, [showPrePage]);

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const updateShowPerpagevalue = (value) => {
    setShowPrePage(value);
    setPagination({ start: 0, end: value });
  };

  const getBranch = () => {
    setLoader(true);
    axios(`${API_URL}/api/history/get-history`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        setLoader(false);
        console.log("transaction=============>", response.data.message);
        setdataRows(response.data.message);
      })
      .catch((error) => {
        console.log("getBranch error :::", error);
      });
  };

  const searchingData = [];
  let data = [];
  if (searchPhoneNo) {
    console.log("searchPhoneNo", searchPhoneNo, dataRows);

    data = dataRows.filter((item) => {
      return (
        item?.email !== undefined &&
        Object.values(item?.email)
          .join("")
          .toLowerCase()
          .includes(searchPhoneNo.toLowerCase())
      );
    });
    console.log("data", data);
    data?.map((dataItem) => searchingData?.push(dataItem));
  } else {
    dataRows?.map((dataItem) => searchingData?.push(dataItem));
  }

  return (
    <div>
      <div className="content p-3">
        <div class="card ">
          <div class="pl-4 pr-4 pt-4">
            <div className="row">
              <div className="col-md-4 col-lg-4">
                <h3 class="card-title py-2">
                  <b>History</b>
                </h3>
              </div>
              <div
                className="col-md-4 col-lg-4 d-flex justify-content-start justify-content-lg-center "
                style={{ padding: "10px" }}
              >
                {accessObject?.historyDownload === "true" && (
                  <div>
                    <button
                      className="btn btn-primary mr-2 "
                      onClick={() => {
                        const input = document.getElementById("download-PDF");
                        const opt = {
                          filename: "History-List.pdf",
                        };
                        html2pdf().set(opt).from(input).save().from().toPdf();
                      }}
                    >
                      PDF
                    </button>
                    <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="download-table-xls-button btn btn-info"
                      table="table-to-xls"
                      filename="History"
                      sheet="History"
                      buttonText="Excel"
                    />
                  </div>
                )}
              </div>
              <div className="col-md-4 col-lg-4 d-flex justify-content-start justify-content-lg-end">
                <div>
                  <input
                    class="input-simple"
                    type="text"
                    placeholder="Email"
                    value={searchPhoneNo}
                    onChange={(e) => setSearchPhoneNo(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            class="card-body"
            id="download-PDF"
            style={{ overflow: "scroll" }}
          >
            <table class="table table-bordered table-striped" id="table-to-xls">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>Id</th>
                  <th style={{ textAlign: "center" }}>Date</th>
                  <th style={{ textAlign: "center" }}>Player Id</th>
                  <th style={{ textAlign: "center" }}>Email</th>
                  <th style={{ textAlign: "center" }}>Game Type</th>
                  <th style={{ textAlign: "center" }}>Transaction Type</th>
                  <th style={{ textAlign: "center" }}>Amount</th>
                  <th style={{ textAlign: "center" }}>Note</th>
                  <th style={{ textAlign: "center" }}>Status</th>
                </tr>
              </thead>

              {accessObject?.getHistoryList === "true" ? (
                <>
                  <tbody>
                    {!loader ? (
                      <>
                        {searchingData.length > 0 ? (
                          <>
                            {searchingData
                              ?.slice(pagination.start, pagination.end)
                              .map((item, index) => (
                                <>
                                  <tr
                                    key={index}
                                    style={{ textAlign: "center" }}
                                  >
                                    <td style={{ textAlign: "center" }}>
                                      {index + 1 + pagination.start}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {moment(item.createdAt).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {item.playerId ? item.playerId : "-"}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {item.email ? item.email : "-"}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {item.gameType ? item.gameType : "-"}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {item.transactionType}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {Math.round(item.amount * 100) / 100}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {item.note}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {item.status}
                                    </td>
                                  </tr>
                                </>
                              ))}
                          </>
                        ) : (
                          <>
                            <tr style={{ backgroundColor: "whitesmoke" }}>
                              <td colSpan={10}>
                                <h5 className="text-center">
                                  No Data Available
                                </h5>
                              </td>
                            </tr>
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    <tr style={{ backgroundColor: "whitesmoke" }}>
                      <td colSpan={10}>
                        <h5 className="text-center">No Data Available</h5>
                      </td>
                    </tr>
                  </tbody>
                </>
              ) : (
                <>
                  <tr style={{ backgroundColor: "rgba(0,0,0,.05)" }}>
                    <td colSpan={10}>
                      <h5 className="text-center">Access Denied</h5>
                    </td>
                  </tr>
                </>
              )}
            </table>
            {/* {accessObject?.getHistoryList == "true" && (
              <div className="mt-4">{loader && <Loader />}</div>
            )} */}
          </div>
          {accessObject?.getHistoryList === "true" &&
            searchingData.length !== 0 && (
              <Pagination
                updateShowPerpagevalue={updateShowPerpagevalue}
                showPrePage={showPrePage}
                onPaginationChange={onPaginationChange}
                total={searchingData.length}
              />
            )}
        </div>
      </div>
    </div>
  );
}
