import { useState } from "react";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
import axios from "axios";
import Logo from "../../img/W_Logo.png";

import { API_URL } from "../../config";

function SignUp() {
  // const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    phoneNo: "",
    email: "",
    message: "",
  });
  const handleChange = (event) => {
    setUserData({ ...userData, [event.target.name]: event.target.value });
  };
  const notify = (e) => {
    setLoader(true);
    e.preventDefault();
    axios
      .post(`${API_URL}/api/contactUs/add-contactUs`, userData)
      .then((res) => {
        console.log("contact us res:::", res.data);
        if (res.data.isValid) {
          toast.success(res.data.message);
          setLoader(false);
          clearInputField();
        } else {
          toast.error(res.data.message);
          setLoader(false);
          clearInputField();
        }
      })
      .catch((err) => {
        console.log("contact us err :::", err);
      });
    console.log("userData::: ", userData);
  };
  const clearInputField = () => {
    setUserData({
      name: "",
      phoneNo: "",
      email: "",
      message: "",
    });
  };

  return (
    <>
      <div className="main-div">
        <div className="card1">
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
            className="py-3"
          >
            <img src={Logo} alt="contact-us" width="130px" />
          </div>
          <div className="signup-form">
            <div className="container">
              <div className="header">
                <h2 style={{ color: "#000000" }} className="mb-1">
                  <b>ContactUs</b>
                </h2>
                <p>Get started for free!</p>
              </div>
              <form onSubmit={notify}>
                <div className="input">
                  <i className="fa fa-user" />
                  <input
                    type="text"
                    name="name"
                    value={userData.name}
                    placeholder="Name"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="input">
                  <i className="fa fa-mobile" />
                  <input
                    type="number"
                    name="phoneNo"
                    value={userData.phoneNo}
                    placeholder="PhoneNo"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="input">
                  <i className="fa fa-envelope" />
                  <input
                    type="email"
                    name="email"
                    value={userData.email}
                    placeholder="Email"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="textarea">
                  <i className="fa fa-comments" />
                  <textarea
                    type="email"
                    name="message"
                    value={userData.message}
                    placeholder="Message"
                    onChange={handleChange}
                    rows={5}
                    required
                  />
                </div>

                <button className="signup-btn" type="submit" disabled={loader}>
                  {loader ? (
                    <div
                      class="spinner-border"
                      role="status"
                      style={{ height: "1.5rem", width: "1.5rem" }}
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                  {/* Submit */}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
