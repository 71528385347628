import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./Dashboard";
import ContactDetails from "./Pages/ContactDetails/ContactDetails";
import Version from "./Pages/Controls/Version";
import GameSetting from "./Pages/GameSetting/GameSetting";
import AddCash from "./Pages/Payment/AddCash";
// import Bet from "./Pages/Payment/Bet";
import History from "./Pages/Payment/History";
// import Payout from "./Pages/Payment/Payout";
// import Win from "./Pages/Payment/Win";
// import LivePlayerApp from "./Pages/Player/LivePlayerApp";
// import LivePlayerGame from "./Pages/Player/LivePlayerGame";
import PlayerBanned from "./Pages/Player/PlayerBanned";
// import AndroidPlayer from "./Pages/Player/AndroidPlayer";
// import IosPlayer from "./Pages/Player/IosPlayer";
// import PendingKycPlayer from "./Pages/Player/PendingKycPlayer";
import PlayerList from "./Pages/Player/PlayerList";
// import HistoryReports from "./Pages/Reports/HistoryReports";
// import PlayerReports from "./Pages/Reports/PlayerReports";
import SendNotification from "./Pages/Controls/SendNotification";
import AdminList from "./Pages/Admin/AdminList";
import AccessUser from "./Pages/Admin/AccessUser";
import CoinPackage from "./Pages/Controls/CoinPackage";
import Social from "./Pages/Controls/Social";
import Notification from "./Pages/Controls/Notification";
import BotForm from "./Pages/Controls/BotForm";
import RoomTier from "./Pages/Controls/RoomTier";
import RestrictedArea from "./Pages/Controls/RestrictedArea";
import Bonus from "./Pages/GameSetting/Bonus";
import GameHandle from "./Pages/GameSetting/GameHandle";
import GameVersion from "./Pages/GameSetting/GameVersion";
import Withdraw from "./Pages/GameSetting/Withdraw";
import AddMoney from "./Pages/GameSetting/AddMoney";

// income route
import IncomeDetails from "./Pages/Income/IncomeDetails";
// import BlockBlastIncome from "./Pages/Income/BlockBlastIncome";
// import CandyCreamIncome from "./Pages/Income/CandyCreamIncome";
// import LogicLineIncome from "./Pages/Income/LogicLineIncome";
// import SmashBlockIncome from "./Pages/Income/SmashBlockIncome";
// import TicTacToeIncome from "./Pages/Income/TicTacToeIncome";
// import WordBookIncome from "./Pages/Income/WordBookIncome";
// import NumberPuzzle2248Income from "./Pages/Income/NumberPuzzle2248Income";
// import ChessGameBitIncome from "./Pages/Income/ChessGameBitIncome";
import SeparateNotification from "./Pages/Controls/SeparateNotification";
import GameList from "./Pages/Game/GameList";
import SpinWheel from "./Pages/Controls/SpinWheel";
import CoinShop from "./Pages/Controls/CoinShop";
import GuestUser from "./Pages/Player/GuestUser";
import GameAnalytics from "./Pages/GameAnalytics/GameAnalytics";

export default function Router() {
  return (
    <div>
      <Routes>
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/playerlist" element={<PlayerList />} />
        <Route path="/guest-user" element={<GuestUser />} />
        <Route path="/playerbanned" element={<PlayerBanned />} />
        {/* <Route path="/android-player" element={<AndroidPlayer />} /> */}
        {/* <Route path="/ios-player" element={<IosPlayer />} /> */}
        {/* <Route path="/pending-kyc-player" element={<PendingKycPlayer />} /> */}
        <Route path="/history" element={<History />} />
        <Route path="/addcash" element={<AddCash />} />
        {/* <Route path="/payout" element={<Payout />} /> */}
        {/* <Route path="/live-player-app" element={<LivePlayerApp />} />
        <Route path="/live-player-game" element={<LivePlayerGame />} /> */}
        {/* <Route path="/bet" element={<Bet />} />
        <Route path="/win" element={<Win />} /> */}
        <Route path="/bot-form" element={<BotForm />} />
        <Route path="/room-tier" element={<RoomTier />} />
        <Route path="/restrictedarea" element={<RestrictedArea />} />
        <Route path="/coin-package" element={<CoinPackage />} />
        <Route path="/social" element={<Social />} />
        {/* game */}
        <Route path="/game-list" element={<GameList />} />
        <Route path="/spinWheel" element={<SpinWheel />} />
        <Route path="/admin-list" element={<AdminList />} />
        <Route path="/access-user" element={<AccessUser />} />
        <Route path="/notification" element={<Notification />} />
        <Route
          path="/separate-notification"
          element={<SeparateNotification />}
        />
        <Route path="/bonus" element={<Bonus />} />
        <Route path="/game-handle" element={<GameHandle />} />
        <Route path="/game-version" element={<GameVersion />} />
        <Route path="/coin-shop" element={<CoinShop />} />
        {/* Income */}
        <Route path="/income-details" element={<IncomeDetails />} />
        {/* <Route path="/blockblast-income" element={<BlockBlastIncome />} />
        <Route path="/candycream-income" element={<CandyCreamIncome />} />
        <Route path="/logicline-income" element={<LogicLineIncome />} />
        <Route path="/smashblock-income" element={<SmashBlockIncome />} />
        <Route path="/tictactoe-income" element={<TicTacToeIncome />} />
        <Route path="/wordbook-income" element={<WordBookIncome />} />
        <Route path="/chessgamebit-income" element={<ChessGameBitIncome />} />
        <Route
          path="/numberpuzzle2248-income"
          element={<NumberPuzzle2248Income />}
        /> */}
        {/* end income */}
        <Route path="/send-notification" element={<SendNotification />} />
        <Route path="/version" element={<Version />} />
        {/* <Route path="/reports-player" element={<PlayerReports />} /> */}
        <Route path="/game-setting" element={<GameSetting />} />
        {/* <Route path="/history-reports" element={<HistoryReports />} /> */}
        <Route path="/contact-details" element={<ContactDetails />} />
        <Route path="/withdraw" element={<Withdraw />} />
        <Route path="/addmoney" element={<AddMoney />} />
        <Route path="/game-analytics" element={<GameAnalytics />} />
      </Routes>
    </div>
  );
}
