import React from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import moment from "moment";

const AccordionItem = ({
  isExpanded,
  onToggle,
  row,
  index,
  paginationStart,
}) => {
  // console.log("isExpanded::: ", isExpanded);
  // console.log("row::: ", row);

  // bet
  // const playerNameBet = (item) => {
  //   if (item.gameName == "dragontiger") {
  //     console.log("item.transactionSubData_DragonTigerBet::: ", item);
  //     return item.transactionSubData_DragonTigerBet.name;
  //   } else if (item.gameName == "andarbahar") {
  //     return item.transactionSubData_AndarBaharBet.name;
  //   } else if (item.gameName == "aviator") {
  //     return item.transactionSubData_AviatorBet.name;
  //   } else if (item.gameName == "carroulette") {
  //     return item.transactionSubData_CarRouletteBet.name;
  //   } else if (item.gameName == "colorprediction") {
  //     return item.transactionSubData_ColorPredictionBet.name;
  //   } else if (item.gameName == "sevenupdown") {
  //     return item.transactionSubData_SevenUpDownBet.name;
  //   } else if (item.gameName == "teenpatti") {
  //     return item.transactionSubData_TeenPattiBet.name;
  //   }
  // };
  // const emailBet = (item) => {
  //   if (item.gameName == "dragontiger") {
  //     console.log("item.transactionSubData_DragonTigerBet::: ", item);
  //     return item.transactionSubData_DragonTigerBet.email;
  //   } else if (item.gameName == "andarbahar") {
  //     return item.transactionSubData_AndarBaharBet.email;
  //   } else if (item.gameName == "aviator") {
  //     return item.transactionSubData_AviatorBet.email;
  //   } else if (item.gameName == "carroulette") {
  //     return item.transactionSubData_CarRouletteBet.email;
  //   } else if (item.gameName == "colorprediction") {
  //     return item.transactionSubData_ColorPredictionBet.email;
  //   } else if (item.gameName == "sevenupdown") {
  //     return item.transactionSubData_SevenUpDownBet.email;
  //   } else if (item.gameName == "teenpatti") {
  //     return item.transactionSubData_TeenPattiBet.email;
  //   }
  // };

  // win

  // const playerNameWin = (item) => {
  //   if (item.gameName == "dragontiger") {
  //     console.log("item.transactionSubData_DragonTigerBet::: ", item);
  //     return item.transactionSubData_DragonTigerWin.name;
  //   } else if (item.gameName == "andarbahar") {
  //     return item.transactionSubData_AndarBaharWin.name;
  //   } else if (item.gameName == "aviator") {
  //     return item.transactionSubData_AviatorWin.name;
  //   } else if (item.gameName == "carroulette") {
  //     return item.transactionSubData_CarRouletteWin.name;
  //   } else if (item.gameName == "colorprediction") {
  //     return item.transactionSubData_ColorPredictionWin.name;
  //   } else if (item.gameName == "sevenupdown") {
  //     return item.transactionSubData_SevenUpDownWin.name;
  //   } else if (item.gameName == "teenpatti") {
  //     return item.transactionSubData_TeenPattiWin.name;
  //   }
  // };
  // const emailWin = (item) => {
  //   if (item.gameName == "dragontiger") {
  //     console.log("item.transactionSubData_DragonTigerBet::: ", item);
  //     return item.transactionSubData_DragonTigerWin.email;
  //   } else if (item.gameName == "andarbahar") {
  //     return item.transactionSubData_AndarBaharWin.email;
  //   } else if (item.gameName == "aviator") {
  //     return item.transactionSubData_AviatorWin.email;
  //   } else if (item.gameName == "carroulette") {
  //     return item.transactionSubData_CarRouletteWin.email;
  //   } else if (item.gameName == "colorprediction") {
  //     return item.transactionSubData_ColorPredictionWin.email;
  //   } else if (item.gameName == "sevenupdown") {
  //     return item.transactionSubData_SevenUpDownWin.email;
  //   } else if (item.gameName == "teenpatti") {
  //     return item.transactionSubData_TeenPattiWin.email;
  //   }
  // };
  // const placeSideWin = (item) => {
  //   if (item.gameName == "dragontiger") {
  //     console.log("item.transactionSubData_DragonTigerBet::: ", item);
  //     return item.transactionSubData_DragonTigerWin.placeSide;
  //   } else if (item.gameName == "andarbahar") {
  //     return item.transactionSubData_AndarBaharWin.placeSide;
  //   } else if (item.gameName == "aviator") {
  //     return item.transactionSubData_AviatorWin.placeSide;
  //   } else if (item.gameName == "carroulette") {
  //     return item.transactionSubData_CarRouletteWin.placeSide;
  //   } else if (item.gameName == "colorprediction") {
  //     return item.transactionSubData_ColorPredictionWin.placeSide;
  //   } else if (item.gameName == "sevenupdown") {
  //     return item.transactionSubData_SevenUpDownWin.placeSide;
  //   } else if (item.gameName == "teenpatti") {
  //     return item.transactionSubData_TeenPattiWin.placeSide;
  //   }
  // };
  // const placeTypeWin = (item) => {
  //   if (item.gameName == "dragontiger") {
  //     console.log("item.transactionSubData_DragonTigerBet::: ", item);
  //     return item.transactionSubData_DragonTigerWin.placeType;
  //   } else if (item.gameName == "andarbahar") {
  //     return item.transactionSubData_AndarBaharWin.placeType;
  //   } else if (item.gameName == "aviator") {
  //     return item.transactionSubData_AviatorWin.placeType;
  //   } else if (item.gameName == "carroulette") {
  //     return item.transactionSubData_CarRouletteWin.placeType;
  //   } else if (item.gameName == "colorprediction") {
  //     return item.transactionSubData_ColorPredictionWin.placeType;
  //   } else if (item.gameName == "sevenupdown") {
  //     return item.transactionSubData_SevenUpDownWin.placeType;
  //   } else if (item.gameName == "teenpatti") {
  //     return item.transactionSubData_TeenPattiWin.placeType;
  //   }
  // };
  // const colorNameWin = (item) => {
  //   if (item.gameName == "dragontiger") {
  //     console.log("item.transactionSubData_DragonTigerBet::: ", item);
  //     return item.transactionSubData_DragonTigerWin.colorName;
  //   } else if (item.gameName == "andarbahar") {
  //     return item.transactionSubData_AndarBaharWin.colorName;
  //   } else if (item.gameName == "aviator") {
  //     return item.transactionSubData_AviatorWin.colorName;
  //   } else if (item.gameName == "carroulette") {
  //     return item.transactionSubData_CarRouletteWin.colorName;
  //   } else if (item.gameName == "colorprediction") {
  //     return item.transactionSubData_ColorPredictionWin.colorName;
  //   } else if (item.gameName == "sevenupdown") {
  //     return item.transactionSubData_SevenUpDownWin.colorName;
  //   } else if (item.gameName == "teenpatti") {
  //     return item.transactionSubData_TeenPattiWin.colorName;
  //   }
  // };
  // const colorNumberWin = (item) => {
  //   if (item.gameName == "dragontiger") {
  //     console.log("item.transactionSubData_DragonTigerBet::: ", item);
  //     return item.transactionSubData_DragonTigerWin.colorNumber;
  //   } else if (item.gameName == "andarbahar") {
  //     return item.transactionSubData_AndarBaharWin.colorNumber;
  //   } else if (item.gameName == "aviator") {
  //     return item.transactionSubData_AviatorWin.colorNumber;
  //   } else if (item.gameName == "carroulette") {
  //     return item.transactionSubData_CarRouletteWin.colorNumber;
  //   } else if (item.gameName == "colorprediction") {
  //     return item.transactionSubData_ColorPredictionWin.colorNumber;
  //   } else if (item.gameName == "sevenupdown") {
  //     return item.transactionSubData_SevenUpDownWin.colorNumber;
  //   } else if (item.gameName == "teenpatti") {
  //     return item.transactionSubData_TeenPattiWin.colorNumber;
  //   }
  // };
  // const betNoWin = (item) => {
  //   if (item.gameName == "dragontiger") {
  //     console.log("item.transactionSubData_DragonTigerBet::: ", item);
  //     return item.transactionSubData_DragonTigerWin.betNo;
  //   } else if (item.gameName == "andarbahar") {
  //     return item.transactionSubData_AndarBaharWin.betNo;
  //   } else if (item.gameName == "aviator") {
  //     return item.transactionSubData_AviatorWin.betNo;
  //   } else if (item.gameName == "carroulette") {
  //     return item.transactionSubData_CarRouletteWin.betNo;
  //   } else if (item.gameName == "colorprediction") {
  //     return item.transactionSubData_ColorPredictionWin.betNo;
  //   } else if (item.gameName == "sevenupdown") {
  //     return item.transactionSubData_SevenUpDownWin.betNo;
  //   } else if (item.gameName == "teenpatti") {
  //     return item.transactionSubData_TeenPattiWin.betNo;
  //   }
  // };
  // const winCardsWin = (item) => {
  //   if (item.gameName == "dragontiger") {
  //     console.log("item.transactionSubData_DragonTigerBet::: ", item);
  //     return item.transactionSubData_DragonTigerWin.winCards;
  //   } else if (item.gameName == "andarbahar") {
  //     return item.transactionSubData_AndarBaharWin.winCards;
  //   } else if (item.gameName == "aviator") {
  //     return item.transactionSubData_AviatorWin.winCards;
  //   } else if (item.gameName == "carroulette") {
  //     return item.transactionSubData_CarRouletteWin.winCards;
  //   } else if (item.gameName == "colorprediction") {
  //     return item.transactionSubData_ColorPredictionWin.winCards;
  //   } else if (item.gameName == "sevenupdown") {
  //     return item.transactionSubData_SevenUpDownWin.winCards;
  //   } else if (item.gameName == "teenpatti") {
  //     return item.transactionSubData_TeenPattiWin.winCards;
  //   }
  // };
  // const carTypeWin = (item) => {
  //   if (item.gameName == "dragontiger") {
  //     console.log("item.transactionSubData_DragonTigerBet::: ", item);
  //     return item.transactionSubData_DragonTigerWin.carType;
  //   } else if (item.gameName == "andarbahar") {
  //     return item.transactionSubData_AndarBaharWin.carType;
  //   } else if (item.gameName == "aviator") {
  //     return item.transactionSubData_AviatorWin.carType;
  //   } else if (item.gameName == "carroulette") {
  //     return item.transactionSubData_CarRouletteWin.carType;
  //   } else if (item.gameName == "colorprediction") {
  //     return item.transactionSubData_ColorPredictionWin.carType;
  //   } else if (item.gameName == "sevenupdown") {
  //     return item.transactionSubData_SevenUpDownWin.carType;
  //   } else if (item.gameName == "teenpatti") {
  //     return item.transactionSubData_TeenPattiWin.carType;
  //   }
  // };
  // const modeWin = (item) => {
  //   if (item.gameName == "dragontiger") {
  //     console.log("item.transactionSubData_DragonTigerBet::: ", item);
  //     return item.transactionSubData_DragonTigerWin.mode;
  //   } else if (item.gameName == "andarbahar") {
  //     return item.transactionSubData_AndarBaharWin.mode;
  //   } else if (item.gameName == "aviator") {
  //     return item.transactionSubData_AviatorWin.mode;
  //   } else if (item.gameName == "carroulette") {
  //     return item.transactionSubData_CarRouletteWin.mode;
  //   } else if (item.gameName == "colorprediction") {
  //     return item.transactionSubData_ColorPredictionWin.mode;
  //   } else if (item.gameName == "sevenupdown") {
  //     return item.transactionSubData_SevenUpDownWin.mode;
  //   } else if (item.gameName == "teenpatti") {
  //     return item.transactionSubData_TeenPattiWin.mode;
  //   }
  // };

  return (
    <>
      <tbody>
        <tr key={index} style={{ textAlign: "center" }}>
          <td style={{ textAlign: "center" }}>
            <div className="d-flex" style={{ justifyContent: "center" }}>
              <div className="ml-2"> {index + 1 + paginationStart}</div>
            </div>
          </td>
          <td style={{ textAlign: "center" }}>
            {moment(row.date).format("DD/MM/YYYY")}
          </td>
          <td style={{ textAlign: "center" }}>{row.roundName}</td>
          <td style={{ textAlign: "center" }}>{row.commission}</td>
          <td style={{ textAlign: "center" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {isExpanded ? (
                <div
                  style={{
                    height: "20px",
                    width: "20px",
                    backgroundColor: "red",
                    opacity: 0.7,
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "50px",
                    alignItems: "center",
                  }}
                >
                  <RemoveIcon
                    style={{ color: "white", cursor: "pointer" }}
                    onClick={onToggle}
                  />
                </div>
              ) : (
                <div
                  style={{
                    height: "20px",
                    width: "20px",
                    backgroundColor: "#f9c130",
                    opacity: 0.7,
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "50px",
                    alignItems: "center",
                  }}
                >
                  <AddIcon
                    style={{ color: "white", cursor: "pointer" }}
                    onClick={onToggle}
                  />
                </div>
              )}
            </div>
          </td>
        </tr>
        {isExpanded && (
          <>
            <tr>
              <td colSpan={20}>
                <table className="w-100 table-striped">
                  <thead style={{ background: "#FFCCCB" }}>
                    <th colSpan={20} className="text-center">
                      <h5 className="mb-0">
                        <b>Bet</b>
                      </h5>
                    </th>
                  </thead>
                  {row?.bet.length > 0 ? (
                    <>
                      <thead>
                        <th>SrNo</th>
                        <th>Player Id</th>
                        <th>Player Name</th>
                        <th>Email</th>
                        <th>Amount</th>
                        <th>Note</th>
                      </thead>
                      <tbody>
                        {row?.bet?.map((item, i) => (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{item.playerId}</td>
                            <td>{item.playerName}</td>
                            <td>{item.email}</td>
                            <td>{item.amount}</td>
                            <td>{item.note}</td>
                          </tr>
                        ))}
                      </tbody>
                    </>
                  ) : (
                    <tr>
                      <td colSpan={8}>
                        <h5 className="text-center mb-0">No Data Available</h5>
                      </td>
                    </tr>
                  )}
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan={5}>
                <table className="w-100 w-100 table-striped">
                  <thead style={{ background: "#90EE90" }}>
                    <th colSpan={11} className="text-center">
                      <h5 className="mb-0">
                        <b>Win</b>
                      </h5>
                    </th>
                  </thead>
                  {row?.win.length > 0 ? (
                    <>
                      <thead>
                        <th>SrNo</th>
                        <th>Player Id</th>
                        <th>Player Name</th>
                        <th>Email</th>
                        <th>Amount</th>
                        <th>Note</th>
                      </thead>
                      <tbody>
                        {row?.win?.map((item, i) => (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{item.playerId}</td>
                            <td>{item.playerName}</td>
                            <td>{item.email}</td>
                            <td>{item.amount}</td>
                            <td>{item.note}</td>
                          </tr>
                        ))}
                      </tbody>
                    </>
                  ) : (
                    <tr>
                      <td colSpan={8}>
                        <h5 className="text-center mb-0">No Data Available</h5>
                      </td>
                    </tr>
                  )}
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan={5}>
                <table className="w-100 w-100 table-striped">
                  <thead style={{ background: "#FFCCCB" }}>
                    <th colSpan={11} className="text-center">
                      <h5 className="mb-0">
                        <b>Refund</b>
                      </h5>
                    </th>
                  </thead>
                  {row?.refund.length > 0 ? (
                    <>
                      <thead>
                        <th>SrNo</th>
                        <th>Player Id</th>
                        <th>Player Name</th>
                        <th>Email</th>
                        <th>Amount</th>
                        <th>Note</th>
                      </thead>
                      <tbody>
                        {row?.refund?.map((item, i) => (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{item.playerId}</td>
                            <td>{item.playerName}</td>
                            <td>{item.email}</td>
                            <td>{item.amount}</td>
                            <td>{item.note}</td>
                          </tr>
                        ))}
                      </tbody>
                    </>
                  ) : (
                    <tr>
                      <td colSpan={8}>
                        <h5 className="text-center mb-0">No Data Available</h5>
                      </td>
                    </tr>
                  )}
                </table>
              </td>
            </tr>
          </>
        )}
      </tbody>
    </>
  );
};

export default AccordionItem;
