import React from "react";

export default function AccessData({
  buttonLoader,
  accessObject,
  handelAddData,
  setAccessDataList,
  accessDataList,
}) {
  return (
    <div>
      {console.log("accessDataList?.email::: ", accessDataList)}
      <label className="mt-3 mb-3">
        Access User : {accessObject?.name + " (" + accessObject?.email + ")"}
      </label>
      <div className="card pb-2" style={{ background: "#F5F5F5" }}>
        <h5
          className="text-center p-1"
          style={{ background: "#ECCCA6", color: "#A8684B" }}
        >
          <b>Dashboard</b>
        </h5>
        <div className="row">
          <div className="col-sm-12 col-lg-6 col-md-6 ">
            <table className="m-2">
              <thead>
                <th>Dashboard Count</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="custom-checkbox d-flex">
                      <div class="form-group m-2">
                        <input
                          type="checkbox"
                          id="CountView"
                          defaultChecked={
                            accessObject?.access?.dashboardCount === "true"
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            setAccessDataList({
                              ...accessDataList,
                              dashboardCount:
                                e.target.checked === true ? "true" : "false",
                            });
                          }}
                        />
                        <label for="CountView">View</label>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="card pb-2" style={{ background: "#F5F5F5" }}>
        <h5
          className="text-center p-1"
          style={{ background: "#ECCCA6", color: "#A8684B" }}
        >
          <b>Admin</b>
        </h5>
        <div className="row">
          <div className="col-sm-12 col-lg-6 col-md-6 ">
            <table className="m-2">
              <thead>
                <th>Admin List</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="custom-checkbox d-flex">
                      <div class="form-group m-2">
                        <input
                          type="checkbox"
                          id="getAdminList"
                          defaultChecked={
                            accessObject?.access?.getAdminList === "true"
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            setAccessDataList({
                              ...accessDataList,
                              getAdminList:
                                e.target.checked === true ? "true" : "false",
                            });
                          }}
                        />
                        <label for="getAdminList">View</label>
                      </div>
                      {console.log(
                        "accessDataList.getAdminList::: ",
                        accessDataList.getAdminList
                      )}
                      {accessDataList.getAdminList === "true" && (
                        <>
                          <div class="form-group m-2">
                            <input
                              type="checkbox"
                              id="adminListAdd"
                              defaultChecked={
                                accessObject?.access?.getAdminList === "true"
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                setAccessDataList({
                                  ...accessDataList,
                                  getAdminList:
                                    e.target.checked === true
                                      ? "true"
                                      : "false",
                                });
                              }}
                            />
                            <label for="adminListAdd">Add</label>
                          </div>
                          <div class="form-group m-2">
                            <input
                              type="checkbox"
                              id="adminListEdit"
                              defaultChecked={
                                accessObject?.access?.adminListEdit === "true"
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                setAccessDataList({
                                  ...accessDataList,
                                  adminListEdit:
                                    e.target.checked === true
                                      ? "true"
                                      : "false",
                                });
                              }}
                            />
                            <label for="adminListEdit">Edit</label>
                          </div>
                          <div class="form-group m-2">
                            <input
                              type="checkbox"
                              id="adminListDelete"
                              defaultChecked={
                                accessObject?.access?.adminListDelete === "true"
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                setAccessDataList({
                                  ...accessDataList,
                                  adminListDelete:
                                    e.target.checked === true
                                      ? "true"
                                      : "false",
                                });
                              }}
                            />
                            <label for="adminListDelete">Delete</label>
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-sm-12 col-lg-6 col-md-6 ">
            <table className="m-2">
              <thead>
                <th>Admin Access</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="custom-checkbox d-flex">
                      <div class="form-group m-2">
                        <input
                          type="checkbox"
                          id="adminAccessUser"
                          defaultChecked={
                            accessObject?.access?.adminAccessUser === "true"
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            setAccessDataList({
                              ...accessDataList,
                              adminAccessUser:
                                e.target.checked === true ? "true" : "false",
                            });
                          }}
                        />
                        <label for="adminAccessUser">View</label>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="card pb-2" style={{ background: "#F5F5F5" }}>
        <h5
          className="text-center p-1"
          style={{ background: "#ECCCA6", color: "#A8684B" }}
        >
          <b>Player</b>
        </h5>
        <div className="row">
          <div className="col-sm-12 col-lg-6 col-md-6 ">
            <table className="m-2">
              <thead>
                <th>Player List</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="custom-checkbox d-flex">
                      <div class="form-group m-2">
                        {console.log(
                          " accessObject?.access?.getPlayerList::: ",
                          accessObject?.access?.getPlayerList
                        )}
                        <input
                          type="checkbox"
                          id="getPlayerList"
                          onChange={(e) => {
                            setAccessDataList({
                              ...accessDataList,
                              getPlayerList:
                                e.target.checked === true ? "true" : "false",
                            });
                          }}
                          defaultChecked={
                            accessObject?.access?.getPlayerList === "true"
                              ? true
                              : false
                          }
                        />
                        <label for="getPlayerList">View</label>
                      </div>
                      {accessDataList?.getPlayerList === "true" && (
                        <>
                          <div class="form-group m-2">
                            <input
                              type="checkbox"
                              id="playerListEdit"
                              onChange={(e) => {
                                setAccessDataList({
                                  ...accessDataList,
                                  playerListEdit:
                                    e.target.checked === true
                                      ? "true"
                                      : "false",
                                });
                              }}
                              defaultChecked={
                                accessObject?.access?.playerListEdit === "true"
                                  ? true
                                  : false
                              }
                            />
                            <label for="playerListEdit">Edit</label>
                          </div>
                          <div class="form-group m-2">
                            <input
                              type="checkbox"
                              id="playerListDelete"
                              onChange={(e) => {
                                setAccessDataList({
                                  ...accessDataList,
                                  playerListDelete:
                                    e.target.checked === true
                                      ? "true"
                                      : "false",
                                });
                              }}
                              defaultChecked={
                                accessObject?.access?.playerListDelete ===
                                "true"
                                  ? true
                                  : false
                              }
                            />
                            <label for="playerListDelete">Delete</label>
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-sm-12 col-lg-6 col-md-6 ">
            <table className="m-2">
              <thead>
                <th>Player Banned</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="custom-checkbox d-flex">
                      <div class="form-group m-2">
                        <input
                          type="checkbox"
                          id="getPlayerBannedList"
                          onChange={(e) => {
                            setAccessDataList({
                              ...accessDataList,
                              getPlayerBannedList:
                                e.target.checked === true ? "true" : "false",
                            });
                          }}
                          defaultChecked={
                            accessObject?.access?.getPlayerBannedList === "true"
                              ? true
                              : false
                          }
                        />
                        <label for="getPlayerBannedList">View</label>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="card pb-2" style={{ background: "#F5F5F5" }}>
        <h5
          className="text-center p-1"
          style={{ background: "#ECCCA6", color: "#A8684B" }}
        >
          <b>Controls</b>
        </h5>
        <div className="row">
          {/* Notification */}
          <div className="col-lsm-12 col-lg-4 col-md-4">
            <table className="m-2">
              <thead>
                <th>Notification</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="custom-checkbox d-flex">
                      <div class="form-group m-2">
                        <input
                          type="checkbox"
                          id="getNotificationList"
                          onChange={(e) => {
                            setAccessDataList({
                              ...accessDataList,
                              getNotificationList:
                                e.target.checked === true ? "true" : "false",
                            });
                          }}
                          defaultChecked={
                            accessObject?.access?.getNotificationList === "true"
                              ? true
                              : false
                          }
                        />
                        <label for="getNotificationList">View</label>
                      </div>
                      {accessDataList?.getNotificationList === "true" && (
                        <>
                          <div class="form-group m-2">
                            <input
                              type="checkbox"
                              id="notificationAdd"
                              onChange={(e) => {
                                setAccessDataList({
                                  ...accessDataList,
                                  notificationAdd:
                                    e.target.checked === true
                                      ? "true"
                                      : "false",
                                });
                              }}
                              defaultChecked={
                                accessObject?.access?.notificationAdd === "true"
                                  ? true
                                  : false
                              }
                            />
                            <label for="notificationAdd">Add</label>
                          </div>
                          <div class="form-group m-2">
                            <input
                              type="checkbox"
                              id="notificationDelete"
                              onChange={(e) => {
                                setAccessDataList({
                                  ...accessDataList,
                                  notificationDelete:
                                    e.target.checked === true
                                      ? "true"
                                      : "false",
                                });
                              }}
                              defaultChecked={
                                accessObject?.access?.notificationDelete ===
                                "true"
                                  ? true
                                  : false
                              }
                            />
                            <label for="notificationDelete">Delete</label>
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* Separate Notification */}
          <div className="col-lsm-12 col-lg-4 col-md-4">
            <table className="m-2">
              <thead>
                <th>Separate Notification</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="custom-checkbox d-flex">
                      <div class="form-group m-2">
                        <input
                          type="checkbox"
                          id="getNotificationListSeparate"
                          onChange={(e) => {
                            setAccessDataList({
                              ...accessDataList,
                              getNotificationListSeparate:
                                e.target.checked === true ? "true" : "false",
                            });
                          }}
                          defaultChecked={
                            accessObject?.access
                              ?.getNotificationListSeparate === "true"
                              ? true
                              : false
                          }
                        />
                        <label for="getNotificationListSeparate">View</label>
                      </div>
                      {accessDataList?.getNotificationListSeparate ===
                        "true" && (
                        <>
                          <div class="form-group m-2">
                            <input
                              type="checkbox"
                              id="notificationSeparateSend"
                              onChange={(e) => {
                                setAccessDataList({
                                  ...accessDataList,
                                  notificationSeparateSend:
                                    e.target.checked === true
                                      ? "true"
                                      : "false",
                                });
                              }}
                              defaultChecked={
                                accessObject?.access
                                  ?.notificationSeparateSend === "true"
                                  ? true
                                  : false
                              }
                            />
                            <label for="notificationSeparateSend">Send</label>
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* Version Android */}
          <div className="col-lsm-12 col-lg-4 col-md-4">
            <table className="m-2">
              <thead>
                <th>Version Android</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="custom-checkbox d-flex">
                      <div class="form-group m-2">
                        <input
                          type="checkbox"
                          id="getVersionListAndroid"
                          onChange={(e) => {
                            setAccessDataList({
                              ...accessDataList,
                              getVersionListAndroid:
                                e.target.checked === true ? "true" : "false",
                            });
                          }}
                          defaultChecked={
                            accessObject?.access?.getVersionListAndroid ===
                            "true"
                              ? true
                              : false
                          }
                        />
                        <label for="getVersionListAndroid">View</label>
                      </div>
                      {accessDataList?.getVersionListAndroid === "true" && (
                        <>
                          <div class="form-group m-2">
                            <input
                              type="checkbox"
                              id="versionEditAndroid"
                              onChange={(e) => {
                                setAccessDataList({
                                  ...accessDataList,
                                  versionEditAndroid:
                                    e.target.checked === true
                                      ? "true"
                                      : "false",
                                });
                              }}
                              defaultChecked={
                                accessObject?.access?.versionEditAndroid ===
                                "true"
                                  ? true
                                  : false
                              }
                            />
                            <label for="versionEditAndroid">Edit</label>
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* Version IOS */}
          <div className="col-lsm-12 col-lg-4 col-md-4">
            <table className="m-2">
              <thead>
                <th>Version IOS</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="custom-checkbox d-flex">
                      <div class="form-group m-2">
                        <input
                          type="checkbox"
                          id="getVersionListIos"
                          onChange={(e) => {
                            setAccessDataList({
                              ...accessDataList,
                              getVersionListIos:
                                e.target.checked === true ? "true" : "false",
                            });
                          }}
                          defaultChecked={
                            accessObject?.access?.getVersionListIos === "true"
                              ? true
                              : false
                          }
                        />
                        <label for="getVersionListIos">View</label>
                      </div>
                      {accessDataList?.getVersionListIos === "true" && (
                        <>
                          <div class="form-group m-2">
                            <input
                              type="checkbox"
                              id="versionEditIos"
                              onChange={(e) => {
                                setAccessDataList({
                                  ...accessDataList,
                                  versionEditIos:
                                    e.target.checked === true
                                      ? "true"
                                      : "false",
                                });
                              }}
                              defaultChecked={
                                accessObject?.access?.versionEditIos === "true"
                                  ? true
                                  : false
                              }
                            />
                            <label for="versionEditIos">Edit</label>
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* RestrictedArea */}
          <div className="col-lsm-12 col-lg-4 col-md-4">
            <table className="m-2">
              <thead>
                <th>Restricted Area</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="custom-checkbox d-flex">
                      <div class="form-group m-2">
                        <input
                          type="checkbox"
                          id="getRestrictedArea"
                          onChange={(e) => {
                            setAccessDataList({
                              ...accessDataList,
                              getRestrictedArea:
                                e.target.checked === true ? "true" : "false",
                            });
                          }}
                          defaultChecked={
                            accessObject?.access?.getRestrictedArea === "true"
                              ? true
                              : false
                          }
                        />
                        <label for="getRestrictedArea">View</label>
                      </div>
                      {accessDataList?.getRestrictedArea === "true" && (
                        <>
                          <div class="form-group m-2">
                            <input
                              type="checkbox"
                              id="addRestrictedArea"
                              onChange={(e) => {
                                setAccessDataList({
                                  ...accessDataList,
                                  addRestrictedArea:
                                    e.target.checked === true
                                      ? "true"
                                      : "false",
                                });
                              }}
                              defaultChecked={
                                accessObject?.access?.addRestrictedArea ===
                                "true"
                                  ? true
                                  : false
                              }
                            />
                            <label for="addRestrictedArea">Add</label>
                          </div>
                          <div class="form-group m-2">
                            <input
                              type="checkbox"
                              id="editRestrictedArea"
                              onChange={(e) => {
                                setAccessDataList({
                                  ...accessDataList,
                                  editRestrictedArea:
                                    e.target.checked === true
                                      ? "true"
                                      : "false",
                                });
                              }}
                              defaultChecked={
                                accessObject?.access?.editRestrictedArea ===
                                "true"
                                  ? true
                                  : false
                              }
                            />
                            <label for="editRestrictedArea">Edit</label>
                          </div>
                          <div class="form-group m-2">
                            <input
                              type="checkbox"
                              id="deleteRestrictedArea"
                              onChange={(e) => {
                                setAccessDataList({
                                  ...accessDataList,
                                  deleteRestrictedArea:
                                    e.target.checked === true
                                      ? "true"
                                      : "false",
                                });
                              }}
                              defaultChecked={
                                accessObject?.access?.deleteRestrictedArea ===
                                "true"
                                  ? true
                                  : false
                              }
                            />
                            <label for="deleteRestrictedArea">Delete</label>
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="card pb-2" style={{ background: "#F5F5F5" }}>
        <h5
          className="text-center p-1"
          style={{ background: "#ECCCA6", color: "#A8684B" }}
        >
          <b>Payment</b>
        </h5>
        <div className="row">
          <div className="col-lsm-12 col-lg-4 col-md-4">
            <table className="m-2">
              <thead>
                <th>History</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="custom-checkbox d-flex">
                      <div class="form-group m-2">
                        <input
                          type="checkbox"
                          id="getHistoryList"
                          onChange={(e) => {
                            setAccessDataList({
                              ...accessDataList,
                              getHistoryList:
                                e.target.checked === true ? "true" : "false",
                            });
                          }}
                          defaultChecked={
                            accessObject?.access?.getHistoryList === "true"
                              ? true
                              : false
                          }
                        />
                        <label for="getHistoryList">View</label>
                      </div>
                      {accessDataList?.getHistoryList === "true" && (
                        <>
                          <div class="form-group m-2">
                            <input
                              type="checkbox"
                              id="historyDownload"
                              onChange={(e) => {
                                setAccessDataList({
                                  ...accessDataList,
                                  historyDownload:
                                    e.target.checked === true
                                      ? "true"
                                      : "false",
                                });
                              }}
                              defaultChecked={
                                accessObject?.access?.historyDownload === "true"
                                  ? true
                                  : false
                              }
                            />
                            <label for="historyDownload">Download</label>
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-lsm-12 col-lg-4 col-md-4">
            <table className="m-2">
              <thead>
                <th>Add Cash</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="custom-checkbox d-flex">
                      <div class="form-group m-2">
                        <input
                          type="checkbox"
                          id="getAddCashList"
                          onChange={(e) => {
                            setAccessDataList({
                              ...accessDataList,
                              getAddCashList:
                                e.target.checked === true ? "true" : "false",
                            });
                          }}
                          defaultChecked={
                            accessObject?.access?.getAddCashList === "true"
                              ? true
                              : false
                          }
                        />
                        <label for="getAddCashList">View</label>
                      </div>
                      {accessDataList?.getAddCashList === "true" && (
                        <>
                          <div class="form-group m-2">
                            <input
                              type="checkbox"
                              id="addCashDownload"
                              onChange={(e) => {
                                setAccessDataList({
                                  ...accessDataList,
                                  addCashDownload:
                                    e.target.checked === true
                                      ? "true"
                                      : "false",
                                });
                              }}
                              defaultChecked={
                                accessObject?.access?.addCashDownload === "true"
                                  ? true
                                  : false
                              }
                            />
                            <label for="addCashDownload">Download</label>
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-lsm-12 col-lg-4 col-md-4">
            <table className="m-2">
              <thead>
                <th>Payout</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="custom-checkbox d-flex">
                      <div class="form-group m-2">
                        <input
                          type="checkbox"
                          id="getPayoutList"
                          onChange={(e) => {
                            setAccessDataList({
                              ...accessDataList,
                              getPayoutList:
                                e.target.checked === true ? "true" : "false",
                            });
                          }}
                          defaultChecked={
                            accessObject?.access?.getPayoutList === "true"
                              ? true
                              : false
                          }
                        />
                        <label for="getPayoutList">View</label>
                      </div>
                      {accessDataList?.getPayoutList === "true" && (
                        <>
                          <div class="form-group m-2">
                            <input
                              type="checkbox"
                              id="payoutDownload"
                              onChange={(e) => {
                                setAccessDataList({
                                  ...accessDataList,
                                  payoutDownload:
                                    e.target.checked === true
                                      ? "true"
                                      : "false",
                                });
                              }}
                              defaultChecked={
                                accessObject?.access?.payoutDownload === "true"
                                  ? true
                                  : false
                              }
                            />
                            <label for="payoutDownload">Download</label>
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-lsm-12 col-lg-4 col-md-4">
            <table className="m-2">
              <thead>
                <th>Bet</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="custom-checkbox d-flex">
                      <div class="form-group m-2">
                        <input
                          type="checkbox"
                          id="getBetList"
                          onChange={(e) => {
                            setAccessDataList({
                              ...accessDataList,
                              getBetList:
                                e.target.checked === true ? "true" : "false",
                            });
                          }}
                          defaultChecked={
                            accessObject?.access?.getBetList === "true"
                              ? true
                              : false
                          }
                        />
                        <label for="getBetList">View</label>
                      </div>
                      {accessDataList?.getBetList === "true" && (
                        <>
                          <div class="form-group m-2">
                            <input
                              type="checkbox"
                              id="betDownload"
                              onChange={(e) => {
                                setAccessDataList({
                                  ...accessDataList,
                                  betDownload:
                                    e.target.checked === true
                                      ? "true"
                                      : "false",
                                });
                              }}
                              defaultChecked={
                                accessObject?.access?.betDownload === "true"
                                  ? true
                                  : false
                              }
                            />
                            <label for="betDownload">Download</label>
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-lsm-12 col-lg-4 col-md-4">
            <table className="m-2">
              <thead>
                <th>Win</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="custom-checkbox d-flex">
                      <div class="form-group m-2">
                        <input
                          type="checkbox"
                          id="getWinList"
                          onChange={(e) => {
                            setAccessDataList({
                              ...accessDataList,
                              getWinList:
                                e.target.checked === true ? "true" : "false",
                            });
                          }}
                          defaultChecked={
                            accessObject?.access?.getWinList === "true"
                              ? true
                              : false
                          }
                        />
                        <label for="getWinList">View</label>
                      </div>
                      {accessDataList?.getWinList === "true" && (
                        <>
                          <div class="form-group m-2">
                            <input
                              type="checkbox"
                              id="winDownload"
                              onChange={(e) => {
                                setAccessDataList({
                                  ...accessDataList,
                                  winDownload:
                                    e.target.checked === true
                                      ? "true"
                                      : "false",
                                });
                              }}
                              defaultChecked={
                                accessObject?.access?.winDownload === "true"
                                  ? true
                                  : false
                              }
                            />
                            <label for="winDownload">Download</label>
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="card pb-2" style={{ background: "#F5F5F5" }}>
        <h5
          className="text-center p-1"
          style={{ background: "#ECCCA6", color: "#A8684B" }}
        >
          <b>Income</b>
        </h5>
        <div className="row">
          <div className="col-lsm-12 col-lg-4 col-md-4">
            <table className="m-2">
              <thead>
                <th>Income Details</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="custom-checkbox d-flex">
                      <div class="form-group m-2">
                        <input
                          type="checkbox"
                          id="getIncomeList"
                          onChange={(e) => {
                            setAccessDataList({
                              ...accessDataList,
                              getIncomeList:
                                e.target.checked === true ? "true" : "false",
                            });
                          }}
                          defaultChecked={
                            accessObject?.access?.getIncomeList === "true"
                              ? true
                              : false
                          }
                        />
                        <label for="getIncomeList">View</label>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Game Setting */}
      <div className="card pb-2" style={{ background: "#F5F5F5" }}>
        <h5
          className="text-center p-1"
          style={{ background: "#ECCCA6", color: "#A8684B" }}
        >
          <b>Game Setting</b>
        </h5>
        <div className="row">
          {/* Setting */}
          <div className="col-sm-12 col-lg-6 col-md-6 ">
            <table className="m-2">
              <thead>
                <th>Setting</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="custom-checkbox d-flex">
                      <div class="form-group m-2">
                        <input
                          type="checkbox"
                          id="getSetting"
                          onChange={(e) => {
                            setAccessDataList({
                              ...accessDataList,
                              getSetting:
                                e.target.checked === true ? "true" : "false",
                            });
                          }}
                          defaultChecked={
                            accessObject?.access?.getSetting === "true"
                              ? true
                              : false
                          }
                        />
                        <label for="getSetting">View</label>
                      </div>
                      {accessDataList?.getSetting === "true" && (
                        <div class="form-group m-2">
                          <input
                            type="checkbox"
                            id="editSetting"
                            onChange={(e) => {
                              setAccessDataList({
                                ...accessDataList,
                                editSetting:
                                  e.target.checked === true ? "true" : "false",
                              });
                            }}
                            defaultChecked={
                              accessObject?.access?.editSetting === "true"
                                ? true
                                : false
                            }
                          />
                          <label for="editSetting">Edit</label>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* Social */}
          <div className="col-sm-12 col-lg-6 col-md-6 ">
            <table className="m-2">
              <thead>
                <th>Social</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="custom-checkbox d-flex">
                      <div class="form-group m-2">
                        <input
                          type="checkbox"
                          id="getSocial"
                          onChange={(e) => {
                            setAccessDataList({
                              ...accessDataList,
                              getSocial:
                                e.target.checked === true ? "true" : "false",
                            });
                          }}
                          defaultChecked={
                            accessObject?.access?.getSocial === "true"
                              ? true
                              : false
                          }
                        />
                        <label for="getSocial">View</label>
                      </div>
                      {accessDataList?.getSocial === "true" && (
                        <div class="form-group m-2">
                          <input
                            type="checkbox"
                            id="editSocial"
                            onChange={(e) => {
                              setAccessDataList({
                                ...accessDataList,
                                editSocial:
                                  e.target.checked === true ? "true" : "false",
                              });
                            }}
                            defaultChecked={
                              accessObject?.access?.editSocial === "true"
                                ? true
                                : false
                            }
                          />
                          <label for="editSocial">Edit</label>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <button
        style={{ width: "100px" }}
        type="submit"
        className="btn btn-primary btn-block mt-4 mr-2"
        disabled={buttonLoader}
        onClick={() => handelAddData()}
      >
        <div
          className="d-flex"
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            className="d-flex"
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <b>Submit</b>
          </div>
          {buttonLoader && (
            <div>
              <div
                class="spinner-border ml-2 mt-1"
                role="status"
                style={{ height: "20px", width: "20px" }}
              ></div>
            </div>
          )}
        </div>
      </button>
    </div>
  );
}
