import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// import axios from "axios";
// import { API_URL } from "../../config";
import Loader from "../Loader/Loader";
// import { useAppContext } from "../../Lib/ContextLib";
// import socketIO from "socket.io-client";
import { toast } from "react-toastify";
import DataServices from "../../services/requestApi";

export default function ReactTables() {
  // let socket = socketIO(API_URL, { transports: ["websocket"] });

  // const { accessObject } = useAppContext();
  const [dataRows, setdataRows] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [toggle, setToggle] = React.useState();
  const [buttonLoader, setButtonLoader] = React.useState(false);
  const [location, steLocation] = React.useState(false);

  React.useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoader(true);
    try {
      const res = await DataServices.GetAllSettingList();
      console.log("Game Setting res::: ", res);
      if (res.data.status) {
        setdataRows(res.data.data);
        setToggle(res.data.data[0].maintenance);
        setLoader(false);
      }
    } catch (error) {
      toast.error("ERROR");
      console.log("ERROR::: ", error);
    }
  };

  const LoginSchema = Yup.object().shape({
    maintenanceData: Yup.string().required("required"),
    referBonusValue: Yup.string().required("required"),
    referByBonusValue: Yup.string().required("required"),
    signupBonus: Yup.string().required("required"),
    apiKey: Yup.string().required("required"),
  });

  return (
    <>
      {!loader && (
        <div class=" p-3 ">
          <div className="card p-3 ">
            <h5 className="mt-3">Game Setting</h5>
            <Formik
              initialValues={{
                maintenanceData: dataRows[0]?.maintenanceData,
                referBonusValue: dataRows[0]?.referBonusValue,
                signupBonus: dataRows[0]?.signupBonus,
                referByBonusValue: dataRows[0]?.referByBonusValue,
                apiKey: dataRows[0]?.apiKey,
              }}
              validationSchema={LoginSchema}
              onSubmit={async (values) => {
                let merged = {
                  ...values,
                  maintenance: toggle,
                  location: location,
                  id: dataRows[0]?._id,
                };
                console.log("merged", merged);

                try {
                  setButtonLoader(true);
                  const res = await DataServices.UpdateSettingDetails(
                    dataRows[0]?._id,
                    merged
                  );
                  console.log("Game Setting res::: ", res);
                  if (res.data.status) {
                    toast.success(res.data.message);
                    setButtonLoader(false);
                  }
                } catch (error) {
                  setButtonLoader(false);
                  toast.error("ERROR");
                  console.log("ERROR::: ", error);
                }
              }}
            >
              {({ touched, errors, isSubmitting, values }) => (
                <div style={{ width: "100%" }}>
                  <Form>
                    <div className="row">
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="email" className="mt-3">
                            Maintenance
                          </label>
                          <br />
                          <label class="switch mt-3">
                            <input
                              type="checkbox"
                              name="maintenance"
                              onChange={(e) => {
                                setToggle(e.target.checked);
                              }}
                              defaultChecked={dataRows[0]?.maintenance}
                            />
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="email" className="mt-3">
                            Location
                          </label>
                          <br />
                          <label class="switch mt-3">
                            <input
                              type="checkbox"
                              name="location"
                              onChange={(e) => {
                                steLocation(e.target.checked);
                              }}
                              defaultChecked={dataRows[0]?.location}
                            />
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="password" className="mt-3">
                        ApiKey
                      </label>
                      <Field
                        type="text"
                        name="apiKey"
                        placeholder="Enter Maintenance Text"
                        className={`mt-2 form-control
                          ${
                            touched.apiKey && errors.apiKey ? "is-invalid" : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="apiKey"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password" className="mt-3">
                        Maintenance Text
                      </label>
                      <Field
                        type="text"
                        name="maintenanceData"
                        placeholder="Enter Maintenance Text"
                        className={`mt-2 form-control
                          ${
                            touched.maintenanceData && errors.maintenanceData
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="maintenanceData"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password" className="mt-3">
                        Refer Bonus Value
                      </label>
                      <Field
                        type="text"
                        name="referBonusValue"
                        placeholder="Enter Refer Bonus Value"
                        className={`mt-2 form-control
                          ${
                            touched.referBonusValue && errors.referBonusValue
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="referBonusValue"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password" className="mt-3">
                        New Player refer Bonus
                      </label>
                      <Field
                        type="text"
                        name="referByBonusValue"
                        placeholder="Enter Player Bonus Value"
                        className={`mt-2 form-control
                          ${
                            touched.referByBonusValue &&
                            errors.referByBonusValue
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="referByBonusValue"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password" className="mt-3">
                        Signup Bonus
                      </label>
                      <Field
                        type="text"
                        name="signupBonus"
                        placeholder="Enter Signup Bonus"
                        className={`mt-2 form-control
                          ${
                            touched.signupBonus && errors.signupBonus
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="signupBonus"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="d-flex">
                      <button
                        style={{ width: "100px" }}
                        type="submit"
                        className="btn btn-primary btn-block mt-4 mr-2"
                        disabled={buttonLoader}
                      >
                        <div
                          className="d-flex"
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            className="d-flex"
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <b>Submit</b>
                          </div>
                          {buttonLoader && (
                            <div>
                              <div
                                class="spinner-border ml-2 mt-1"
                                role="status"
                                style={{
                                  height: "20px",
                                  width: "20px",
                                }}
                              ></div>
                            </div>
                          )}
                        </div>
                      </button>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      )}
      {loader && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      )}
    </>
  );
}
