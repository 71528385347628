import React, { useEffect, useState } from "react";
import totalPlayerIcon from "./icon/total_player_icon.svg";
import total_bet_amount_icon from "./icon/total_bet_amount_icon.svg";
import total_win_amount_icon from "./icon/total_win_amount_icon.svg";
// import total_add_amount_icon from "./icon/total_add_amount_icon.svg";
// import total_withdraw_icon from "./icon/total_withdraw_icon.svg";
import total_income_icon from "./icon/total_income_icon.svg";
// import games_icon from "./icon/games.svg";
// import axios from "axios";
import CountUp from "react-countup";
// import { API_URL } from "../src/config";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "./Lib/ContextLib";

import ReactApexCharts from "react-apexcharts";
import { useTheme } from "@material-ui/core/styles";
import DataServices from "./services/requestApi";
import { generateColorsForChart } from "./utils";
import Loader from "./Pages/Loader/Loader";

export default function Dashboard() {
  const history = useNavigate();
  const {
    setActive1,
    setActive2,
    setActive3,
    setActive4,
    setActive5,
    setActive6,
    setActive7,
    setActive8,
    accessObject,
  } = useAppContext();
  const [totalPlayer, setTotalPlayer] = useState(0);

  React.useEffect(() => {
    getPlayerList();
  }, []);

  const getPlayerList = async () => {
    const { data: totalPlayerCount } = await DataServices.AllPlayerList();
    setTotalPlayer(totalPlayerCount?.data.length);
  };

  const theme = useTheme();

  //mostPlayed
  const [gamesMostPlayed, setGamesMostPlayed] = useState(null);
  const [countsMostPlayed, setCountsMostPlayed] = useState(null);
  const [colorsMostPlayed, setColorsMostPlayed] = useState(null);

  //winRatio
  const [countsWinRatio, setCountsWinRatio] = useState(null);

  //betRatio
  const [countsBetRatio, setCountsBetRatio] = useState(null);

  //gameProfits
  const [gamesProfitList, setGamesProfitList] = useState(null);
  const [countsProfitList, setCountsProfitList] = useState(null);
  const [colorsProfitList, setColorsProfitList] = useState(null);

  //live Player
  const [gamesLivePlayer, setGamesLivePlayer] = useState(null);
  const [countsLivePlayer, setCountsLivePlayer] = useState(null);
  const [colorsLivePlayer, setColorsLivePlayer] = useState(null);

  const [selectTime] = useState("all");
  const [loading, setLoading] = useState(false);

  const donutColors = {
    series1: "#826bf8",
    series2: "#00d4bd",
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const { data } = await DataServices.GetGameAnalytics({
          time: selectTime,
        });
        if (data?.status) {
          //mostPlayed
          const gameListMostPlayed = data?.data?.mostPlayed?.map(
            (item) => item?.gameName || "gameName"
          );
          const countListMostPlayed = data?.data?.mostPlayed.map(
            (item) => item?.count
          );
          const colorListMostPlayed = generateColorsForChart(
            gameListMostPlayed?.length
          );

          //winRatio
          const countListWinRatio = data?.data.winRatio.map(
            (item) => item?.count
          );
          const betSum = countListWinRatio.reduce(
            (accumulator, currentValue) => {
              return accumulator + currentValue;
            },
            0
          );
          //betRatio
          const countListBetRatio = data?.data.betRatio.map(
            (item) => item?.count
          );
          const winSum = countListBetRatio.reduce(
            (accumulator, currentValue) => {
              return accumulator + currentValue;
            },
            0
          );

          //winRatio
          setCountsWinRatio(winSum);

          //betRatio
          setCountsBetRatio(betSum);

          //ProfitList
          const gameListProfitList = data?.data?.gameProfits?.map(
            (item) => item?.gameName || "gameName"
          );
          const countListProfitList = data?.data?.gameProfits.map(
            (item) => item?.totalProfit || 0
          );
          const colorListProfitList = generateColorsForChart(
            countListProfitList?.length
          );
          //live player
          const gameListLivePlayer = data?.data?.livePlayers?.map(
            (item) => item?.country || "countryName"
          );
          const countListLivePlayer = data?.data?.livePlayers.map(
            (item) => item?.count || 0
          );
          const colorListMostPlayer = generateColorsForChart(
            countListProfitList?.length
          );

          //mostPlayed
          setGamesMostPlayed(gameListMostPlayed);
          setCountsMostPlayed(countListMostPlayed);
          setColorsMostPlayed(colorListMostPlayed);

          //ProfitList
          setGamesProfitList(gameListProfitList);
          setCountsProfitList(countListProfitList);
          setColorsProfitList(colorListProfitList);

          //Live Player
          setGamesLivePlayer(gameListLivePlayer);
          setCountsLivePlayer(countListLivePlayer);
          setColorsLivePlayer(colorListMostPlayer);

          setLoading(false);
        } else {
          setLoading(false);
          console.log(data?.message);
        }
      } catch (error) {
        setLoading(false);
        console.log("error::: ", error);
      }
    };
    getData();
  }, [selectTime]);

  const optionsWinRatio = {
    type: "donut",
    stroke: { width: 0 },
    labels: ["Bet", "Win"],
    colors: [donutColors.series1, donutColors.series2],
    dataLabels: {
      enabled: true,
      formatter: (val) => `${parseInt(val, 10)}%`,
    },
    legend: {
      position: "bottom",
      markers: { offsetX: -3 },
      labels: { colors: theme.palette.text.secondary },
      itemMargin: {
        vertical: 3,
        horizontal: 10,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              fontSize: "1rem",
            },
            value: {
              fontSize: "1rem",
              color: theme.palette.text.secondary,
              formatter: (val) => `${parseInt(val, 10)}`,
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 992,
        options: {
          chart: {
            height: 380,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 576,
        options: {
          chart: {
            height: 320,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: theme.typography.body1.fontSize,
                  },
                  value: {
                    fontSize: theme.typography.body1.fontSize,
                  },
                  total: {
                    fontSize: theme.typography.body1.fontSize,
                  },
                },
              },
            },
          },
        },
      },
    ],
  };

  const optionsGameProfit = {
    type: "donut",
    stroke: { width: 0 },
    labels: gamesProfitList,
    colors: colorsProfitList,
    dataLabels: {
      enabled: true,
      formatter: (val) => `${parseInt(val, 10)}%`,
    },
    legend: {
      position: "bottom",
      markers: { offsetX: -3 },
      labels: { colors: theme.palette.text.secondary },
      itemMargin: {
        vertical: 3,
        horizontal: 10,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              fontSize: "1rem",
            },
            value: {
              fontSize: "1rem",
              color: theme.palette.text.secondary,
              formatter: (val) => `${parseInt(val, 10)}`,
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 992,
        options: {
          chart: {
            height: 380,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 576,
        options: {
          chart: {
            height: 320,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: theme.typography.body1.fontSize,
                  },
                  value: {
                    fontSize: theme.typography.body1.fontSize,
                  },
                  total: {
                    fontSize: theme.typography.body1.fontSize,
                  },
                },
              },
            },
          },
        },
      },
    ],
  };

  //mostPlayed
  const optionsMostPlayed = {
    type: "donut",
    stroke: { width: 0 },
    labels: gamesMostPlayed,
    colors: colorsMostPlayed,
    dataLabels: {
      enabled: true,
      formatter: (val) => `${parseInt(val, 10)}%`,
    },
    legend: {
      position: "bottom",
      markers: { offsetX: -3 },
      labels: { colors: theme.palette.text.secondary },
      itemMargin: {
        vertical: 3,
        horizontal: 10,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              fontSize: "1rem",
            },
            value: {
              fontSize: "1rem",
              color: theme.palette.text.secondary,
              formatter: (val) => `${parseInt(val, 10)}`,
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 992,
        options: {
          chart: {
            height: 380,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 576,
        options: {
          chart: {
            height: 320,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: theme.typography.body1.fontSize,
                  },
                  value: {
                    fontSize: theme.typography.body1.fontSize,
                  },
                  total: {
                    fontSize: theme.typography.body1.fontSize,
                  },
                },
              },
            },
          },
        },
      },
    ],
  };

  //live player
  const optionsLivePlayer = {
    type: "donut",
    stroke: { width: 0 },
    labels: gamesLivePlayer,
    colors: colorsLivePlayer,
    dataLabels: {
      enabled: true,
      formatter: (val) => `${parseInt(val, 10)}%`,
    },
    legend: {
      position: "bottom",
      markers: { offsetX: -3 },
      labels: { colors: theme.palette.text.secondary },
      itemMargin: {
        vertical: 3,
        horizontal: 10,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              fontSize: "1rem",
            },
            value: {
              fontSize: "1rem",
              color: theme.palette.text.secondary,
              formatter: (val) => `${parseInt(val, 10)}`,
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 992,
        options: {
          chart: {
            height: 380,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 576,
        options: {
          chart: {
            height: 320,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: theme.typography.body1.fontSize,
                  },
                  value: {
                    fontSize: theme.typography.body1.fontSize,
                  },
                  total: {
                    fontSize: theme.typography.body1.fontSize,
                  },
                },
              },
            },
          },
        },
      },
    ],
  };

  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Dashboard</h1>
            </div>
          </div>
        </div>
      </div>

      {accessObject?.dashboardCount === "true" ? (
        <>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3 col-6">
                  <div
                    className="small-box"
                    style={{ backgroundColor: "#ffab39" }}
                    onClick={() => {
                      history("/playerlist", { state: { data: false } });
                      setActive1(false);
                      setActive2(true);
                      setActive3(true);
                      setActive4(true);
                      setActive5(true);
                      setActive6(true);
                      setActive7(true);
                      setActive8(true);
                    }}
                  >
                    <div className="inner p-lg-4 p-md-3">
                      <div
                        className="row  d-flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="col-sm-12 col-md-6 col-lg-6 order-2">
                          <h3
                            style={{
                              color: "white",
                              alignItems: "center",
                            }}
                            className="m-2 d-flex justify-content-center justify-content-md-center justify-content-lg-end "
                          >
                            <CountUp end={totalPlayer || 0} />
                          </h3>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-sm order-sm-start-1">
                          <img
                            alt="total player"
                            src={totalPlayerIcon}
                            height="50px"
                            width="50px"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="icon"></div>
                    <p className="small-box-footer">
                      <div>Total Player</div>
                    </p>
                  </div>
                </div>

                <div className="col-lg-3 col-6">
                  <div
                    className="small-box"
                    style={{ backgroundColor: "#2ec268" }}
                  >
                    <div className="inner p-lg-4 p-md-3">
                      <div
                        className="row  d-flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="col-sm-12 col-md-6 col-lg-6 order-2">
                          <h3
                            style={{
                              color: "white",
                              alignItems: "center",
                            }}
                            className="m-2 d-flex justify-content-center justify-content-md-center justify-content-lg-end "
                          >
                            <CountUp end={countsBetRatio || 0} />
                          </h3>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-sm order-sm-start-1">
                          <img
                            src={total_bet_amount_icon}
                            alt="total_bet_amount_icon"
                            height="50px"
                            width="50px"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="icon"></div>
                    <p className="small-box-footer">
                      <div style={{ color: "white" }}>Total Bet Amount</div>
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div
                    className="small-box"
                    style={{ backgroundColor: "#ff3843" }}
                  >
                    <div className="inner p-lg-4 p-md-3">
                      <div
                        className="row  d-flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="col-sm-12 col-md-6 col-lg-6 order-2">
                          <h3
                            style={{
                              color: "white",
                              alignItems: "center",
                            }}
                            className="m-2 d-flex justify-content-center justify-content-md-center justify-content-lg-end "
                          >
                            <CountUp end={countsWinRatio || 0} />
                          </h3>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-sm order-sm-start-1">
                          <img
                            src={total_win_amount_icon}
                            alt="total win amt"
                            height="50px"
                            width="50px"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="icon"></div>
                    <p className="small-box-footer">
                      <div style={{ color: "white" }}>Total Win Amount</div>
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-6 ">
                  <div
                    className="small-box "
                    style={{ backgroundColor: "#ca4ddd" }}
                  >
                    <div className="inner p-lg-4 p-md-3">
                      <div
                        className="row  d-flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="col-sm-12 col-md-6 col-lg-6 order-2">
                          <h3
                            style={{
                              color: "white",
                              alignItems: "center",
                            }}
                            className="m-2 d-flex justify-content-center justify-content-md-center justify-content-lg-end "
                          >
                            <CountUp
                              end={countsBetRatio - countsWinRatio || 0}
                            />
                          </h3>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-sm order-sm-start-1">
                          <img
                            src={total_income_icon}
                            alt="total income"
                            height="50px"
                            width="50px"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="icon"></div>
                    <p className="small-box-footer">
                      <div style={{ color: "white" }}>Total Income</div>
                    </p>
                  </div>
                </div>
              </div>

              <div className="row gx-2">
                <div className="col-lg-4  p-2">
                  <div className="card p-4">
                    <h5>
                      <b>Game Round</b>
                    </h5>
                    <p>Maximum bet in games.</p>
                    {loading ? (
                      <div
                        style={{
                          height: "400px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Loader />
                      </div>
                    ) : gamesMostPlayed?.length ? (
                      <>
                        <ReactApexCharts
                          type="donut"
                          height={400}
                          options={optionsMostPlayed}
                          series={countsMostPlayed}
                        />
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "400px",
                        }}
                      >
                        No data available !
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-4  p-2">
                  <div className="card p-4">
                    <h5>
                      <b>Game Win Ratio</b>
                    </h5>
                    <p>Most Games win by players</p>
                    {loading ? (
                      <div
                        style={{
                          height: "400px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Loader />
                      </div>
                    ) : (
                      <ReactApexCharts
                        type="donut"
                        height={400}
                        options={optionsWinRatio}
                        series={[countsBetRatio || 0, countsWinRatio || 0]}
                      />
                    )}
                  </div>
                </div>
                <div className="col-lg-4  p-2">
                  <div className="card p-4">
                    <h5>
                      <b>Game Win Ratio</b>
                    </h5>
                    <p>Most Games win by players</p>
                    {loading ? (
                      <div
                        style={{
                          height: "400px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Loader />
                      </div>
                    ) : gamesProfitList?.length ? (
                      <ReactApexCharts
                        type="donut"
                        height={400}
                        options={optionsGameProfit}
                        series={countsProfitList}
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "400px",
                        }}
                      >
                        No data available !
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-4  p-2">
                  <div className="card p-4">
                    <h5>
                      <b>Live Players</b>
                    </h5>
                    <p>Live players counts across country wise</p>
                    {loading ? (
                      <div
                        style={{
                          height: "400px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Loader />
                      </div>
                    ) : gamesLivePlayer?.length ? (
                      <ReactApexCharts
                        type="donut"
                        height={400}
                        options={optionsLivePlayer}
                        series={countsLivePlayer}
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "400px",
                        }}
                      >
                        No data available !
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <table className="w-100">
            <tbody>
              <tr style={{ background: "gray" }}>
                <td className="p-3">
                  <h5 className="text-center mb-0 text-light">Access Denied</h5>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}
