import React, { useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import SweetAlert from "react-bootstrap-sweetalert";
import Box from "@mui/material/Box";
import { Formik, Form, Field } from "formik";
// import { toast } from "react-toastify";
import axios from "axios";
import Pagination from "../../Pagination/Pagination";
import { API_URL } from "../../config";
// import Loader from "../Loader/Loader";
import { useAppContext } from "../../Lib/ContextLib";
import html2pdf from "html2pdf.js";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import moment from "moment";
import socketIO from "socket.io-client";
import { useLocation } from "react-router-dom";

export default function AddCash() {
  let socket = socketIO(API_URL, { transports: ["websocket"] });

  const { state } = useLocation();
  let { data } = state;

  const { accessObject, showPage, setShowPage } = useAppContext();
  // const [searchPhoneNo, setSearchPhoneNo] = React.useState();
  const [dataRows, setdataRows] = React.useState([]);
  const [showPrePage, setShowPrePage] = React.useState(10);
  const [loader, setLoader] = React.useState(false);
  const [changeStatus, setChangeStatus] = React.useState();
  const [filteredData, setFilteredData] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    start: 0,
    end: showPrePage,
  });
  const [alert, setAlert] = React.useState(null);
  const [rowValue, setRowValue] = React.useState();
  const [showBankDetails, setShowBankDetails] = React.useState();
  const [buttonLoader] = React.useState(false);

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const getBranch = () => {
    setLoader(true);
    axios(`${API_URL}/api/history/get-history`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        setLoader(false);
        console.log("Add cash=============>", response.data.message);
        // setdataRows(response.data.message);
        // const filteredBooks = response.data.message.filter((val) =>
        //   val.note.includes("addmoney")
        // );
        // setFilteredData(filteredBooks);

        console.log("1 ---", data);

        if (data === true) {
          setChangeStatus("pending");
          console.log("2 ---", response.data.message);

          const filtered = response.data.message.filter((item) => {
            return item.note === "addmoney" && item.status.includes("pending");
          });
          console.log("filtered :::", filtered);
          setFilteredData(filtered);
        } else {
          setdataRows(response.data.message);
          const filteredBooks = response.data.message.filter((val) =>
            val.note.includes("addmoney")
          );
          setFilteredData(filteredBooks);
        }
      })
      .catch((error) => {
        console.log("getBranch error :::", error);
      });
  };

  useEffect(() => {
    getBranch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateShowPerpagevalue = (value) => {
    setShowPrePage(value);
    setPagination({ start: 0, end: value });
  };

  const handleFilter = (event) => {
    const searchQuery = event.target.value;
    console.log("tableData::: ", dataRows);
    const filtered = dataRows.filter((item) => {
      return item.note === "addmoney" && item.email.includes(searchQuery);
    });
    setFilteredData(filtered);
  };

  const handleChangeStatus = (value) => {
    const searchQuery = value;
    setChangeStatus(searchQuery);
    data = false;
    console.log("searchQuery::: ", searchQuery);
    if (searchQuery !== "All") {
      const filtered = dataRows.filter((item) => {
        return item.note === "addmoney" && item.status.includes(searchQuery);
      });
      setFilteredData(filtered);
    } else {
      // const filtered = dataRows.filter((item) => {
      //   return item.note === "addmoney";
      // });
      // setFilteredData(filtered);
      getBranch();
    }
  };

  const handleApprove = (item) => {
    const data = {
      historyId: item.historyId,
      playerId: item.playerId,
      amount: item.amount,
      extraAmount: item.extraAmount || 0,
    };
    console.log("item::: ", data);
    axios(`${API_URL}/api/history/add-money-approve`, {
      method: "POST",
      credentials: "include",
      data: data,
    })
      .then((response) => {
        console.log("response::: ", response);
        getBranch();
        setChangeStatus("All");
        socket.emit("PlayerPayment");
        // alert(response.data.message);
      })
      .catch((error) => {
        // alert(error.message);
        setChangeStatus("");
        console.log("getBranch error :::", error);
      });
  };

  const handleDecline = (item) => {
    const data = {
      historyId: item.historyId,
      phoneNo: item.phoneNo,
      amount: item.amount,
    };
    console.log("item::: ", data);
    axios(`${API_URL}/api/history/add-money-decline`, {
      method: "POST",
      credentials: "include",
      data: data,
    })
      .then((response) => {
        console.log("response::: ", response);
        getBranch();
        setChangeStatus("All");
        socket.emit("PlayerPayment");
        // alert(response.data.message);
      })
      .catch((error) => {
        // alert(error.message);
        setChangeStatus("");
        console.log("getBranch error :::", error);
      });
  };

  const clickEditButton = (item) => {
    console.log("item edit value :::", item);
    setShowPage(true);
    setRowValue(item);
    axios
      .get(`${API_URL}/api/player/get-player-bank-details/${item.playerId}`)
      .then((res) => {
        console.log(res.data);
        setShowBankDetails(res.data.getPlayerData);
      });
  };

  const warningWithConfirmMessage = (item) => {
    console.log("done");
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title="Are you sure?"
        onConfirm={() => {
          handleDecline(item);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      ></SweetAlert>
    );
  };

  return (
    <div>
      {alert}
      {showPage ? (
        <div class="content p-3 ">
          <div className="card p-3">
            <Box sx={{ width: "100%" }}>
              <Formik
                initialValues={{
                  amount: rowValue ? rowValue?.amount : "",
                }}
                onSubmit={(values) => {
                  console.log("rowValue::: ", rowValue);
                  handleApprove(rowValue);
                  // axios
                  //   .post(`${API_URL}/transaction/withdraw-cash-approve`, {
                  //     historyId: rowValue.historyId,
                  //   })
                  //   .then((res) => {
                  //     if (res.data.isValid) {
                  //       setShowPage(false);
                  //       const data = {
                  //         message: "Approve",
                  //       };
                  //       socket.emit("paymentCheck", data);
                  //       toast.success(res.data.message);
                  //     } else {
                  //       setShowPage(false);
                  //       toast.warning(res.data.message);
                  //     }
                  //   });
                }}
              >
                {({ touched, errors, isSubmitting, values }) => (
                  <div>
                    <Form>
                      <div className="form-group">
                        <label htmlFor="password" className="mt-3">
                          Amount
                        </label>
                        <Field
                          disabled
                          type="text"
                          name="amount"
                          placeholder="Enter Amount"
                          className={`mt-2 form-control 
                          ${
                            touched.amount && errors.amount ? "is-invalid" : ""
                          }`}
                        />
                      </div>
                      <label htmlFor="password" className="mt-3">
                        Withdraw:
                      </label>
                      <table class="table table-bordered table-striped">
                        <tr>
                          {console.log("showBankDetails...", showBankDetails)}
                          <td style={{ fontWeight: 700 }}>Upi Id:</td>
                          <td>{showBankDetails?.upiId || "-"}</td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: 700 }}>Bank A/C No:</td>
                          <td>{showBankDetails?.bankAccountNo || "-"}</td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: 700 }}>
                            Bank A/C Holder Name:
                          </td>
                          <td>{showBankDetails?.bankName || "-"}</td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: 700 }}>
                            Bank A/C IFSC Code:
                          </td>
                          <td>{showBankDetails?.ifscCode || "-"}</td>
                        </tr>
                      </table>
                      <br />
                      <div className="d-flex">
                        <button
                          style={{ width: "100px" }}
                          type="submit"
                          className="btn btn-primary btn-block mt-4 mr-2"
                          disabled={buttonLoader}
                        >
                          <div
                            className="d-flex"
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              className="d-flex"
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <b>Approve</b>
                            </div>
                            {buttonLoader && (
                              <div>
                                <div
                                  class="spinner-border ml-2 mt-1"
                                  role="status"
                                  style={{ height: "20px", width: "20px" }}
                                ></div>
                              </div>
                            )}
                          </div>
                        </button>
                        <button
                          style={{ width: "100px" }}
                          type="submit"
                          className="btn btn-secondary btn-block mt-4 mr-2"
                          onClick={() => setShowPage(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </Box>
          </div>
        </div>
      ) : (
        <div className="content p-3">
          <div class="card ">
            <div class="pl-4 pr-4 pt-4">
              <div className="row">
                <div className="col-md-3 col-lg-3">
                  <h3 class="card-title">
                    <b>Add Cash</b>
                  </h3>
                </div>
                <div
                  className="col-md-3 col-lg-3 d-flex justify-content-start justify-content-lg-center "
                  style={{ padding: "10px" }}
                >
                  {accessObject?.addCashDownload === "true" && (
                    <div>
                      <button
                        className="btn btn-primary mr-2 "
                        onClick={() => {
                          const input = document.getElementById("download-PDF");
                          const opt = {
                            filename: "History-List.pdf",
                          };
                          html2pdf().set(opt).from(input).save().from().toPdf();
                        }}
                      >
                        PDF
                      </button>
                      <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button btn btn-info"
                        table="table-to-xls"
                        filename="History"
                        sheet="History"
                        buttonText="Excel"
                      />
                    </div>
                  )}
                </div>
                <div
                  className="col-md-3 col-lg-3 d-flex justify-content-start justify-content-lg-center "
                  style={{ padding: "10px" }}
                >
                  <div>
                    <div
                      className="form-group d-flex"
                      style={{ alignItems: "end" }}
                    >
                      <label>
                        <b className="px-2">Status</b>
                      </label>
                      <select
                        className={`mt-2 form-control`}
                        onChange={(e) => handleChangeStatus(e.target.value)}
                        value={changeStatus}
                      >
                        <option value="All">All</option>
                        <option value="pending">Pending</option>
                        <option value="success">Approve</option>
                        <option value="failed">Decline</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-lg-3 d-flex justify-content-start justify-content-lg-end">
                  <div>
                    <input
                      class="input-simple"
                      type="text"
                      placeholder="Email "
                      onChange={handleFilter}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="card-body"
              id="download-PDF"
              style={{ overflow: "scroll" }}
            >
              <table
                class="table table-bordered table-striped"
                id="table-to-xls"
              >
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>Id</th>
                    <th style={{ textAlign: "center" }}>Date</th>
                    <th style={{ textAlign: "center" }}>Player Id</th>
                    <th style={{ textAlign: "center" }}>Email</th>
                    <th style={{ textAlign: "center" }}>Amount</th>
                    <th style={{ textAlign: "center" }}>Note</th>
                    <th style={{ textAlign: "center" }}>Status</th>
                    {changeStatus === "pending" && (
                      <>
                        <th style={{ textAlign: "center" }}>Action</th>
                      </>
                    )}
                  </tr>
                </thead>
                {accessObject?.getAddCashList === "true" ? (
                  <>
                    <tbody>
                      {!loader ? (
                        <>
                          {filteredData.length > 0 ? (
                            <>
                              {filteredData
                                ?.slice(pagination.start, pagination.end)
                                .map((item, index) => (
                                  <>
                                    <tr
                                      key={index}
                                      style={{ textAlign: "center" }}
                                    >
                                      <td style={{ textAlign: "center" }}>
                                        {index + 1 + pagination.start}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {moment(item.createdAt).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {item.playerId ? item.playerId : "-"}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {item.email ? item.email : "-"}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {Math.round(item.amount * 100) / 100}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {item.note}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {item.status}
                                      </td>
                                      {changeStatus === "pending" && (
                                        <>
                                          {/* <td style={{ textAlign: "center" }}>
                                          <button
                                            className="btn mx-2"
                                            style={{
                                              backgroundColor: "green",
                                              color: "white",
                                            }}
                                            onClick={() => handleApprove(item)}
                                          >
                                            Approve
                                          </button>
                                          <button
                                            className="btn btn-danger"
                                            onClick={() => handleDecline(item)}
                                          >
                                            Decline
                                          </button>
                                        </td> */}
                                          <td style={{ textAlign: "center" }}>
                                            <EditIcon
                                              className="mr-3 courser"
                                              onClick={() =>
                                                clickEditButton(item)
                                              }
                                            />
                                            <ClearIcon
                                              className="courser text-danger"
                                              onClick={() =>
                                                warningWithConfirmMessage(item)
                                              }
                                            />
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                  </>
                                ))}
                            </>
                          ) : (
                            <>
                              <tr style={{ backgroundColor: "whitesmoke" }}>
                                <td colSpan={10}>
                                  <h5 className="text-center">
                                    No Data Available
                                  </h5>
                                </td>
                              </tr>
                            </>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </>
                ) : (
                  <>
                    <tr style={{ backgroundColor: "rgba(0,0,0,.05)" }}>
                      <td colSpan={10}>
                        <h5 className="text-center">Access Denied</h5>
                      </td>
                    </tr>
                  </>
                )}
                <tr style={{ backgroundColor: "whitesmoke" }}>
                  <td colSpan={10}>
                    <h5 className="text-center">No Data Available</h5>
                  </td>
                </tr>
              </table>
              {/* {accessObject?.getAddCashList == "true" && (
                <div className="mt-4">{loader && <Loader />}</div>
              )} */}
            </div>
            {accessObject?.getAddCashList === "true" &&
              filteredData.length !== 0 && (
                <Pagination
                  updateShowPerpagevalue={updateShowPerpagevalue}
                  showPrePage={showPrePage}
                  onPaginationChange={onPaginationChange}
                  total={filteredData.length}
                />
              )}
          </div>
        </div>
      )}
    </div>
  );
}
