import React, { useEffect, useState } from "react";
import ReactApexCharts from "react-apexcharts";
import { useTheme } from "@material-ui/core/styles";
import DataServices from "../../services/requestApi";
import { generateColorsForChart } from "../../utils";
import Loader from "../Loader/Loader";

export default function GameAnalytics() {
  const theme = useTheme();

  const [selectTime, setSelectTime] = useState("all");
  const [loading, setLoading] = useState(false);

  //mostPlayed
  const [gamesMostPlayed, setGamesMostPlayed] = useState(null);
  const [countsMostPlayed, setCountsMostPlayed] = useState(null);
  const [colorsMostPlayed, setColorsMostPlayed] = useState(null);

  //winRatio
  const [gamesWinRatio, setGamesWinRatio] = useState(null);
  const [countsWinRatio, setCountsWinRatio] = useState(null);
  const [colorsWinRatio, setColorsWinRatio] = useState(null);

  //betRatio
  const [gamesBetRatio, setGamesBetRatio] = useState(null);
  const [countsBetRatio, setCountsBetRatio] = useState(null);
  const [colorsBetRatio, setColorsBetRatio] = useState(null);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const { data } = await DataServices.GetGameAnalytics({
          time: selectTime,
        });
        console.log("data::: ", data);
        if (data?.status) {
          //mostPlayed
          const gameListMostPlayed = data?.data?.mostPlayed?.map(
            (item) => item?.gameName || "gameName"
          );
          const countListMostPlayed = data?.data?.mostPlayed.map(
            (item) => item?.count
          );
          const colorListMostPlayed = generateColorsForChart(
            gameListMostPlayed?.length
          );
          console.log("colorListMostPlayed::: ", colorListMostPlayed);

          //winRatio
          const gameListWinRatio = data?.data.winRatio.map(
            (item) => item?.gameName || "gameName"
          );
          const countListWinRatio = data?.data.winRatio.map(
            (item) => item?.count
          );
          const colorListWinRatio = generateColorsForChart(
            gameListWinRatio?.length
          );

          //betRatio
          const gameListBetRatio = data?.data.betRatio.map(
            (item) => item?.gameName || "gameName"
          );
          const countListBetRatio = data?.data.betRatio.map(
            (item) => item?.count
          );
          const colorListBetRatio = generateColorsForChart(
            gameListBetRatio?.length
          );

          //mostPlayed
          setGamesMostPlayed(gameListMostPlayed);
          setCountsMostPlayed(countListMostPlayed);
          setColorsMostPlayed(colorListMostPlayed);

          //winRatio
          setGamesWinRatio(gameListWinRatio);
          setCountsWinRatio(countListWinRatio);
          setColorsWinRatio(colorListWinRatio);

          //betRatio
          setGamesBetRatio(gameListBetRatio);
          setCountsBetRatio(countListBetRatio);
          setColorsBetRatio(colorListBetRatio);

          setLoading(false);
        } else {
          setLoading(false);
          console.log(data?.message);
        }
      } catch (error) {
        setLoading(false);
        console.log("error::: ", error);
      }
    };
    getData();
  }, [selectTime]);

  //mostPlayed
  const optionsMostPlayed = {
    type: "donut",
    stroke: { width: 0 },
    labels: gamesMostPlayed,
    colors: colorsMostPlayed,
    dataLabels: {
      enabled: true,
      formatter: (val) => `${parseInt(val, 10)}%`,
    },
    legend: {
      position: "bottom",
      markers: { offsetX: -3 },
      labels: { colors: theme.palette.text.secondary },
      itemMargin: {
        vertical: 3,
        horizontal: 10,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              fontSize: "1rem",
            },
            value: {
              fontSize: "1rem",
              color: theme.palette.text.secondary,
              formatter: (val) => `${parseInt(val, 10)}`,
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 992,
        options: {
          chart: {
            height: 380,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 576,
        options: {
          chart: {
            height: 320,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: theme.typography.body1.fontSize,
                  },
                  value: {
                    fontSize: theme.typography.body1.fontSize,
                  },
                  total: {
                    fontSize: theme.typography.body1.fontSize,
                  },
                },
              },
            },
          },
        },
      },
    ],
  };

  //winRatio
  const optionsWinRatio = {
    type: "donut",
    stroke: { width: 0 },
    labels: gamesWinRatio,
    colors: colorsWinRatio,
    dataLabels: {
      enabled: true,
      formatter: (val) => `${parseInt(val, 10)}%`,
    },
    legend: {
      position: "bottom",
      markers: { offsetX: -3 },
      labels: { colors: theme.palette.text.secondary },
      itemMargin: {
        vertical: 3,
        horizontal: 10,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              fontSize: "1rem",
            },
            value: {
              fontSize: "1rem",
              color: theme.palette.text.secondary,
              formatter: (val) => `${parseInt(val, 10)}`,
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 992,
        options: {
          chart: {
            height: 380,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 576,
        options: {
          chart: {
            height: 320,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: theme.typography.body1.fontSize,
                  },
                  value: {
                    fontSize: theme.typography.body1.fontSize,
                  },
                  total: {
                    fontSize: theme.typography.body1.fontSize,
                  },
                },
              },
            },
          },
        },
      },
    ],
  };

  //betRatio
  const optionsBetRatio = {
    type: "donut",
    stroke: { width: 0 },
    labels: gamesBetRatio,
    colors: colorsBetRatio,
    dataLabels: {
      enabled: true,
      formatter: (val) => `${parseInt(val, 10)}%`,
    },
    legend: {
      position: "bottom",
      markers: { offsetX: -3 },
      labels: { colors: theme.palette.text.secondary },
      itemMargin: {
        vertical: 3,
        horizontal: 10,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              fontSize: "1rem",
            },
            value: {
              fontSize: "1rem",
              color: theme.palette.text.secondary,
              formatter: (val) => `${parseInt(val, 10)}`,
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 992,
        options: {
          chart: {
            height: 380,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 576,
        options: {
          chart: {
            height: 320,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: theme.typography.body1.fontSize,
                  },
                  value: {
                    fontSize: theme.typography.body1.fontSize,
                  },
                  total: {
                    fontSize: theme.typography.body1.fontSize,
                  },
                },
              },
            },
          },
        },
      },
    ],
  };

  const durations = [
    { label: "All", value: "all" },
    { label: "Today", value: "today" },
    { label: "Last 7 days", value: "week" },
    { label: "Last Month", value: "month" },
    { label: "Last Year", value: "year" },
  ];

  return (
    <div>
      <div class=" p-3 ">
        <div className=" p-3 ">
          <label>Select Duration</label>
          <select
            className="form-control"
            style={{ width: "200px" }}
            value={selectTime}
            onChange={(e) => {
              setSelectTime(e.target.value);
            }}
          >
            {durations?.map((item, i) => (
              <option value={item.value}>{item.label}</option>
            ))}
          </select>
        </div>
        <div className="row gx-2">
          <div className="col-lg-4  p-2">
            <div className="card p-4">
              <h5>
                <b>Game Played</b>
              </h5>
              <p>Most Games Played by players in percentage(%).</p>
              {loading ? (
                <div
                  style={{
                    height: "400px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Loader />
                </div>
              ) : gamesMostPlayed?.length ? (
                <>
                  <ReactApexCharts
                    type="donut"
                    height={400}
                    options={optionsMostPlayed}
                    series={countsMostPlayed}
                  />
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "400px",
                  }}
                >
                  No data available !
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-4  p-2">
            <div className="card p-4">
              <h5>
                <b>Game Win Ratio</b>
              </h5>
              <p>Most Games win by players</p>
              {loading ? (
                <div
                  style={{
                    height: "400px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Loader />
                </div>
              ) : gamesWinRatio?.length ? (
                <ReactApexCharts
                  type="donut"
                  height={400}
                  options={optionsWinRatio}
                  series={countsWinRatio}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "400px",
                  }}
                >
                  No data available !
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-4 p-2">
            <div className="card p-4">
              <h5>
                <b>Bet Game Ratio</b>
              </h5>
              <p>Most Games bet by players</p>
              {loading ? (
                <div
                  style={{
                    height: "400px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Loader />
                </div>
              ) : gamesBetRatio?.length ? (
                <ReactApexCharts
                  type="donut"
                  height={400}
                  options={optionsBetRatio}
                  series={countsBetRatio}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "400px",
                  }}
                >
                  No data available !
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
