import React from "react";
// import axios from "axios";
// import { API_URL } from "../../config";
import moment from "moment";
import Loader from "../Loader/Loader";
// import { useAppContext } from "../../Lib/ContextLib";
import DataServices from "../../services/requestApi";

export default function RefferalList({ playerId }) {
  // const { accessObject } = useAppContext();
  // const [dataRows, setdataRows] = React.useState([]);
  // const [searchPhoneNo, setSearchPhoneNo] = React.useState();
  const [showPrePage] = React.useState(10);
  const [loader, setLoader] = React.useState(false);
  const [filteredData, setFilteredData] = React.useState([]);

  const [pagination] = React.useState({
    start: 0,
    end: showPrePage,
  });

  const getBranch = async () => {
    try {
      // AllReferList;
      setLoader(true);
      const res = await DataServices.AllReferList(playerId);
      console.log("AllReferList res::: ", res);
      setLoader(false);
      setFilteredData(res?.data?.data);
    } catch (error) {}
    // setLoader(true);
    // axios(`${API_URL}/api/player/get-player`, {
    //   method: "GET",
    //   credentials: "include",
    // })
    //   .then(function (response) {
    //     setLoader(false);
    //     console.log("dataTable.dataRows :::", response.data.message);
    //     setdataRows(response.data.message);

    //     const filteredBooks = response.data.message.filter((val) =>
    //       val.playerStatus.includes("banned")
    //     );
    //     setFilteredData(filteredBooks);
    //   })
    //   .catch(function (error) {
    //     console.log("getBranch error :::", error);
    //   });
  };

  React.useEffect(() => {
    getBranch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const filterData = (str) => {
  //   const emailRegex = /\S+@\S+\.\S+/;
  //   const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;
  //   return emailRegex.test(str) || phoneRegex.test(str);
  // };

  // Event handler for filtering the data
  // const handleFilter = (event) => {
  //   const searchQuery = event.target.value;
  //   console.log("tableData::: ", dataRows);
  //   const filtered = dataRows

  //     .filter((item) => filterData(item.email) || filterData(item.phoneNo))
  //     .filter((item) => {
  //       item.playerStatus == "banned" &&
  //         (item.email.includes(searchQuery) ||
  //           item.phoneNo.includes(searchQuery));
  //     });
  //   setFilteredData(filtered);
  // };

  return (
    <div>
      <div class="card-body" style={{ overflow: "scroll" }}>
        <table id="" class="table table-bordered table-striped">
          <thead>
            <tr>
              <th scope="col" style={{ textAlign: "center" }}>
                SrNo
              </th>
              <th scope="col" style={{ textAlign: "center" }}>
                Player Id
              </th>

              <th scope="col" style={{ textAlign: "center" }}>
                User Name
              </th>
              <th scope="col" style={{ textAlign: "center" }}>
                Email
              </th>
              <th scope="col" style={{ textAlign: "center" }}>
                Balance
              </th>
              <th scope="col" style={{ textAlign: "center" }}>
                Join Date
              </th>
              <th scope="col" style={{ textAlign: "center" }}>
                Status
              </th>
            </tr>
          </thead>

          <>
            <tbody>
              {!loader ? (
                <>
                  {filteredData?.length > 0 ? (
                    <>
                      {filteredData
                        ?.slice(pagination.start, pagination.end)
                        .map((row, index) => (
                          <>
                            <tr key={index} style={{ textAlign: "center" }}>
                              <td style={{ textAlign: "center" }}>
                                {index + 1 + pagination.start}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {row.playerId}
                              </td>

                              <td style={{ textAlign: "center" }}>
                                {row.username}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {row.email}
                              </td>
                              <td>
                                {Math.round(row?.totalBalance * 100) / 100}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {moment(row.createdAt).format("DD-MM-YYYY")}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {row.playerStatus}
                              </td>
                            </tr>
                          </>
                        ))}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td colSpan={8}>
                          <h5 className="text-center">No Data Available</h5>
                        </td>
                      </tr>
                    </>
                  )}
                </>
              ) : (
                ""
              )}
            </tbody>
          </>
        </table>

        <div className="mt-4">{loader && <Loader />}</div>
      </div>
      {/* {filteredData?.length !== 0 && (
        <Pagination
          showPrePage={showPrePage}
          onPaginationChange={onPaginationChange}
          total={filteredData?.length}
        />
      )} */}
    </div>
  );
}
