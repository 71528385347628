import React from "react";
// import axios from "axios";
// import { API_URL } from "../../config";
import moment from "moment";
import Pagination from "../../Pagination/Pagination";
import Loader from "../Loader/Loader";
import { useAppContext } from "../../Lib/ContextLib";
import DataServices from "../../services/requestApi";
import { toast } from "react-toastify";
import ClearIcon from "@mui/icons-material/Clear";
import SweetAlert from "react-bootstrap-sweetalert";
// import EditIcon from "@mui/icons-material/Edit";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import Home from "./Home";
// import History from "./HistoryList";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function GuestUser() {
  const { accessObject, showPage } = useAppContext();
  const [dataRows, setdataRows] = React.useState([]);
  const [value, setValue] = React.useState(0);
  // const [searchPhoneNo, setSearchPhoneNo] = React.useState();
  const [showPrePage, setShowPrePage] = React.useState(10);
  const [loader, setLoader] = React.useState(false);
  const [filteredData, setFilteredData] = React.useState([]);
  // const [historyData, setHistoryData] = React.useState([]);
  // const [rowValue, setRowValue] = React.useState();

  React.useEffect(() => {
    getBranch();
  }, []);
  const [pagination, setPagination] = React.useState({
    start: 0,
    end: showPrePage,
  });
  const [alert, setAlert] = React.useState(null);
  const updateShowPerpagevalue = (value) => {
    setShowPrePage(value);
    setPagination({ start: 0, end: value });
  };
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const getBranch = async () => {
    setLoader(true);
    try {
      const res = await DataServices.AllGuestUserList();
      console.log("data::: ", res);
      setLoader(false);
      setdataRows(res?.data?.data);
      setFilteredData(res?.data?.data);
    } catch (error) {
      toast.error("Error");
    }
  };

  const handleFilter = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    console.log("searchQuery::: ", searchQuery);
    console.log("tableData::: ", dataRows);
    const filtered = dataRows.filter((item) => {
      console.log("item::: ", item);
      return (
        item.playerId?.toLowerCase().includes(searchQuery) ||
        item.username?.toLowerCase().includes(searchQuery)
      );
    });
    setFilteredData(filtered);
  };

  const deleteData = async (e) => {
    console.log("e===>", e._id);
    const res = await DataServices.DeleteGuestPlayer(e._id);
    if (res.status) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  const warningWithConfirmMessage = (e) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title="Are you sure?"
        onConfirm={() => {
          deleteData(e);
          successDeleted();
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      ></SweetAlert>
    );
  };

  const successDeleted = () => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title="Deleted!"
        onConfirm={() => {
          getBranch();
          setAlert(null);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      >
        Your Player Record has been deleted
      </SweetAlert>
    );
  };

  // const clickEditButton = async (item) => {
  //   setShowPage(true);
  //   setValue(0);
  //   setRowValue(item);
  //   try {
  //     // AllHistoryList;
  //     const res = await DataServices.GuestHistoryListById({
  //       playerId: item.playerId,
  //     });
  //     console.log("res?.data.data::: ", res?.data.data);
  //     setHistoryData(res?.data.data);
  //   } catch (error) {}
  // };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      {alert}
      {showPage ? (
        <div class="content p-3 ">
          <div className="card p-3">
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  scrollButtons="auto"
                  variant="scrollable"
                >
                  <Tab
                    label="Home"
                    {...a11yProps(0)}
                    style={{ width: "190px" }}
                  />

                  {/* <Tab
                        label="KYC Player"
                        {...a11yProps(1)}
                        style={{ width: "190px" }}
                      /> */}
                  <Tab
                    label="History List"
                    {...a11yProps(1)}
                    style={{ width: "190px" }}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                {/* <Home rowValue={rowValue} /> */}
              </TabPanel>

              <TabPanel value={value} index={1}>
                {/* <History historyData={historyData} /> */}
              </TabPanel>
            </Box>
          </div>
        </div>
      ) : (
        <div className="content p-3">
          <div class="card ">
            <div class="pl-4 pr-4 pt-4">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  {" "}
                  <h3 class="card-title pb-3">
                    <b>Guest player</b>
                  </h3>
                </div>
                <div className="col-md-6 col-lg-6 d-flex justify-content-start justify-content-lg-end">
                  <div>
                    <input
                      class="input-simple"
                      type="text"
                      placeholder="PlayerId and username"
                      onChange={handleFilter}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body" style={{ overflow: "scroll" }}>
              <table id="" class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col" style={{ textAlign: "center" }}>
                      SrNo
                    </th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      Date
                    </th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      Player Id
                    </th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      Name
                    </th>

                    <th scope="col" style={{ textAlign: "center" }}>
                      Status
                    </th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                {accessObject?.getPlayerBannedList === "true" ? (
                  <>
                    <tbody>
                      {!loader ? (
                        <>
                          {filteredData.length > 0 ? (
                            <>
                              {filteredData
                                ?.slice(pagination.start, pagination.end)
                                .map((row, index) => (
                                  <>
                                    <tr
                                      key={index}
                                      style={{ textAlign: "center" }}
                                    >
                                      <td style={{ textAlign: "center" }}>
                                        {index + 1 + pagination.start}
                                      </td>
                                      <td>
                                        {moment(row.createdAt).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {row.playerId}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {row.username}
                                      </td>

                                      <td style={{ textAlign: "center" }}>
                                        {row.playerStatus}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {/* {accessObject?.playerListEdit ===
                                          "true" && (
                                          <EditIcon
                                            className="mr-3 courser"
                                            onClick={() => clickEditButton(row)}
                                          />
                                        )} */}
                                        {accessObject?.playerListDelete ===
                                          "true" && (
                                          <ClearIcon
                                            className="courser text-danger"
                                            onClick={() =>
                                              warningWithConfirmMessage(row)
                                            }
                                          />
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                ))}
                            </>
                          ) : (
                            <>
                              <tr>
                                <td colSpan={9}>
                                  <h5 className="text-center">
                                    No Data Available
                                  </h5>
                                </td>
                              </tr>
                            </>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </>
                ) : (
                  <>
                    <tr style={{ backgroundColor: "rgba(0,0,0,.05)" }}>
                      <td colSpan={9}>
                        <h5 className="text-center">Access Denied</h5>
                      </td>
                    </tr>
                  </>
                )}
              </table>
              {/* {accessObject?.getPlayerBannedList == "true" && (
              <div className="mt-4">{loader && <Loader />}</div>
            )} */}
              <div className="mt-4">{loader && <Loader />}</div>
            </div>
            {filteredData.length !== 0 && (
              <Pagination
                updateShowPerpagevalue={updateShowPerpagevalue}
                showPrePage={showPrePage}
                onPaginationChange={onPaginationChange}
                total={filteredData.length}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
