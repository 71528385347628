import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// import axios from "axios";
import { useAppContext } from "../../Lib/ContextLib";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { API_URL } from "../../config";
import DataServices from "../../services/requestApi";
import axiosInstance from "../../services/http-common";
import Logo from "../../img/Web_Swift_logo.png";
import MailRoundedIcon from "@mui/icons-material/MailRounded";
import LockRoundedIcon from "@mui/icons-material/LockRounded";

export default function Login() {
  const history = useNavigate();

  const [buttonLoader, setButtonLoader] = React.useState(false);

  const { setAuthenticated, setAccessOject } = useAppContext();

  const LoginSchema4 = Yup.object().shape({
    email: Yup.string().required("required"),
    password: Yup.string().required("required"),
  });

  return (
    <div className="hold-transition login-page">
      <div class="login-box">
        <div class="card card-outline card-success">
          <div class="card-header text-center">
            {/* <p class="h1">
              <b>Casino</b>
            </p> */}
            <img
              src={Logo}
              alt="Logo"
              style={{ opacity: "0.8", width: "70%", height: "50px" }}
            />
          </div>
          <div class="card-body">
            <p class="login-box-msg">Sign in to start your session</p>

            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={LoginSchema4}
              onSubmit={async (values) => {
                setButtonLoader(true);
                console.log("Login=====>", values);
                // const response = await DataServices.Login(values);
                // console.log("response::: ", response.data.status);
                // if (response.data.status) {
                //   console.log("iffffff::: ");
                //   sessionStorage.setItem("token", response.data.data.token);
                //   // console.log("result::: ", response.data.result[0].access);
                //   // sessionStorage.setItem(
                //   //   "user",
                //   //   JSON.stringify(response.data.result[0].access)
                //   // );
                //   // sessionStorage.setItem(
                //   //   "email",
                //   //   response.data.result[0].email
                //   // );
                //   // setAccessOject(response.data.result[0].access);

                //   axios.defaults.headers.common["token"] =
                //     response.data.data.token;
                //   setAuthenticated(true);
                //   toast.success(response.data.message);
                //   history("/dashboard");
                //   setButtonLoader(false);
                // } else {
                //   console.log("else::: ");

                //   toast.warning(response.data.message);
                //   setButtonLoader(false);
                // }

                // setAuthenticated(true);
                // toast.success("Login Successfully");
                // history("/dashoard");
                // setAccessOject({
                //   dashboardCount: "true",
                //   getAdminList: "true",
                //   adminListAdd: "true",
                //   adminListEdit: "true",
                //   adminListDelete: "true",
                //   adminAccessUser: "true",
                //   getPlayerList: "true",
                //   playerListEdit: "true",
                //   playerListDelete: "true",
                //   getPlayerBannedList: "true",
                //   getLivePlayerAppList: "true",
                //   getLivePlayerGameList: "true",
                //   getTeenPattiTourList: "true",
                //   teenPattiTourAdd: "true",
                //   teenPattiTourDelete: "true",
                //   getAviatorTourList: "true",
                //   getAndarBaharTourList: "true",
                //   getColorPredictionTourList: "true",
                //   getCarRouletteTourList: "true",
                //   getDragonTigerTourList: "true",
                //   getSevenUpDownTourList: "true",
                //   teenPattiTourEdit: "true",
                //   aviatorTourEdit: "true",
                //   andarBaharTourEdit: "true",
                //   colorPredictionTourEdit: "true",
                //   carRouletteTourEdit: "true",
                //   dragonTigerTourEdit: "true",
                //   sevenUpDownTourEdit: "true",
                //   getNotificationList: "true",
                //   notificationAdd: "true",
                //   notificationDelete: "true",
                //   getCoinPackageList: "true",
                //   coinPackageAdd: "true",
                //   coinPackageEdit: "true",
                //   coinPackageDelete: "true",
                //   getVersionList: "true",
                //   versionEdit: "true",
                //   getSpinWheelList: "true",
                //   spinWheelAdd: "true",
                //   spinWheelEdit: "true",
                //   spinWheelDelete: "true",
                //   getNoticeList: "true",
                //   noticeEdit: "true",
                //   getBannerList: "true",
                //   bannerAdd: "true",
                //   bannerEdit: "true",
                //   bannerDelete: "true",
                //   getHistoryList: "true",
                //   getAddCashList: "true",
                //   getPayoutList: "true",
                //   getBetList: "true",
                //   getWinList: "true",
                //   getIncomeList: "true",
                //   getIncomeAviatorList: "true",
                //   getIncomeAndarBaharList: "true",
                //   getIncomeColorPredictionList: "true",
                //   getIncomeCarRouletteList: "true",
                //   getIncomeDragonTigerList: "true",
                //   getIncome7UpDownList: "true",
                //   getReportPlayerList: "true",
                //   getReportHistoryList: "true",
                //   getStaffAdminList: "true",
                //   getStaffPlayerList: "true",
                //   getMainteanceList: "true",
                //   mainteanceEdit: "true",
                //   getSocialList: "true",
                //   editSocial: "true",
                //   getBonusList: "true",
                //   bonusEdit: "true",
                //   contactDetailsList: "true",
                //   historyDownload: "true",
                //   addCashDownload: "true",
                //   payoutDownload: "true",
                //   betDownload: "true",
                //   winDownload: "true",
                //   incomeDetailsDownload: "true",
                //   aviatorDetailsDownload: "true",
                //   andarBaharDetailsDownload: "true",
                //   dragonTigerDetailsDownload: "true",
                //   carRouletteDetailsDownload: "true",
                //   colorPredictionDetailsDownload: "true",
                //   sevenUpDownDetailsDownload: "true",
                //   playerReportDownload: "true",
                //   historyReportDownload: "true",
                //   withdrawEdit: "true",
                //   getWithdrawList: "true",
                // });
                // setButtonLoader(false);
                try {
                  const { data } = await DataServices.Login(values);
                  console.log("response::: ", data?.status);
                  if (data?.status) {
                    setAuthenticated(true);
                    toast.success(data?.message);
                    sessionStorage.setItem("token", data?.data?.token);
                    axiosInstance.defaults.headers.token = data?.data?.token;
                    history("/dashboard");
                    setAccessOject({
                      dashboardCount: "true",
                      getAdminList: "true",
                      adminListAdd: "true",
                      adminListEdit: "true",
                      adminListDelete: "true",
                      adminAccessUser: "true",
                      getPlayerList: "true",
                      playerListEdit: "true",
                      playerListDelete: "true",
                      getPlayerBannedList: "true",
                      getLivePlayerAppList: "true",
                      getLivePlayerGameList: "true",
                      getTeenPattiTourList: "true",
                      teenPattiTourAdd: "true",
                      teenPattiTourDelete: "true",
                      getAviatorTourList: "true",
                      getAndarBaharTourList: "true",
                      getColorPredictionTourList: "true",
                      getCarRouletteTourList: "true",
                      getDragonTigerTourList: "true",
                      getSevenUpDownTourList: "true",
                      teenPattiTourEdit: "true",
                      aviatorTourEdit: "true",
                      andarBaharTourEdit: "true",
                      colorPredictionTourEdit: "true",
                      carRouletteTourEdit: "true",
                      dragonTigerTourEdit: "true",
                      sevenUpDownTourEdit: "true",
                      getNotificationList: "true",
                      notificationAdd: "true",
                      notificationDelete: "true",
                      getCoinPackageList: "true",
                      coinPackageAdd: "true",
                      coinPackageEdit: "true",
                      coinPackageDelete: "true",
                      getVersionList: "true",
                      versionEdit: "true",
                      getSpinWheelList: "true",
                      spinWheelAdd: "true",
                      spinWheelEdit: "true",
                      spinWheelDelete: "true",
                      getNoticeList: "true",
                      noticeEdit: "true",
                      getBannerList: "true",
                      bannerAdd: "true",
                      bannerEdit: "true",
                      bannerDelete: "true",
                      getHistoryList: "true",
                      getAddCashList: "true",
                      getPayoutList: "true",
                      getBetList: "true",
                      getWinList: "true",
                      getIncomeList: "true",
                      getIncomeAviatorList: "true",
                      getIncomeAndarBaharList: "true",
                      getIncomeColorPredictionList: "true",
                      getIncomeCarRouletteList: "true",
                      getIncomeDragonTigerList: "true",
                      getIncome7UpDownList: "true",
                      getReportPlayerList: "true",
                      getReportHistoryList: "true",
                      getStaffAdminList: "true",
                      getStaffPlayerList: "true",
                      getMainteanceList: "true",
                      mainteanceEdit: "true",
                      getSocialList: "true",
                      editSocial: "true",
                      getBonusList: "true",
                      bonusEdit: "true",
                      contactDetailsList: "true",
                      historyDownload: "true",
                      addCashDownload: "true",
                      payoutDownload: "true",
                      betDownload: "true",
                      winDownload: "true",
                      incomeDetailsDownload: "true",
                      aviatorDetailsDownload: "true",
                      andarBaharDetailsDownload: "true",
                      dragonTigerDetailsDownload: "true",
                      carRouletteDetailsDownload: "true",
                      colorPredictionDetailsDownload: "true",
                      sevenUpDownDetailsDownload: "true",
                      playerReportDownload: "true",
                      historyReportDownload: "true",
                      withdrawEdit: "true",
                      getWithdrawList: "true",
                    });
                    setButtonLoader(false);
                  } else {
                    toast.warning(data?.message);
                    setButtonLoader(false);
                  }
                } catch (e) {
                  console.log("e::: ", e);
                  if (e?.response?.data.statusCode === 400) {
                    console.log("else::: ");
                    toast.warning(e?.response?.data.message);
                    setButtonLoader(false);
                  }
                }
              }}
            >
              {({ touched, errors, isSubmitting, values }) => (
                <div>
                  <Form>
                    <div class="input-group mb-3">
                      <Field
                        type="email"
                        name="email"
                        className={`form-control
                          ${touched.email && errors.email ? "is-invalid" : ""}`}
                        placeholder="Email"
                      />
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <MailRoundedIcon />
                        </div>
                      </div>
                      <ErrorMessage
                        component="div"
                        name="email"
                        className="invalid-feedback"
                      />
                    </div>
                    <div class="input-group mb-3">
                      <Field
                        type="password"
                        name="password"
                        className={`form-control
                          ${
                            touched.password && errors.password
                              ? "is-invalid"
                              : ""
                          }`}
                        placeholder="Password"
                      />
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <LockRoundedIcon />
                        </div>
                      </div>
                      <ErrorMessage
                        component="div"
                        name="password"
                        className="invalid-feedback"
                      />
                    </div>
                    <p
                      className="mb-0"
                      style={{
                        textAlign: "end",
                        color: "#25124d",
                        cursor: "pointer",
                      }}
                      onClick={() => history("/forgot-password")}
                    >
                      Forgot Password?
                    </p>

                    <button
                      style={{ width: "100px" }}
                      type="submit"
                      className="btn btn-primary btn-block mt-4 mr-2"
                      disabled={buttonLoader}
                    >
                      <div
                        className="d-flex"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className="d-flex"
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <b>Submit</b>
                        </div>
                        {buttonLoader && (
                          <div>
                            <div
                              class="spinner-border ml-2 mt-1"
                              role="status"
                              style={{ height: "20px", width: "20px" }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </button>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
