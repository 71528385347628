import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useAppContext } from "../../Lib/ContextLib";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_URL } from "../../config";
import Logo from "../../img/Web_Swift_logo.png";
import LockRoundedIcon from "@mui/icons-material/LockRounded";

export default function ChangePassword() {
  const history = useNavigate();
  const [buttonLoader, setButtonLoader] = React.useState(false);

  const { setAuthenticated } = useAppContext();

  const LoginSchema4 = Yup.object().shape({
    currentPass: Yup.string().required("required"),
    newPass: Yup.string().required("required"),
    confirmPass: Yup.string()
      .oneOf([Yup.ref("newPass"), null], "Passwords must match")
      .required("required"),
  });

  return (
    <div className="hold-transition login-page">
      <div class="login-box">
        <div class="card card-outline card-success">
          <div class="card-header text-center">
            {/* <a class="h1">
              <b>Casino</b>
            </a> */}
            <img
              src={Logo}
              alt="Logo"
              style={{ opacity: "0.8", width: "70%", height: "50px" }}
            />
          </div>
          <div class="card-body">
            <p class="login-box-msg">Change Password</p>

            <Formik
              initialValues={{
                currentPass: "",
                newPass: "",
                confirmPass: "",
              }}
              validationSchema={LoginSchema4}
              onSubmit={(values, { resetForm }) => {
                setButtonLoader(true);
                const email = sessionStorage.getItem("email");
                console.log("email::: ", email);

                const data = {
                  email: email,
                  password: values.newPass,
                  currentPassword: values.currentPass,
                };
                axios(`${API_URL}/api/auth/change-password`, {
                  method: "POST",
                  credentials: "include",
                  data: data,
                })
                  .then((result) => {
                    console.log("result::: ", result);
                    if (result.data.isValid) {
                      toast.success(result.data.message);
                      history("/login");
                      sessionStorage.clear();
                      setAuthenticated(false);
                      resetForm();
                      setButtonLoader(false);
                    } else {
                      toast.warning(result.data.message);
                      resetForm();
                      setButtonLoader(false);
                    }
                  })
                  .catch((err) => {
                    console.log("err::: ", err);
                    setButtonLoader(false);
                  });
                console.log("data::: ", data);

                // setButtonLoader(true);
                // console.log(values);
              }}
            >
              {({ touched, errors, isSubmitting, values }) => (
                <div>
                  <Form>
                    <div class="input-group mb-3">
                      <Field
                        type="password"
                        name="currentPass"
                        className={`form-control
                          ${
                            touched.currentPass && errors.currentPass
                              ? "is-invalid"
                              : ""
                          }`}
                        placeholder="Current Password"
                      />
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <LockRoundedIcon />
                        </div>
                      </div>
                      <ErrorMessage
                        component="div"
                        name="currentPass"
                        className="invalid-feedback"
                      />
                    </div>
                    <div class="input-group mb-3">
                      <Field
                        type="password"
                        name="newPass"
                        className={`form-control
                          ${
                            touched.newPass && errors.newPass
                              ? "is-invalid"
                              : ""
                          }`}
                        placeholder="New Password"
                      />
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <LockRoundedIcon />
                        </div>
                      </div>
                      <ErrorMessage
                        component="div"
                        name="newPass"
                        className="invalid-feedback"
                      />
                    </div>
                    <div class="input-group mb-3">
                      <Field
                        type="password"
                        name="confirmPass"
                        className={`form-control
                          ${
                            touched.confirmPass && errors.confirmPass
                              ? "is-invalid"
                              : ""
                          }`}
                        placeholder="Confirm Password"
                      />
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <LockRoundedIcon />
                        </div>
                      </div>
                      <ErrorMessage
                        component="div"
                        name="confirmPass"
                        className="invalid-feedback"
                      />
                    </div>

                    <button
                      style={{ width: "100px" }}
                      type="submit"
                      className="btn btn-primary btn-block mt-4 mr-2"
                      disabled={buttonLoader}
                    >
                      <div
                        className="d-flex"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className="d-flex"
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <b>Submit</b>
                        </div>
                        {buttonLoader && (
                          <div>
                            <div
                              class="spinner-border ml-2 mt-1"
                              role="status"
                              style={{ height: "20px", width: "20px" }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </button>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
