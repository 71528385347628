import axios from "axios";
let AuthToken = sessionStorage.getItem("token");
console.log("token::: ", AuthToken);
export const BASEURL = process.env.REACT_APP_API_ENDPOINT;
// export const BASEURL = "http://localhost:5011/";

const Headers = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
  token: AuthToken,
};

const axiosInstance = axios.create({
  baseURL: `${BASEURL}/api/admin/`,
  headers: Headers,
});
export default axiosInstance;
