import React from "react";

export default function Footer() {
  return (
    <div>
      <footer className="main-footer ">
        <strong className="d-flex">
          Copyright © 2022-2023
          <p className="pl-2 mb-0" style={{ color: "#869099" }}>
            Casino.
          </p>
        </strong>
        All rights reserved.
        <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 1.1.1
        </div>
      </footer>
    </div>
  );
}
