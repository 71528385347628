import React from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import Box from "@mui/material/Box";
// import Loader from "../Loader/Loader";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloseIcon from "@mui/icons-material/Close";
import FileUpload from "react-drag-n-drop-image";
import * as Yup from "yup";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { API_URL } from "../../config";

function CustomBody() {
  return (
    <div
      className="drag-file-area mt-0"
      style={{
        border: "0px",
        borderRadius: "0px",
        width: "100%",
        position: "relative",
      }}
    >
      <div>
        <CloudDownloadIcon style={{ fontSize: "50px" }} />
      </div>
      <h3 className="dynamic-message" style={{ fontFamily: "Montserrat" }}>
        Drag &amp; drop any file here
      </h3>
      <label className="">
        <span className="">
          <span
            className="browse-files-text"
            style={{
              fontFamily: "Montserrat",
              color: "#539ffe",
            }}
          >
            browse file
          </span>
          <span style={{ fontFamily: "Montserrat" }}>from device</span>
        </span>
      </label>
    </div>
  );
}

function ShowData({ files, onRemoveImage }) {
  return (
    <div className="">
      {files.map((item) => {
        return (
          <>
            <div
              className="drag-file-area mt-0"
              style={{
                border: "0px",
                borderRadius: "0px",
                position: "relative",
              }}
            >
              <div className="d-flex" style={{ justifyContent: "center" }}>
                <div style={{ height: "200px", width: "200px" }} className="">
                  <img
                    src={item.preview}
                    alt="preview..."
                    height="100%"
                    width="100%"
                    style={{ objectFit: "contain" }}
                  />
                </div>
                <CloseIcon
                  style={{
                    cursor: "pointer",
                    color: "red",
                    position: "absolute",
                    right: 10,
                    top: 10,
                  }}
                  // onClick={() => {
                  //   setDragImage2(true);
                  //   setImage2(null);
                  // }}
                  onClick={() => onRemoveImage(item.id)}
                />
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
}

export default function SendNotification() {
  const [buttonLoader, setbuttonLoader] = React.useState(false);
  const [alert, setAlert] = React.useState(null);

  const [files, setFiles] = React.useState([]);
  const [showImages, setShowData] = React.useState(false);

  // const [isEditDataImage1, setIsEditDataImage1] = React.useState(false);

  const [imageFile, setImageFile] = React.useState();

  const onRemoveImage = (id) => {
    setShowData(false);
    setFiles((prev) => prev.filter((i) => i.id !== id));
  };
  const onError = (error) => {
    console.error(error);
  };
  const onChange = (e) => {
    if (files.length === 0) {
      console.log(e[0].file.name, "true");
      setShowData(true);
      console.log("e[0].file::: ", e[0].file);
      setImageFile(e[0].file);
      setFiles(e);
    }
  };

  const LoginSchema = Yup.object().shape({
    title: Yup.string().required("required"),
    body: Yup.string().required("required"),
  });

  const successAdd = (msg) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title={msg}
        onConfirm={() => {
          setAlert(null);

          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      ></SweetAlert>
    );
  };
  const warningAlert = () => {
    console.log("donw");
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title="Something Went Wrong .....!"
        onConfirm={() => {
          //  getBranch();
          setAlert(null);
          //  setShowPage(false);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      ></SweetAlert>
    );
  };
  return (
    <div>
      {alert}
      <div class="content p-3 ">
        <div className="card p-3">
          <Box sx={{ width: "100%" }}>
            <Formik
              initialValues={{
                title: "",
                body: "",
              }}
              validationSchema={LoginSchema}
              onSubmit={(values, { resetForm }) => {
                setbuttonLoader(true);

                const data = {
                  ...values,
                  imageUrl: imageFile.name,
                };
                console.log(data);
                axios
                  .post(`${API_URL}/api/firebase/push-notification`, data)
                  .then((res) => {
                    console.log("update :::", res.data);
                    if (res.data.isValid) {
                      successAdd(res.data.message);
                      setbuttonLoader(false);
                      resetForm();
                      setImageFile();
                      setFiles([]);
                      setShowData(false);
                    } else {
                      warningAlert();
                      setbuttonLoader(false);
                      resetForm();
                      setImageFile();
                      setShowData(false);
                    }
                  })
                  .catch((err) => {
                    setbuttonLoader(false);
                    console.log("update err :::", err);
                    warningAlert();
                    resetForm();
                    setImageFile();
                    setShowData(false);
                  });
              }}
            >
              {({ touched, errors, isSubmitting, values }) => (
                <div>
                  <Form>
                    <div className="form-group">
                      <label htmlFor="password" className="mt-3">
                        Title
                      </label>
                      <Field
                        type="text"
                        name="title"
                        placeholder="Enter title"
                        className={`mt-2 form-control
                          ${touched.title && errors.title ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        component="div"
                        name="title"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password" className="mt-3">
                        Body
                      </label>
                      <Field
                        type="text"
                        name="body"
                        placeholder="Enter body"
                        className={` form-control
                          ${touched.body && errors.body ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        component="div"
                        name="body"
                        className="invalid-feedback"
                      />
                    </div>
                    <label htmlFor="password" className="mt-3">
                      Image Url
                    </label>

                    <div className="file-upload-box">
                      {showImages ? (
                        <ShowData files={files} onRemoveImage={onRemoveImage} />
                      ) : (
                        <FileUpload
                          onError={onError}
                          body={<CustomBody />}
                          overlap={false}
                          fileValue={files}
                          onChange={(e) => onChange(e)}
                        />
                      )}
                    </div>
                    <button
                      style={{ width: "100px" }}
                      type="submit"
                      className="btn btn-primary btn-block mt-4 mr-2"
                      disabled={buttonLoader}
                    >
                      <div
                        className="d-flex"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className="d-flex"
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <b>Submit</b>
                        </div>
                        {buttonLoader && (
                          <div>
                            <div
                              class="spinner-border ml-2 mt-1"
                              role="status"
                              style={{ height: "20px", width: "20px" }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </button>
                  </Form>
                </div>
              )}
            </Formik>
          </Box>
        </div>
      </div>
    </div>
  );
}
