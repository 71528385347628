import React from "react";
// import EditIcon from "@mui/icons-material/Edit";
// import ClearIcon from "@mui/icons-material/Clear";
// import PropTypes from "prop-types";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { useAppContext } from "../../Lib/ContextLib";
import SweetAlert from "react-bootstrap-sweetalert";
import { API_URL } from "../../config";
import Loader from "../Loader/Loader";

export default function Withdraw() {
  const { setShowPage, accessObject } = useAppContext();

  const [alert, setAlert] = React.useState(null);
  // const [isEdit, setIsEdit] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [buttonLoader, setbuttonLoader] = React.useState(false);
  React.useEffect(() => {
    getBranch();
  }, []);
  const getBranch = () => {
    setLoader(true);
    axios(`${API_URL}/api/withdraw/get-withdraw`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        setLoader(false);
        console.log("dataTable.dataRows :::", response.data);
        setTableData(response.data.message);
      })
      .catch((error) => {
        console.log("getBranch error :::", error);
      });
  };
  const LoginSchema3 = Yup.object().shape({
    minWithdraw: Yup.string().required("required"),
    maxWithdraw: Yup.string().required("required"),
  });

  const successEdit = (msg) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title=""
        onConfirm={() => {
          getBranch();
          setAlert(null);
          // setIsEdit(false);
          setShowPage(false);
        }}
        confirmBtnBsStyle="success"
      >
        <h4>{msg}</h4>
      </SweetAlert>
    );
  };

  return (
    <>
      {alert}
      <div class="content p-3 ">
        <div className="card p-3">
          {!loader && (
            <Box sx={{ width: "100%" }}>
              <Formik
                initialValues={{
                  minWithdraw: tableData.minWithdraw || "",
                  maxWithdraw: tableData.maxWithdraw || "",
                }}
                validationSchema={LoginSchema3}
                onSubmit={(values) => {
                  setbuttonLoader(true);
                  let merged = { ...values, id: tableData?._id };
                  console.log("values", values);
                  axios(`${API_URL}/api/withdraw/update-withdraw`, {
                    method: "POST",
                    credentials: "include",
                    data: merged,
                  })
                    .then((result) => {
                      console.log("handleSubmit result :::", result.data);
                      setbuttonLoader(false);
                      successEdit(result.data.message);
                    })
                    .catch((err) => {
                      console.log("handleSubmit err :::", err);
                    });
                }}
              >
                {({ touched, errors, isSubmitting, values }) => (
                  <div>
                    <Form>
                      <div className="form-group">
                        <label htmlFor="password" className="mt-3">
                          Min Withdraw
                        </label>
                        <Field
                          type="number"
                          name="minWithdraw"
                          placeholder="minWithdraw"
                          disabled={
                            accessObject?.editWithdraw === "true" ? false : true
                          }
                          className={` form-control
                          ${
                            touched.minWithdraw && errors.minWithdraw
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="minWithdraw"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password" className="">
                          Max Withdraw
                        </label>
                        <Field
                          type="number"
                          name="maxWithdraw"
                          placeholder="maxWithdraw"
                          disabled={
                            accessObject?.editWithdraw === "true" ? false : true
                          }
                          className={` form-control
                          ${
                            touched.maxWithdraw && errors.maxWithdraw
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="maxWithdraw"
                          className="invalid-feedback"
                        />
                      </div>
                      {console.log(
                        "accessObject?.editWithdraw::: ",
                        accessObject,
                        accessObject?.editWithdraw === "true"
                      )}
                      {accessObject?.editWithdraw === "true" && (
                        <button
                          style={{ width: "100px" }}
                          type="submit"
                          className="btn btn-primary btn-block mt-4 mr-2"
                          disabled={buttonLoader}
                        >
                          <div
                            className="d-flex"
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              className="d-flex"
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <b>Submit</b>
                            </div>
                            {buttonLoader && (
                              <div>
                                <div
                                  class="spinner-border ml-2 mt-1"
                                  role="status"
                                  style={{ height: "20px", width: "20px" }}
                                ></div>
                              </div>
                            )}
                          </div>
                        </button>
                      )}
                    </Form>
                  </div>
                )}
              </Formik>
            </Box>
          )}
          {loader && <Loader />}
        </div>
      </div>
    </>
  );
}
