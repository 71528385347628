import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import Pagination from "../../Pagination/Pagination";
import { useAppContext } from "../../Lib/ContextLib";
import { API_URL } from "../../config";
import Loader from "../Loader/Loader";

export default function RoomTier() {
  const { showPage, setShowPage, accessObject } = useAppContext();
  const [alert, setAlert] = React.useState(null);
  const [tableData, setTableData] = React.useState([]);
  const [rowValue, setRowValue] = React.useState();
  const [openValue, setOpenValue] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  const [searchTourName] = React.useState();
  const [showPrePage] = React.useState(10);
  const [buttonLoader, setbuttonLoader] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    start: 0,
    end: showPrePage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const LoginSchema = Yup.object().shape({
    amount: Yup.string().required("required"),
    adminCommssion: Yup.string().required("required"),
  });

  React.useEffect(() => {
    getBranch();
  }, []);

  const getBranch = () => {
    setLoader(true);
    axios(`${API_URL}/api/room-tier/get-room-tier`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        setLoader(false);
        console.log("dataTable.dataRows :::", response.data);
        setTableData(response.data.message);
      })
      .catch((error) => {
        console.log("getBranch error :::", error);
      });
  };

  const searchingData = [];
  let data = [];
  console.log("tableData 111 ---", tableData, searchTourName);
  if (searchTourName) {
    console.log("tableData 222 ---", tableData, tableData.length > 0);
    data = tableData.filter((item) => {
      return Object.values(item?.tourName)
        .join("")
        .includes(searchTourName.toLowerCase());
    });
    console.log("data 111 ---", data);
    data?.map((dataItem) => searchingData.push(dataItem));
  } else {
    tableData.map((dataItem) => searchingData.push(dataItem));
  }

  const clickEditButton = (item) => {
    setShowPage(true);
    setRowValue(item);
    setOpenValue(2);
  };

  const openAddTournament = (value) => {
    setRowValue();
    console.log("openAddTournament :::", value);
    setShowPage(true);
    setOpenValue(value);
  };

  const updateTournament = (value) => {
    let merged = { ...value, id: rowValue._id };
    console.log("updateTournament merged value :::", merged);

    axios
      .post(`${API_URL}/api/room-tier/update-room-tier`, merged)
      .then((res) => {
        console.log("updateTournament update :::", res.data);
        if (res.data.isValid) {
          successEdit(res.data.message);
          setbuttonLoader(false);
        } else {
          warningAlert();
          setbuttonLoader(false);
        }
      })
      .catch((err) => {
        setbuttonLoader(false);
        console.log("updateTournament err :::", err);
        warningAlert();
      });
  };

  const addNewTournament = (value) => {
    console.log("addNewTournament ---", value);

    axios(`${API_URL}/api/room-tier/add-room-tier`, {
      method: "POST",
      credentials: "include",
      data: value,
    })
      .then((result) => {
        console.log("addNewTournament result :::", result.data);
        if (result.data.isValid) {
          setbuttonLoader(false);
          successAdd();
        } else {
          warningAlert();
          setbuttonLoader(false);
        }
      })
      .catch((err) => {
        console.log("addNewTournament err :::", err);
        warningAlert();
      });
  };

  const warningWithConfirmMessage = (e) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title="Are you sure?"
        onConfirm={() => {
          successDeleted();
          deleteData(e);
          //  setIsPlayerEditButtonClicked(false);
        }}
        onCancel={() => setAlert(null)}
        // confirmBtnCssClass={classes.button + " " + classes.danger}
        // cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      ></SweetAlert>
    );
  };

  const successDeleted = () => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title="Deleted!"
        onConfirm={() => {
          getBranch();
          setAlert(null);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      >
        Your Admin Commission Record has been deleted
      </SweetAlert>
    );
  };

  const deleteData = (e) => {
    console.log("e===>", e._id);
    axios
      .post(`${API_URL}/api/room-tier/delete-room-tier/${e._id}`)
      .then((res) => {
        console.log(res);
        console.log(res.data);
        getBranch();
      });
  };

  const successAdd = () => {
    console.log("done");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title="Added successfully!"
        onConfirm={() => {
          getBranch();
          setAlert(null);
          setShowPage(false);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      >
        Your Admin Commission Record has been Added...
      </SweetAlert>
    );
  };

  const successEdit = () => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title="Edited successfully!"
        onConfirm={() => {
          getBranch();
          setAlert(null);
          setShowPage(false);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      >
        Your Admin Commission Record has been Edited ...
      </SweetAlert>
    );
  };

  const warningAlert = () => {
    console.log("donw");
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title="Something Went Wrong .....!"
        onConfirm={() => {
          //  getBranch();
          setAlert(null);
          //  setShowPage(false);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      ></SweetAlert>
    );
  };

  return (
    <>
      {alert}
      {showPage ? (
        <div class="content p-3 ">
          <div className="card p-3">
            <Box sx={{ width: "100%" }}>
              <Formik
                initialValues={{
                  amount: rowValue ? rowValue?.amount : "",
                  adminCommssion: rowValue ? rowValue?.adminCommssion : "",
                }}
                validationSchema={LoginSchema}
                onSubmit={(values) => {
                  setbuttonLoader(true);
                  console.log(values);

                  if (parseInt(openValue) === 1) {
                    addNewTournament(values);
                  }
                  if (parseInt(openValue) === 2) {
                    updateTournament(values);
                  }
                }}
              >
                {({ touched, errors, isSubmitting, values, setFieldValue }) => (
                  <div>
                    <Form>
                      <div className="form-group">
                        <label htmlFor="password" className="mt-3">
                          Amount
                        </label>
                        <Field
                          type="number"
                          name="amount"
                          placeholder="Enter Amount..."
                          className={`mt-2 form-control
                          ${
                            touched.amount && errors.amount ? "is-invalid" : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="amount"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password" className="mt-3">
                          Admin Comission
                        </label>
                        <Field
                          type="number"
                          name="adminCommssion"
                          placeholder="Enter Admin Comission..."
                          className={`mt-2 form-control
                          ${
                            touched.adminCommssion && errors.adminCommssion
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="adminCommssion"
                          className="invalid-feedback"
                        />
                      </div>
                      <button
                        style={{ width: "100px" }}
                        type="submit"
                        className="btn btn-primary btn-block mt-4 mr-2"
                        disabled={buttonLoader}
                      >
                        <div
                          className="d-flex"
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            className="d-flex"
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <b>Submit</b>
                          </div>
                          {buttonLoader && (
                            <div>
                              <div
                                class="spinner-border ml-2 mt-1"
                                role="status"
                                style={{ height: "20px", width: "20px" }}
                              ></div>
                            </div>
                          )}
                        </div>
                      </button>
                    </Form>
                  </div>
                )}
              </Formik>
            </Box>
          </div>
        </div>
      ) : (
        <div className="content p-3">
          <div class="card ">
            {/* <div
              class="pl-4 pr-4 pt-4"
              style={{ display: "flex", justifyContent: "space-between" }}
                          > */}
            <div className="row pl-4 pr-4 pt-4">
              <div className="col-5">
                {accessObject?.addRoomTier === "true" && (
                  <button
                    style={{ width: "118px" }}
                    onClick={() => {
                      openAddTournament(1);
                    }}
                    type="button"
                    className="btn btn-primary btn-block "
                  >
                    <AddIcon />
                    Add New
                  </button>
                )}
              </div>
              <div className="col-7">
                <h3 class="card-title">
                  <b>Bot List</b>
                </h3>
              </div>
            </div>

            {/* <div style={{ display: "none" }}>
                <b className="mr-2">Tournament Name :</b>
                <input
                  placeholder="Enter Tournament Name"
                  value={searchSubType}
                  onChange={(e) => setSearchTourName(e.target.value)}
                />
              </div> */}
            {/* </div> */}

            <div class="card-body">
              <table id="" class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>SrNo</th>
                    <th>Amount</th>
                    <th>Admin Comission</th>
                    {(accessObject?.editRoomTier === "true" ||
                      accessObject?.deleteRoomTier === "true") && (
                      <th style={{ textAlign: "center" }}>Action</th>
                    )}
                  </tr>
                </thead>
                {accessObject?.getRoomTier === "true" ? (
                  <tbody>
                    {!loader ? (
                      <>
                        {searchingData.length > 0 ? (
                          <>
                            {searchingData
                              ?.slice(pagination.start, pagination.end)
                              .map((item, index) => (
                                <>
                                  <tr key={index}>
                                    <td>{index + 1 + pagination.start}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.adminCommssion}</td>
                                    {(accessObject?.editRoomTier === "true" ||
                                      accessObject?.deleteRoomTier ===
                                        "true") && (
                                      <td className="justify-content-evenly ">
                                        <div className="d-flex">
                                          {accessObject?.editRoomTier ===
                                            "true" && (
                                            <EditIcon
                                              className="mr-3 courser"
                                              onClick={() =>
                                                clickEditButton(item)
                                              }
                                            />
                                          )}
                                          {accessObject?.deleteRoomTier ===
                                            "true" && (
                                            <ClearIcon
                                              className="courser text-danger"
                                              onClick={() =>
                                                warningWithConfirmMessage(item)
                                              }
                                            />
                                          )}
                                        </div>
                                      </td>
                                    )}
                                  </tr>
                                </>
                              ))}
                          </>
                        ) : (
                          <>
                            <tr
                              style={{ backgroundColor: "whitesmoke" }}
                              className="text-center"
                            >
                              <td colSpan={6}>
                                <h5>No Data Available</h5>
                              </td>
                            </tr>
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </tbody>
                ) : (
                  <>
                    <tr
                      style={{ backgroundColor: "rgba(0,0,0,.05)" }}
                      className="text-center"
                    >
                      <td colSpan={6}>
                        <h5>Access Denied</h5>
                      </td>
                    </tr>
                  </>
                )}
              </table>
              <div className="mt-4">{loader && <Loader />}</div>
            </div>
            {searchingData.length !== 0 && (
              <Pagination
                showPrePage={showPrePage}
                onPaginationChange={onPaginationChange}
                total={searchingData.length}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
