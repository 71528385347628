import React from "react";
import axios from "axios";
import { API_URL } from "../../config";
import moment from "moment";
import Pagination from "../../Pagination/Pagination";
import Loader from "../Loader/Loader";
import { useAppContext } from "../../Lib/ContextLib";

export default function ContactDetails() {
  const { accessObject } = useAppContext();
  const [dataRows, setdataRows] = React.useState([]);
  const [searchPhoneNo] = React.useState();
  const [showPrePage, setShowPrePage] = React.useState(10);
  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    getBranch();
  }, []);
  const [pagination, setPagination] = React.useState({
    start: 0,
    end: showPrePage,
  });
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const updateShowPerpagevalue = (value) => {
    setShowPrePage(value);
    setPagination({ start: 0, end: value });
  };
  const getBranch = () => {
    setLoader(true);
    axios(`${API_URL}/api/contactUs/get-contactUs`, {
      method: "GET",
      credentials: "include",
    })
      .then(function (response) {
        setLoader(false);
        console.log("ContactForm :::", response.data.message);
        setdataRows(response.data.message);
      })
      .catch(function (error) {
        console.log("getBranch error :::", error);
      });
  };
  const searchingData = [];
  if (searchPhoneNo) {
    dataRows.filter((item) => {
      return Object.values(item.phoneNo)
        .join("")
        .includes(searchPhoneNo.toLowerCase());
    });
  } else {
    dataRows.map((dataItem) => searchingData.push(dataItem));
  }

  return (
    <div>
      <div className="content p-3">
        <div class="card ">
          <div
            class="pl-4 pr-4 pt-4"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h3 class="card-title">
              <b>Contact Details</b>
            </h3>
          </div>

          <div class="card-body" style={{ overflow: "scroll" }}>
            <table id="" class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th scope="col" style={{ textAlign: "center" }}>
                    SrNo
                  </th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Date
                  </th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Email
                  </th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Phone No
                  </th>

                  <th scope="col" style={{ textAlign: "center" }}>
                    Name
                  </th>

                  <th scope="col" style={{ textAlign: "center" }}>
                    Message
                  </th>
                </tr>
              </thead>
              <tbody>
                {accessObject?.contactDetailsList === "true" ? (
                  <>
                    {!loader ? (
                      <>
                        {searchingData.length > 0 ? (
                          <>
                            {searchingData
                              ?.slice(pagination.start, pagination.end)
                              .map((row, index) => (
                                <>
                                  <tr
                                    key={index}
                                    style={{ textAlign: "center" }}
                                  >
                                    <td style={{ textAlign: "center" }}>
                                      {index + 1 + pagination.start}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {moment(row.createdAt).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {row.email}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {row.phoneNo}
                                    </td>

                                    <td style={{ textAlign: "center" }}>
                                      {row.name}
                                    </td>

                                    <td style={{ textAlign: "center" }}>
                                      {row.message}
                                    </td>
                                  </tr>
                                </>
                              ))}
                          </>
                        ) : (
                          <>
                            <tr>
                              <td colSpan={6}>
                                <h5 className="text-center">
                                  No Data Available
                                </h5>
                              </td>
                            </tr>
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <tr style={{ backgroundColor: "rgba(0,0,0,.05)" }}>
                    <td colSpan={6}>
                      <h5 className="text-center">Access Denied</h5>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {accessObject?.contactDetailsList === "true" && (
              <div className="mt-4">{loader && <Loader />}</div>
            )}
          </div>
          {accessObject?.contactDetailsList === "true" && (
            <>
              {searchingData.length !== 0 && (
                <Pagination
                  updateShowPerpagevalue={updateShowPerpagevalue}
                  showPrePage={showPrePage}
                  onPaginationChange={onPaginationChange}
                  total={searchingData.length}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
