import React from "react";
import Box from "@mui/material/Box";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { useAppContext } from "../../Lib/ContextLib";
// import { API_URL } from "../../config";
import Loader from "../Loader/Loader";
// import socketIO from "socket.io-client";
import DataServices from "../../services/requestApi";
import { toast } from "react-toastify";

export default function Version() {
  // let socket = socketIO(API_URL, { transports: ["websocket"] });
  const { setShowPage } = useAppContext();
  const [alert, setAlert] = React.useState(null);
  const [tableData, setTableData] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [buttonLoader, setButtonLoader] = React.useState(false);

  React.useEffect(() => {
    getVersion();
  }, []);

  const getVersion = async () => {
    try {
      setLoader(true);
      const res = await DataServices.AllVersionList();
      console.log("version res::: ", res);
      if (res.data.status) {
        setTableData(res?.data.data[0]);
        setLoader(false);
      }
    } catch (error) {
      toast.error("ERROR");
      console.log("ERROR::: ", error);
    }
    // axios(`${API_URL}/api/version/get-version`, {
    //   method: "GET",
    //   credentials: "include",
    // })
    //   .then((response) => {
    //     setLoader(false);
    //     console.log("dataTable.dataRows :::", response.data);
    //     setTableData(response.data.message[0]);
    //   })
    //   .catch((error) => {
    //     console.log("getVersion error :::", error);
    //   });
  };

  const successEdit = (msg) => {
    console.log("done");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title={<h3>{msg}</h3>}
        onConfirm={() => {
          getVersion();
          setAlert(null);
          setShowPage(false);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      ></SweetAlert>
    );
  };

  const LoginSchema = Yup.object().shape({
    playStoreVersion: Yup.string().required("required"),
    testPlayStoreVersion: Yup.string().required("required"),
    playStoreLink: Yup.string().required("required"),
    appStoreLink: Yup.string().required("required"),
    testAppStoreVersion: Yup.string().required("required"),
    appStoreVersion: Yup.string().required("required"),
  });

  return (
    <>
      {alert}
      <div class="content p-3 ">
        <div className="card p-3">
          {!loader && (
            <div>
              {/* android */}
              <Box sx={{ width: "100%" }}>
                <Formik
                  initialValues={{
                    playStoreVersion: tableData.playStoreVersion || "",
                    testPlayStoreVersion: tableData.testPlayStoreVersion || "",
                    playStoreLink: tableData.playStoreLink || "",
                    appStoreLink: tableData.appStoreLink || "",
                    testAppStoreVersion: tableData.testAppStoreVersion || "",
                    appStoreVersion: tableData.appStoreVersion || "",
                  }}
                  validationSchema={LoginSchema}
                  onSubmit={async (values) => {
                    setButtonLoader(true);
                    // UpdateVersion;

                    // let merged = { ...values, id: tableData?._id };
                    console.log("values", values);
                    try {
                      const res = await DataServices.UpdateVersion(
                        tableData?._id,
                        values
                      );
                      if (res.data.status) {
                        successEdit(res.data.message);
                        setButtonLoader(false);
                      } else {
                        setButtonLoader(false);
                      }
                    } catch (error) {
                      console.log("error::: ", error);
                      toast.error("ERROR");
                      setButtonLoader(false);
                    }
                    // axios(`${API_URL}/api/version/update-version`, {
                    //   method: "POST",
                    //   credentials: "include",
                    //   data: merged,
                    // })
                    //   .then((result) => {
                    //     console.log("handleSubmit result :::", result.data);
                    //     setButtonLoader(false);
                    //     console.log(
                    //       "result.data.updateVersionData.appLink::: ",
                    //       result.data.updateVersionData.appLink
                    //     );
                    //     if (result.data.isValid) {
                    //       const data = {
                    //         playStoreVersion:
                    //           result.data.updateVersionData.playStoreVersion,
                    //         testPlayStoreVersion:
                    //           result.data.updateVersionData
                    //             .testPlayStoreVersion,
                    //         playStoreLink:
                    //           result.data.updateVersionData.playStoreLink,
                    //       };
                    //       socket.emit("updateVersion", data);
                    //     }
                    //     successEdit(result.data.message);
                    //   })
                    //   .catch((err) => {
                    //     console.log("handleSubmit err :::", err);
                    //   });
                  }}
                >
                  {({ touched, errors, isSubmitting, values }) => (
                    <div>
                      <Form>
                        <div className="form-group">
                          <label htmlFor="password" className="mt-3">
                            Play Store Version
                          </label>
                          <Field
                            type="text"
                            name="playStoreVersion"
                            placeholder="Play Store Version"
                            className={` form-control
                          ${
                            touched.playStoreVersion && errors.playStoreVersion
                              ? "is-invalid"
                              : ""
                          }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="playStoreVersion"
                            className="invalid-feedback"
                          />
                        </div>
                        {/* <div className="form-group">
                          <label htmlFor="password" className="mt-3">
                            Test Play Store Version
                          </label>
                          <Field
                            type="text"
                            name="testPlayStoreVersion"
                            placeholder="Test Play Store Version"
                            className={` form-control
                          ${
                            touched.testPlayStoreVersion &&
                            errors.testPlayStoreVersion
                              ? "is-invalid"
                              : ""
                          }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="testPlayStoreVersion"
                            className="invalid-feedback"
                          />
                        </div> */}
                        <div className="form-group">
                          <label htmlFor="password" className="">
                            Play Store Link
                          </label>
                          <Field
                            type="text"
                            name="playStoreLink"
                            placeholder="playStoreLink"
                            className={` form-control
                          ${
                            touched.playStoreLink && errors.playStoreLink
                              ? "is-invalid"
                              : ""
                          }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="playStoreLink"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="password" className="">
                            App Link IOS
                          </label>
                          <Field
                            type="text"
                            name="appStoreVersion"
                            placeholder="appStoreVersion"
                            className={` form-control
                          ${
                            touched.appStoreVersion && errors.appStoreVersion
                              ? "is-invalid"
                              : ""
                          }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="appStoreVersion"
                            className="invalid-feedback"
                          />
                        </div>
                        {/* <div className="form-group">
                          <label htmlFor="password" className="mt-3">
                            Test App Store Version
                          </label>
                          <Field
                            type="text"
                            name="testAppStoreVersion"
                            placeholder="Test Play Store Version"
                            className={` form-control
                          ${
                            touched.testAppStoreVersion &&
                            errors.testAppStoreVersion
                              ? "is-invalid"
                              : ""
                          }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="testAppStoreVersion"
                            className="invalid-feedback"
                          />
                        </div> */}
                        <div className="form-group">
                          <label htmlFor="password" className="mt-3">
                            App Store Link
                          </label>
                          <Field
                            type="text"
                            name="appStoreLink"
                            placeholder="App Store Link"
                            className={` form-control
                          ${
                            touched.appStoreLink && errors.appStoreLink
                              ? "is-invalid"
                              : ""
                          }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="appStoreLink"
                            className="invalid-feedback"
                          />
                        </div>
                        <button
                          style={{ width: "100px" }}
                          type="submit"
                          className="btn btn-primary btn-block mt-4 mr-2"
                          disabled={buttonLoader}
                        >
                          <div
                            className="d-flex"
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              className="d-flex"
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <b>Submit</b>
                            </div>
                            {buttonLoader && (
                              <div>
                                <div
                                  class="spinner-border ml-2 mt-1"
                                  role="status"
                                  style={{ height: "20px", width: "20px" }}
                                ></div>
                              </div>
                            )}
                          </div>
                        </button>
                      </Form>
                    </div>
                  )}
                </Formik>
              </Box>
            </div>
          )}
          {loader && <Loader />}
        </div>
      </div>
    </>
  );
}
