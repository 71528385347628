import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// import axios from "axios";
// import { API_URL } from "../../config";
// import socketIO from "socket.io-client";
// import SweetAlert from "react-bootstrap-sweetalert";
import { useAppContext } from "../../Lib/ContextLib";
import DataServices from "../../services/requestApi";
// import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function Home({ rowValue }) {
  const [buttonLoader, setButtonLoader] = React.useState(false);
  const [alert] = React.useState(null);
  // const history = useNavigate();

  const { setShowPage } = useAppContext();

  // let socket = socketIO(API_URL, { transports: ["websocket"] });

  const homeSchema = Yup.object().shape({
    playerStatus: Yup.string().required("required"),
  });

  // const successEditStatus = (msg) => {
  //   console.log("msg :::", msg);
  //   setAlert(
  //     <SweetAlert
  //       success
  //       style={{ display: "block" }}
  //       title={<h4>{msg}</h4>}
  //       onConfirm={() => {
  //         setAlert(null);
  //         setButtonLoader(false);
  //         setShowPage(false);
  //       }}
  //       confirmBtnBsStyle="success"
  //     ></SweetAlert>
  //   );
  // };

  return (
    <div>
      {alert}
      <Formik
        initialValues={{
          TotalBalance:
            rowValue?.diamondBalance +
              rowValue?.goldBalance +
              rowValue?.bronzeBalance || 0,
          diamondBalance: rowValue?.diamondBalance || 0,
          goldBalance: rowValue?.goldBalance || 0,
          bronzeBalance: rowValue?.bronzeBalance || 0,
          upiID: rowValue?.upiID || "-",
          IFSC_Code: rowValue?.bankACIFSCCode || "-",
          Account_number: rowValue?.bankACNo || "-",
          bankACHolderName: rowValue?.bankACHolderName || "-",
          deviceId: rowValue?.deviceId || "-",
          deviceOS: rowValue?.deviceOS || "-",
          deviceName: rowValue?.deviceName || "-",
          deviceModel: rowValue?.deviceModel || "-",
          country: rowValue?.country || "-",
          state: rowValue?.state || "-",
          referCode: rowValue?.refercode || "-",
          email: rowValue?.email || "-",
          playerStatus: rowValue?.playerStatus || "-",
        }}
        validationSchema={homeSchema}
        onSubmit={async (values, { resetForm }) => {
          let merged = {
            status: values.playerStatus,
            diamondBalance: values.diamondBalance,
            goldBalance: values.goldBalance,
            bronzeBalance: values.bronzeBalance,
          };
          console.log("merged :::", merged);
          setButtonLoader(true);
          try {
            const res = await DataServices.ChangePlayerStatus(
              rowValue._id,
              merged
            );
            if (res?.data?.status) {
              setShowPage(false);
              toast.success(res?.data?.message);
            } else {
              toast.error(res?.data?.message);
            }
            setButtonLoader(false);
          } catch (error) {}

          // axios(`${API_URL}/player/update-player-status`, {
          //   method: "POST",
          //   credentials: "include",
          //   data: merged,
          // })
          //   .then((result) => {
          //     console.log("result :::", result.data);
          //     const data = {
          //       playerId: rowValue.playerId,
          //       message: "Change Status",
          //     };
          //     if (result.data.isValid) {
          //       socket.emit("PlayerDetailsUpdate", data);
          //       successEditStatus(result.data.message);
          //       resetForm();
          //     } else {
          //       alert("Error");
          //       setButtonLoader(false);
          //       resetForm();
          //     }
          //   })
          //   .catch((err) => {
          //     setButtonLoader(true);
          //     console.log("handleSubmit err :::", err);
          //   });
        }}
      >
        {(touched, errors) => (
          <div>
            {console.log("rowValue?.deviceOS::: ", rowValue?.deviceOS)}
            <Form>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Field
                  disabled
                  type="text"
                  name="email"
                  autocomplete="off"
                  className={` form-control`}
                />
              </div>
              <div className="form-group mt-2">
                <label htmlFor="email">Total Balance</label>
                <Field
                  disabled
                  type="text"
                  name="TotalBalance"
                  autocomplete="off"
                  className={`mt-2 form-control`}
                />
              </div>
              <div className="form-group mt-2">
                <label htmlFor="email">Diamond Balance</label>
                <Field
                  type="number"
                  name="diamondBalance"
                  autocomplete="off"
                  className={` form-control
                          ${
                            touched.diamondBalance && errors.diamondBalance
                              ? "is-invalid"
                              : ""
                          }`}
                />
                <ErrorMessage
                  component="div"
                  name="diamondBalance"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group mt-2">
                <label htmlFor="email">Gold Balance</label>
                <Field
                  type="number"
                  name="goldBalance"
                  autocomplete="off"
                  className={` form-control
                          ${
                            touched.goldBalance && errors.goldBalance
                              ? "is-invalid"
                              : ""
                          }`}
                />
                <ErrorMessage
                  component="div"
                  name="goldBalance"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group mt-2">
                <label htmlFor="email">Bronze Balance</label>
                <Field
                  type="number"
                  name="bronzeBalance"
                  autocomplete="off"
                  className={` form-control
                          ${
                            touched.bronzeBalance && errors.bronzeBalance
                              ? "is-invalid"
                              : ""
                          }`}
                />
                <ErrorMessage
                  component="div"
                  name="bronzeBalance"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group mt-2">
                <label htmlFor="email">Refer Code</label>
                <Field
                  disabled
                  type="text"
                  name="referCode"
                  autocomplete="off"
                  className={`mt-2 form-control`}
                />
              </div>

              <div className="form-group mt-2">
                <label htmlFor="email">Device Id</label>
                <Field
                  disabled
                  type="text"
                  name="deviceId"
                  autocomplete="off"
                  className={`mt-2 form-control`}
                />
              </div>
              <div className="form-group mt-2">
                <label htmlFor="email">Device Name</label>
                <Field
                  disabled
                  type="text"
                  name="deviceName"
                  autocomplete="off"
                  className={`mt-2 form-control`}
                />
              </div>
              <div className="form-group mt-2">
                <label htmlFor="email">Device OS</label>
                <Field
                  disabled
                  type="text"
                  name="deviceOS"
                  autocomplete="off"
                  className={`mt-2 form-control`}
                />
              </div>
              <div className="form-group mt-2">
                <label htmlFor="email">Device Model</label>
                <Field
                  disabled
                  type="text"
                  name="deviceModel"
                  autocomplete="off"
                  className={`mt-2 form-control`}
                />
              </div>
              <div className="form-group mt-2">
                <label htmlFor="email">Country</label>
                <Field
                  disabled
                  type="text"
                  name="country"
                  autocomplete="off"
                  className={`mt-2 form-control`}
                />
              </div>
              <div className="form-group mt-2">
                <label htmlFor="email">State</label>
                <Field
                  disabled
                  type="text"
                  name="state"
                  autocomplete="off"
                  className={`mt-2 form-control`}
                />
              </div>
              <div className="form-group mt-2">
                <label htmlFor="email">Player Status</label>
                {/* <Field
                  type="text"
                  name="playerStatus"
                  autocomplete="off"
                  className={`mt-2 form-control`}
                /> */}
                <Field
                  as="select"
                  name="playerStatus"
                  className={` form-control
                          ${
                            touched.playerStatus && errors.playerStatus
                              ? "is-invalid"
                              : ""
                          }`}
                >
                  <option value="">Select Status</option>
                  <option value="active">Active</option>
                  <option value="banned">Banned</option>
                </Field>
                <ErrorMessage
                  component="div"
                  name="playerStatus"
                  className="invalid-feedback"
                />
              </div>
              <button
                style={{ width: "100px" }}
                type="submit"
                className="btn btn-primary btn-block mt-4 mr-2"
                disabled={buttonLoader}
              >
                <div
                  className="d-flex"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="d-flex"
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <b>Submit</b>
                  </div>
                  {buttonLoader && (
                    <div>
                      <div
                        class="spinner-border ml-2 mt-1"
                        style={{ height: "20px", width: "20px" }}
                      ></div>
                    </div>
                  )}
                </div>
              </button>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}
