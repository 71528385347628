import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AddIcon from "@mui/icons-material/Add";
// import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import Pagination from "../../Pagination/Pagination";
import { useAppContext } from "../../Lib/ContextLib";
// import { API_URL } from "../../config";
import Loader from "../Loader/Loader";
import moment from "moment";
// import socketIO from "socket.io-client";
import DataServices from "../../services/requestApi";
import { toast } from "react-toastify";

export default function CoinShop() {
  // let socket = socketIO(API_URL, { transports: ["websocket"] });
  const { showPage, setShowPage, accessObject } = useAppContext();
  const [alert, setAlert] = React.useState(null);
  const [tableData, setTableData] = React.useState([]);
  // const [searchSubType] = React.useState();
  const [rowValue, setRowValue] = React.useState();
  const [openValue, setOpenValue] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  const [searchTourName] = React.useState();
  const [buttonLoader, setButtonLoader] = React.useState(false);
  const [showPrePage, setShowPrePage] = React.useState(10);
  const [pagination, setPagination] = React.useState({
    start: 0,
    end: showPrePage,
  });

  React.useEffect(() => {
    getBranch();
  }, []);

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const updateShowPerpagevalue = (value) => {
    setShowPrePage(value);
    setPagination({ start: 0, end: value });
  };

  const LoginSchema = Yup.object().shape({
    amount: Yup.string().required("required"),
    extraAmount: Yup.string().required("required"),
    type: Yup.string().required("required"),
    commonPrice: Yup.string().required("required"),
  });

  const getBranch = async () => {
    try {
      setLoader(true);
      const res = await DataServices.AllCoinShopList();
      if (res.data.status) {
        setTableData(res.data.data);
        setLoader(false);
      }
    } catch (error) {
      toast.error("ERROR");
      console.log("ERROR::: ", error);
    }
  };

  const searchingData = [];
  let data = [];
  console.log("tableData 111 ---", tableData, searchTourName);
  if (searchTourName) {
    console.log("tableData 222 ---", tableData, tableData.length > 0);
    data = tableData.filter((item) => {
      return Object.values(item?.tourName).join("").includes(searchTourName);
    });
    console.log("data 111 ---", data);
    data?.map((dataItem) => searchingData.push(dataItem));
  } else {
    tableData.map((dataItem) => searchingData.push(dataItem));
  }

  const clickEditButton = (item) => {
    setShowPage(true);
    console.log("item edit value :::", item);
    setRowValue(item);
    setOpenValue(2);
  };

  const openAddTournament = (value) => {
    setRowValue();
    console.log("openAddTournament :::", value);
    setShowPage(true);
    setOpenValue(value);
  };

  const updateTournament = async (value) => {
    console.log("updateTournament value :::", value);

    setButtonLoader(true);
    try {
      const res = await DataServices.UpdateCoinShop(rowValue._id, value);
      console.log("res::: ", res);
      if (res.data.status) {
        successEdit(res.data.message);
        setButtonLoader(false);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log("error::: ", error);
      toast.error("ERROR");
      setButtonLoader(false);
    }

    // axios
    //   .post(`${API_URL}/api/spinwheel/update-spinWheel`, data)
    //   .then((res) => {
    //     console.log("update :::", res.data);

    //     if (res.data.isValid) {
    //       setButtonLoader(false);
    //       socket.emit("spinWheelUpdate");
    //       successEdit(res.data.message);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("update err :::", err);
    //     setButtonLoader(false);
    //   });
  };

  const addNewTournament = async (value) => {
    console.log("addNewTournament value :::", value, openValue, rowValue);
    try {
      setButtonLoader(true);
      const res = await DataServices.AddCoinShop(value);
      console.log("res::: ", res);
      if (res?.data.status) {
        successAdd(res?.data.message);
      }
      setButtonLoader(false);
    } catch (error) {
      toast.error("ERROR");
      console.log("error::: ", error);
      setButtonLoader(false);
    }
    // axios(`${API_URL}/api/spinwheel/add-spinWheel`, {
    //   method: "POST",
    //   credentials: "include",
    //   data: value,
    // })
    //   .then((result) => {
    //     console.log("handleSubmit result :::", result.data);
    //     if (result.data.isValid) {
    //       setButtonLoader(false);
    //       socket.emit("spinWheelAdd");

    //       successAdd(result.data.message);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("handleSubmit err :::", err);
    //   });
  };

  const warningWithConfirmMessage = (e) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title="Are you sure?"
        onConfirm={() => {
          deleteData(e);
          //  setIsPlayerEditButtonClicked(false);
        }}
        onCancel={() => setAlert(null)}
        // confirmBtnCssClass={classes.button + " " + classes.danger}
        // cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      ></SweetAlert>
    );
  };

  const successDeleted = (msg) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title={<h4>{msg}</h4>}
        onConfirm={() => {
          getBranch();
          setAlert(null);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      ></SweetAlert>
    );
  };

  const deleteData = async (e) => {
    console.log("e===>", e._id);
    try {
      const res = await DataServices.DeleteCoinShop(e._id);
      console.log("Delete res::: ", res);
      if (res.data.status) {
        successDeleted(res.data.message);
      }
    } catch (error) {}
    // const data = { id: e._id };
    // axios
    //   .post(`${API_URL}/api/spinwheel/delete-spinWheel`, data)
    //   .then((res) => {
    //     console.log(res);
    //     console.log(res.data);
    //     if (res.data.isValid) {
    //       socket.emit("spinWheelDelete");
    //       successDeleted(res.data.message);
    //     }
    //     getBranch();
    //   });
  };

  const successAdd = (msg) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title={<h4>{msg}</h4>}
        onConfirm={() => {
          getBranch();
          setAlert(null);
          setShowPage(false);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      ></SweetAlert>
    );
  };

  const successEdit = (msg) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title={<h4>{msg}</h4>}
        onConfirm={() => {
          getBranch();
          setAlert(null);
          setShowPage(false);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      ></SweetAlert>
    );
  };

  return (
    <>
      {alert}
      {showPage ? (
        <div class="content p-3 ">
          <div className="card p-3">
            <Box sx={{ width: "100%" }}>
              <Formik
                initialValues={{
                  amount: rowValue ? rowValue?.amount : "",
                  extraAmount: rowValue ? rowValue?.extraAmount : "",
                  type: rowValue ? rowValue?.type : "",
                  commonPrice: rowValue ? rowValue?.commonPrice : "",
                  offerID: rowValue ? rowValue?.offerID : "",
                  active: rowValue ? rowValue?.active.toString() : "",
                }}
                validationSchema={LoginSchema}
                onSubmit={(values) => {
                  let toggleValue;
                  if (typeof values.active === "boolean") {
                    toggleValue = values.active;
                  } else {
                    toggleValue = values.active.toLowerCase() === "true";
                  }

                  const data = {
                    amount: values.amount,
                    extraAmount: values.extraAmount,
                    type: parseInt(values.type),
                    commonPrice: values.commonPrice,
                    offerID: values.offerID,
                    active: toggleValue,
                  };
                  console.log("data::: ", data);
                  setButtonLoader(true);
                  if (parseInt(openValue) === 1) {
                    addNewTournament(data);
                  }
                  if (parseInt(openValue) === 2) {
                    updateTournament(data);
                  }
                }}
              >
                {({ touched, errors, isSubmitting, values, setFieldValue }) => (
                  <div>
                    <Form>
                      <div className="form-group">
                        <label htmlFor="password">Amount</label>
                        <Field
                          type="number"
                          name="amount"
                          placeholder="Amount"
                          className={`mt-2 form-control
                          ${
                            touched.amount && errors.amount ? "is-invalid" : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="amount"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">Extra Amount</label>
                        <Field
                          type="number"
                          name="extraAmount"
                          placeholder="Extra Amount"
                          className={`mt-2 form-control
                          ${
                            touched.extraAmount && errors.extraAmount
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="extraAmount"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">Offer ID</label>
                        <Field
                          type="text"
                          name="offerID"
                          placeholder="offerID"
                          className={`mt-2 form-control
                          ${
                            touched.offerID && errors.offerID
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="offerID"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group mt-2">
                        <label htmlFor="password">Select Type</label>
                        <Field
                          as="select"
                          name="type"
                          className={`mt-2 form-control
                          ${touched.type && errors.type ? "is-invalid" : ""}`}
                        >
                          <option value="">Select type...</option>
                          <option value={0}>Android</option>
                          <option value={1}>IOS</option>
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="type"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">Common Price</label>
                        <Field
                          type="number"
                          name="commonPrice"
                          placeholder="Common Price"
                          className={`mt-2 form-control
                          ${
                            touched.commonPrice && errors.commonPrice
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="commonPrice"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group mt-2">
                        <label htmlFor="password">Active</label>
                        <Field
                          as="select"
                          name="active"
                          className={`mt-2 form-control
                          ${touched.type && errors.type ? "is-invalid" : ""}`}
                        >
                          <option value="">Select...</option>
                          <option value={true}>On</option>
                          <option value={false}>Off</option>
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="type"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="d-flex">
                        <button
                          style={{ width: "100px" }}
                          type="submit"
                          className="btn btn-primary btn-block mt-4 mr-2"
                          disabled={buttonLoader}
                        >
                          <div
                            className="d-flex"
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              className="d-flex"
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <b>Submit</b>
                            </div>
                            {buttonLoader && (
                              <div>
                                <div
                                  class="spinner-border ml-2 mt-1"
                                  role="status"
                                  style={{ height: "20px", width: "20px" }}
                                ></div>
                              </div>
                            )}
                          </div>
                        </button>
                        <button
                          style={{ width: "100px" }}
                          onClick={() => setShowPage(false)}
                          type="button"
                          className="btn btn-secondary btn-block mt-4 ms-2"
                        >
                          Close
                        </button>
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </Box>
          </div>
        </div>
      ) : (
        <div className="content p-3">
          <div class="card " style={{ overflow: "scroll" }}>
            <div
              class="pl-4 pr-4 pt-4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {accessObject?.spinWheelAdd === "true" && (
                <button
                  style={{ width: "130px" }}
                  onClick={() => {
                    openAddTournament(1);
                  }}
                  type="button"
                  className="btn btn-primary btn-block "
                >
                  <AddIcon />
                  Add Coin
                </button>
              )}

              <h3 class="card-title">
                <b>Coin Shop</b>
              </h3>
              <div>
                {/* <input
                  class="input-simple"
                  type="text"
                  placeholder="name"
                  value={searchSubType}
                  onChange={(e) => setSearchTourName(e.target.value)}
                /> */}
              </div>
            </div>

            <div class="card-body">
              <table id="" class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>SrNo</th>
                    <th>Date</th>
                    <th>Offer ID</th>
                    <th>Amount</th>
                    <th>Extra Amount</th>

                    <th>Type</th>

                    <th>Common Price</th>
                    <th>Active</th>
                    {(accessObject?.spinWheelEdit === "true" ||
                      accessObject?.spinWheelDelete === "true") && (
                      <th style={{ textAlign: "center" }}>Action</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {accessObject?.getSpinWheelList === "true" ? (
                    <>
                      {!loader ? (
                        <>
                          {searchingData.length > 0 ? (
                            <>
                              {searchingData
                                .reverse()
                                ?.slice(pagination.start, pagination.end)
                                .map((item, index) => (
                                  <>
                                    <tr key={index}>
                                      <td>{index + 1 + pagination.start}</td>
                                      <td>
                                        {moment(item.createdAt).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </td>
                                      <td>{item.offerID}</td>
                                      <td>{item.amount}</td>
                                      <td>{item.extraAmount}</td>

                                      <td>{item.type}</td>
                                      <td>{item.commonPrice}</td>
                                      <td>
                                        {item.active === true ? "On" : "Off"}
                                      </td>
                                      <td className="d-flex justify-content-evenly ">
                                        {accessObject?.spinWheelEdit ===
                                          "true" && (
                                          <EditIcon
                                            className="mr-3 courser"
                                            onClick={() =>
                                              clickEditButton(item)
                                            }
                                          />
                                        )}
                                        {accessObject?.spinWheelDelete ===
                                          "true" && (
                                          <>
                                            <ClearIcon
                                              className="courser text-danger"
                                              onClick={() =>
                                                warningWithConfirmMessage(item)
                                              }
                                            />
                                          </>
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                ))}
                            </>
                          ) : (
                            <>
                              <tr className="text-center">
                                <td colSpan={10}>
                                  <h5>No Data Available</h5>
                                </td>
                              </tr>
                            </>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={14} className="p-3">
                        <h5 className="text-center mb-0">Access Denied</h5>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {accessObject?.getSpinWheelList === "true" && (
                <div className="mt-4">{loader && <Loader />}</div>
              )}
            </div>
            {searchingData.length !== 0 && (
              <Pagination
                updateShowPerpagevalue={updateShowPerpagevalue}
                showPrePage={showPrePage}
                onPaginationChange={onPaginationChange}
                total={searchingData.length}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
