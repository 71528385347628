import React from "react";
import Box from "@mui/material/Box";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import Pagination from "../../Pagination/Pagination";
import { useAppContext } from "../../Lib/ContextLib";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
// import Loader from "../Loader/Loader";
import CloseIcon from "@mui/icons-material/Close";
import FileUpload from "react-drag-n-drop-image";
import { API_URL } from "../../config";
import Select from "react-select";
import DataServices from "../../services/requestApi";
import { toast } from "react-toastify";

function CustomBody() {
  return (
    <div
      className="drag-file-area mt-0"
      style={{
        border: "0px",
        borderRadius: "0px",
        width: "100%",
        position: "relative",
      }}
    >
      <div>
        <CloudDownloadIcon style={{ fontSize: "50px" }} />
      </div>
      <h3 className="dynamic-message" style={{ fontFamily: "Montserrat" }}>
        Drag &amp; drop any file here
      </h3>
      <label className="">
        <span className="">
          <span
            className="browse-files-text"
            style={{
              fontFamily: "Montserrat",
              color: "#539ffe",
            }}
          >
            browse file
          </span>
          <span style={{ fontFamily: "Montserrat" }}>from device</span>
        </span>
      </label>
    </div>
  );
}

function ShowData({ files, onRemoveImage }) {
  return (
    <div className="">
      {files.map((item) => {
        return (
          <>
            <div
              className="drag-file-area mt-0"
              style={{
                border: "0px",
                borderRadius: "0px",
                position: "relative",
              }}
            >
              <div className="d-flex" style={{ justifyContent: "center" }}>
                <div style={{ height: "200px", width: "200px" }} className="">
                  <img
                    src={item.preview}
                    alt="preview..."
                    height="100%"
                    width="100%"
                    style={{ objectFit: "contain" }}
                  />
                </div>
                <CloseIcon
                  style={{
                    cursor: "pointer",
                    color: "red",
                    position: "absolute",
                    right: 10,
                    top: 10,
                  }}
                  // onClick={() => {
                  //   setDragImage2(true);
                  //   setImage2(null);
                  // }}
                  onClick={() => onRemoveImage(item.id)}
                />
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
}

export default function SendNotification() {
  const { showPage, setShowPage, accessObject } = useAppContext();
  const [alert, setAlert] = React.useState(null);
  const [tableData, setTableData] = React.useState([]);
  const [rowValue, setRowValue] = React.useState();
  const [openValue, setOpenValue] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  const [showPrePage, setShowPrePage] = React.useState(10);
  const [imageFile, setImageFile] = React.useState();
  const [buttonLoader, setbuttonLoader] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [showImages, setShowData] = React.useState(false);
  const [isEditDataImage1, setIsEditDataImage1] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    start: 0,
    end: showPrePage,
  });
  const [optionList, setOptionList] = React.useState([]);
  const [multiSelections, setMultiSelections] = React.useState([]);
  console.log("multiSelections::: ", multiSelections);

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const updateShowPerpagevalue = (value) => {
    setShowPrePage(value);
    setPagination({ start: 0, end: value });
  };

  const LoginSchema = Yup.object().shape({
    title: Yup.string().required("required"),
    body: Yup.string().required("required"),
    // fileData: Yup.string().required("required"),
  });

  React.useEffect(() => {
    getNotificationList();
    getPlayerList();
  }, []);

  const getNotificationList = () => {
    setLoader(true);
    axios(`${API_URL}/api/firebase/get-firebase-notification`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        setLoader(false);
        console.log("dataTable.dataRows :::", response.data);
        setTableData(response.data.message);
      })
      .catch((error) => {
        console.log("getNotificationList error :::", error);
      });
  };

  const getPlayerList = async () => {
    // setLoader(true);
    try {
      const res = await DataServices.AllPlayerList();
      console.log("All Player data::: ", res);
      // setLoader(false);

      const optionList = res.data.data.map((item) => {
        return { value: item.email, label: item.email };
      });
      console.log("optionList::: ", optionList);
      setOptionList(optionList);
    } catch (error) {
      toast.error("Error");
    }
  };

  const IsEditOnRemoveImage = () => {
    setShowData(false);
    console.log("isEditOnRemove::: ");
    //  setFiles((prev) => prev.filter((i) => i.id !== id));
    setFiles([]);
    setIsEditDataImage1(false);
  };

  const onRemoveImage = (id) => {
    setShowData(false);
    setFiles((prev) => prev.filter((i) => i.id !== id));
  };

  const onError = (error) => {
    console.error(error);
  };

  const onChange = (e) => {
    if (files.length === 0) {
      console.log(e[0].file.name, "true");
      setShowData(true);
      console.log("e[0].file::: ", e[0].file);
      setImageFile(e[0].file);
      setFiles(e);
    }
  };

  const openAddTournament = (value) => {
    console.log("imageFile :::", imageFile);
    setImageFile();
    setIsEditDataImage1(false);
    setRowValue();
    console.log("openAddTournament :::", value);
    setShowPage(true);
    setOpenValue(value);
  };

  const addNewNotificcation = (value) => {
    console.log("imageFile ---", imageFile, value);

    // change image file name
    const file = imageFile;
    let originalFileName = imageFile.name.split(".");
    let fileName =
      new Date() + "." + originalFileName[originalFileName.length - 1];
    const fileWithNewName = new File([file], fileName, { type: file.type });

    const formData = new FormData();
    formData.append("image", fileWithNewName);
    // end change image file name

    axios(`${API_URL}/api/firebase/upload-image`, {
      method: "POST",
      credentials: "include",
      data: formData,
    })
      .then((result) => {
        console.log("handleSubmit result :::", result.data);
        if (result.data.isValid) {
          let merged = { ...value, imageName: fileName };
          console.log("merged value :::", merged);
          axios(`${API_URL}/api/firebase/send-notification-firebase`, {
            method: "POST",
            credentials: "include",
            data: merged,
          })
            .then((result) => {
              console.log("handleSubmit result :::", result.data.message);
              if (result.data.isValid) {
                setbuttonLoader(false);
                successAdd(result.data.message);
              } else {
                warningAlert();
                setbuttonLoader(false);
              }
            })
            .catch((err) => {
              console.log("handleSubmit err :::", err);
              warningAlert();
            });
        }
      })
      .catch((err) => {
        console.log("handleSubmit err :::", err);
      });
  };

  const successAdd = (msg) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title={msg}
        onConfirm={() => {
          getNotificationList();
          setAlert(null);
          setShowPage(false);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      ></SweetAlert>
    );
  };

  const warningAlert = () => {
    console.log("donw");
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title="Something Went Wrong .....!"
        onConfirm={() => {
          //  getNotificationList();
          setAlert(null);
          //  setShowPage(false);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      ></SweetAlert>
    );
  };

  return (
    <div>
      {alert}
      {showPage ? (
        <div class="content p-3 ">
          <div className="card p-3">
            <Box sx={{ width: "100%" }}>
              <Formik
                initialValues={{
                  title: rowValue ? rowValue?.title : "",
                  body: rowValue ? rowValue?.body : "",
                }}
                validationSchema={LoginSchema}
                onSubmit={(values) => {
                  setbuttonLoader(true);
                  console.log(values);

                  if (parseInt(openValue) === 1) {
                    addNewNotificcation(values);
                  }
                }}
              >
                {({ touched, errors, isSubmitting, values }) => (
                  <div>
                    <Form>
                      <div className="form-group mt-2">
                        <label htmlFor="password">Title</label>
                        <Field
                          type="text"
                          name="title"
                          placeholder="Enter Title Value..."
                          className={`mt-2 form-control
                          ${touched.title && errors.title ? "is-invalid" : ""}`}
                        />
                        <ErrorMessage
                          component="div"
                          name="title"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password" className="mt-3">
                          Body
                        </label>
                        <Field
                          type="text"
                          name="body"
                          placeholder="Enter Body Value..."
                          className={`mt-2 form-control
                          ${touched.body && errors.body ? "is-invalid" : ""}`}
                        />
                        <ErrorMessage
                          component="div"
                          name="body"
                          className="invalid-feedback"
                        />
                      </div>
                      {isEditDataImage1 ? (
                        <div className="file-upload-box">
                          <div
                            className="drag-file-area mt-0"
                            style={{
                              border: "0px",
                              borderRadius: "0px",
                              position: "relative",
                            }}
                          >
                            <div
                              className="d-flex"
                              style={{ justifyContent: "center" }}
                            >
                              <div
                                style={{ height: "200px", width: "200px" }}
                                className=""
                              >
                                <img
                                  src={rowValue?.imageUrl}
                                  alt="preview..."
                                  height="100%"
                                  width="100%"
                                  style={{ objectFit: "contain" }}
                                />
                              </div>
                              <CloseIcon
                                style={{
                                  cursor: "pointer",
                                  color: "red",
                                  position: "absolute",
                                  right: 10,
                                  top: 10,
                                }}
                                onClick={() => IsEditOnRemoveImage()}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="file-upload-box">
                          {showImages ? (
                            <ShowData
                              files={files}
                              onRemoveImage={onRemoveImage}
                            />
                          ) : (
                            <FileUpload
                              onError={onError}
                              body={<CustomBody />}
                              overlap={false}
                              fileValue={files}
                              onChange={(e) => onChange(e)}
                            />
                          )}
                        </div>
                      )}
                      <div className="form-group mt-3">
                        <label htmlFor="password" className="mt-3">
                          Select User
                        </label>
                        <Select
                          options={optionList}
                          isMulti
                          onChange={setMultiSelections}
                          classNamePrefix="select"
                          selected={multiSelections}
                        />
                      </div>

                      <button
                        style={{ width: "100px" }}
                        type="submit"
                        className="btn btn-primary btn-block mt-4 mr-2"
                        disabled={buttonLoader}
                      >
                        <div
                          className="d-flex"
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            className="d-flex"
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <b>Submit</b>
                          </div>
                          {buttonLoader && (
                            <div>
                              <div
                                class="spinner-border ml-2 mt-1"
                                role="status"
                                style={{ height: "20px", width: "20px" }}
                              ></div>
                            </div>
                          )}
                        </div>
                      </button>
                    </Form>
                  </div>
                )}
              </Formik>
            </Box>
          </div>
        </div>
      ) : (
        <div className="content p-3">
          <div class="card ">
            <div className="row pl-4 pr-4 pt-4">
              <div className="col-5">
                {accessObject?.notificationAdd === "true" && (
                  <button
                    style={{ width: "118px" }}
                    onClick={() => {
                      openAddTournament(1);
                    }}
                    type="button"
                    className="btn btn-primary btn-block "
                  >
                    <AddIcon />
                    Add New
                  </button>
                )}
              </div>
              <div className="col-7">
                <h3 class="card-title">
                  <b>Notification List</b>
                </h3>
              </div>
            </div>

            <div class="card-body" style={{ overflow: "scroll" }}>
              <table id="" class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>SrNo</th>
                    <th>Title</th>
                    <th>Body</th>
                    <th>Image Url</th>
                  </tr>
                </thead>
                {accessObject?.getNotificationList === "true" ? (
                  <>
                    <tbody>
                      {!loader ? (
                        <>
                          {tableData.length > 0 ? (
                            <>
                              {tableData
                                ?.slice(pagination.start, pagination.end)
                                .map((item, index) => (
                                  <>
                                    <tr key={index}>
                                      <td>{index + 1 + pagination.start}</td>
                                      <td>{item.title}</td>
                                      <td>{item.body}</td>
                                      <td>{item.imageUrl}</td>
                                    </tr>
                                  </>
                                ))}
                            </>
                          ) : (
                            <>
                              <tr
                                style={{ backgroundColor: "whitesmoke" }}
                                className="text-center"
                              >
                                <td colSpan={6}>
                                  <h5>No Data Available</h5>
                                </td>
                              </tr>
                            </>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      <tr
                        style={{ backgroundColor: "whitesmoke" }}
                        className="text-center"
                      >
                        <td colSpan={6}>
                          <h5>No Data Available</h5>
                        </td>
                      </tr>
                    </tbody>
                  </>
                ) : (
                  <>
                    <tr
                      style={{ backgroundColor: "rgba(0,0,0,.05)" }}
                      className="text-center"
                    >
                      <td colSpan={6}>
                        <h5>Access Denied</h5>
                      </td>
                    </tr>
                  </>
                )}
              </table>
              {/* {accessObject?.getNotificationList == "true" && (
                <div className="mt-4">{loader && <Loader />}</div>
              )} */}
            </div>
            {accessObject?.getNotificationList === "true" &&
              tableData.length !== 0 && (
                <Pagination
                  updateShowPerpagevalue={updateShowPerpagevalue}
                  showPrePage={showPrePage}
                  onPaginationChange={onPaginationChange}
                  total={tableData.length}
                />
              )}
          </div>
        </div>
      )}
    </div>
  );
}
