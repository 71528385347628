import React from "react";
import { useNavigate } from "react-router-dom";
// import ReportIcon from "@mui/icons-material/Report";
import SettingsInputSvideoIcon from "@mui/icons-material/SettingsInputSvideo";
import Logo from "../src/img/Web_Swift_logo.png";
import { useAppContext } from "./Lib/ContextLib";
// import total_income_icon from "./icon/total_income_icon.svg";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import PersonIcon from "@mui/icons-material/Person";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import PaymentIcon from "@mui/icons-material/Payment";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

export default function SideBar() {
  const history = useNavigate();

  const {
    showPage,
    setShowPage,
    active1,
    active2,
    // active3,
    active4,
    active6,
    // active7,
    active8,
    active9,
    setActive1,
    setActive2,
    setActive3,
    setActive4,
    setActive5,
    setActive6,
    setActive7,
    setActive8,
    setActive9,
    setAddNewPlayer,
    // accessObject,
  } = useAppContext();

  return (
    <div>
      <aside
        className="main-sidebar elevation-4"
        style={{ backgroundColor: "#0d0028", position: "fixed" }}
      >
        <a
          href="/dashboard"
          className="brand-link "
          style={{
            display: "flex",
            justifyContent: "center",
            height: "70px",
          }}
        >
          <img
            src={Logo}
            alt="Logo"
            style={{ opacity: "0.8", width: "70%", height: "50px" }}
          />
          {/* <h1 style={{ color: "white" }}>
            <span style={{ color: "#ffab3a" }}>
              <b>C</b>
            </span>
            asino
          </h1> */}
        </a>
        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* dashboard */}
              {/* {accessObject?.dashboardCount == "true" && (
                <div className="nav-item ">
                  <a
                    className={`nav-link ${
                      window.location.pathname.split("/")[1] == "dashboard" &&
                      "active"
                    }`}
                    id="dashboard"
                    onClick={() => {
                      history("/dashboard");
                      setActive1(true);
                      setActive2(true);
                      setActive3(true);
                      setActive4(true);
                      setActive5(true);
                      setActive9(true);
                      setActive6(true);
                      setActive7(true);
                      setActive8(true);
                    }}
                  >
                    <i
                      className="nav-icon fas fa-tachometer-alt mr-2"
                      style={{ color: "white" }}
                    />
                    <p style={{ color: "white" }}>Dashboard</p>
                  </a>
                </div>
              )} */}
              <div className="nav-item mt-4">
                <p
                  className={`nav-link ${
                    window.location.pathname.split("/")[1] === "dashboard" &&
                    "active"
                  }`}
                  id="dashboard"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history("/dashboard");
                    setActive1(true);
                    setActive2(true);
                    setActive3(true);
                    setActive4(true);
                    setActive5(true);
                    setActive9(true);
                    setActive6(true);
                    setActive7(true);
                    setActive8(true);
                  }}
                >
                  <DashboardIcon className="mr-2" style={{ color: "white" }} />
                  <p style={{ color: "white" }}>Dashboard</p>
                </p>
              </div>
              {/* Admin */}
              {/* {(accessObject?.getAdminList == "true" ||
                accessObject?.adminAccessUser == "true") && (
                <li className="nav-item mt-2">
                  <p
                    className="nav-link"
                    onClick={() => {
                      setActive1(true);
                      setActive2(true);
                      setActive3(true);
                      setActive4(true);
                      setActive5(true);
                      setActive9(true);
                      setActive6(true);
                      setActive7(true);
                      console.log("active8::: ", active8);
                      setActive8(!active8);
                    }}
                  >
                    <i
                      className="nav-icon fas fa-user  mr-2"
                      style={{ color: "white" }}
                    />
                    <p style={{ color: "white" }}>
                      Admin
                      <i
                        className={` ${
                          active8
                            ? "right fas fa-angle-left "
                            : "right fas fa-angle-down"
                        }`}
                      />
                    </p>
                  </p>
                  <ul className={`nav ${active8 ? "nav-treeview" : ""}`}>
                    {console.log(
                      'active8 ? "nav-treeview" : ""::: ',
                      active8 ? "nav-treeview" : ""
                    )}
                    {accessObject?.getAdminList == "true" && (
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            window.location.pathname.split("/")[1] ==
                              "admin-list" && "active"
                          } d-flex`}
                          onClick={() => {
                            history("/admin-list");

                            setShowPage(false);
                          }}
                        >
                          <p
                            className="mt-1 "
                            style={{ marginLeft: "34px", color: "white" }}
                          >
                            Admin List
                          </p>
                        </a>
                      </li>
                    )}
                    {accessObject?.adminAccessUser == "true" && (
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            window.location.pathname.split("/")[1] ==
                              "access-user" && "active"
                          } d-flex`}
                          onClick={() => {
                            history("/access-user");

                            setShowPage(false);
                          }}
                        >
                          <p
                            className="mt-1 "
                            style={{ marginLeft: "34px", color: "white" }}
                          >
                            Access User
                          </p>
                        </a>
                      </li>
                    )}
                  </ul>
                </li>
              )} */}

              <li className="nav-item mt-2">
                <p
                  className="nav-link"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setActive1(true);
                    setActive2(true);
                    setActive3(true);
                    setActive4(true);
                    setActive5(true);
                    setActive9(true);
                    setActive6(true);
                    setActive7(true);
                    console.log("active8::: ", active8);
                    setActive8(!active8);
                  }}
                >
                  <div>
                    <SupervisorAccountIcon
                      className=" mr-2"
                      style={{ color: "white" }}
                    />
                    <p
                      style={{
                        color: "white",
                      }}
                    >
                      Admin
                    </p>
                  </div>
                  {active8 ? (
                    <NavigateNextRoundedIcon style={{ color: "white" }} />
                  ) : (
                    <ExpandMoreRoundedIcon style={{ color: "white" }} />
                  )}
                </p>
                <ul className={`nav ${active8 ? "nav-treeview" : ""}`}>
                  <li className="nav-item">
                    <p
                      className={`nav-link ${
                        window.location.pathname.split("/")[1] ===
                          "admin-list" && "active"
                      } d-flex`}
                      onClick={() => {
                        history("/admin-list");
                        setShowPage(false);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <p
                        className="mt-1 "
                        style={{ marginLeft: "34px", color: "white" }}
                      >
                        Admin List
                      </p>
                    </p>
                  </li>

                  {/* <li className="nav-item">
                    <a
                      className={`nav-link ${
                        window.location.pathname.split("/")[1] ==
                          "access-user" && "active"
                      } d-flex`}
                      onClick={() => {
                        history("/access-user");

                        setShowPage(false);
                      }}
                    >
                      <p
                        className="mt-1 "
                        style={{ marginLeft: "34px", color: "white" }}
                      >
                        Access User
                      </p>
                    </a>
                  </li> */}
                </ul>
              </li>

              {/* player */}
              {/* {(accessObject?.getPlayerList == "true" ||
                accessObject?.getPlayerBannedList == "true" ||
                accessObject?.getLivePlayerAppList == "true" ||
                accessObject?.getLivePlayerGameList == "true") && (
                <li className="nav-item mt-2">
                  <a
                    className="nav-link"
                    onClick={() => {
                      setActive1(!active1);
                      setActive2(true);
                      setActive3(true);
                      setActive4(true);
                      setActive5(true);
                      setActive9(true);
                      setActive6(true);
                      setActive7(true);
                      setActive8(true);
                    }}
                  >
                    <i
                      className="nav-icon fa fa-user-circle mr-2"
                      style={{ color: "white" }}
                    />
                    <p style={{ color: "white" }}>
                      Player
                      <i
                        className={` ${
                          active1
                            ? "right fas fa-angle-left "
                            : "right fas fa-angle-down"
                        }`}
                      />
                    </p>
                  </a>
                  <ul className={`nav ${active1 && "nav-treeview"}`}>
                    {accessObject?.getPlayerList == "true" && (
                      <li className="nav-item">
                        <p
                          className={`nav-link ${
                            window.location.pathname.split("/")[1] ==
                              "playerlist" && "active"
                          } d-flex`}
                          onClick={() => {
                            history("/playerlist", { state: { data: "All" } });
                            setShowPage(false);
                            setAddNewPlayer(false);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <p
                            className="mt-1 "
                            style={{ marginLeft: "34px", color: "white" }}
                          >
                            Player List
                          </p>
                        </p>
                      </li>
                    )}
                    {accessObject?.getPlayerBannedList == "true" && (
                      <li className="nav-item">
                        <p
                          className={`nav-link ${
                            window.location.pathname.split("/")[1] ==
                              "playerbanned" && "active"
                          } d-flex`}
                          onClick={() => {
                            history("/playerbanned");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <p
                            className="mt-1 "
                            style={{ marginLeft: "34px", color: "white" }}
                          >
                            Player Banned
                          </p>
                        </p>
                      </li>
                    )}
                  </ul>
                </li>
              )} */}

              <li className="nav-item mt-2">
                <p
                  className="nav-link"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setActive1(!active1);
                    setActive2(true);
                    setActive3(true);
                    setActive4(true);
                    setActive5(true);
                    setActive9(true);
                    setActive6(true);
                    setActive7(true);
                    setActive8(true);
                  }}
                >
                  <div>
                    <PersonIcon className="mr-2" style={{ color: "white" }} />

                    <p
                      style={{
                        color: "white",
                      }}
                    >
                      Player
                    </p>
                  </div>
                  {active1 ? (
                    <NavigateNextRoundedIcon style={{ color: "white" }} />
                  ) : (
                    <ExpandMoreRoundedIcon style={{ color: "white" }} />
                  )}
                </p>
                <ul className={`nav ${active1 && "nav-treeview"}`}>
                  <li className="nav-item">
                    <p
                      className={`nav-link ${
                        window.location.pathname.split("/")[1] ===
                          "playerlist" && "active"
                      } d-flex`}
                      onClick={() => {
                        history("/playerlist", { state: { data: "All" } });
                        setShowPage(false);
                        setAddNewPlayer(false);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <p
                        className="mt-1 "
                        style={{ marginLeft: "34px", color: "white" }}
                      >
                        Player List
                      </p>
                    </p>
                  </li>

                  <li className="nav-item">
                    <p
                      className={`nav-link ${
                        window.location.pathname.split("/")[1] ===
                          "guest-user" && "active"
                      } d-flex`}
                      onClick={() => {
                        history("/guest-user");
                        setShowPage(false);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <p
                        className="mt-1 "
                        style={{ marginLeft: "34px", color: "white" }}
                      >
                        Guest User
                      </p>
                    </p>
                  </li>

                  <li className="nav-item">
                    <p
                      className={`nav-link ${
                        window.location.pathname.split("/")[1] ===
                          "playerbanned" && "active"
                      } d-flex`}
                      onClick={() => {
                        history("/playerbanned");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <p
                        className="mt-1 "
                        style={{ marginLeft: "34px", color: "white" }}
                      >
                        Player Banned
                      </p>
                    </p>
                  </li>
                </ul>
              </li>

              {/* Game */}
              <li className="nav-item mt-2">
                <p
                  className="nav-link"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setActive1(true);
                    setActive2(!active2);
                    setActive3(true);
                    setActive4(true);
                    setActive5(true);
                    setActive9(true);
                    setActive6(true);
                    setActive7(true);
                    setActive8(true);
                  }}
                >
                  <div>
                    <SportsEsportsIcon
                      className="mr-2"
                      style={{ color: "white" }}
                    />
                    <p
                      style={{
                        color: "white",
                      }}
                    >
                      Game
                    </p>
                  </div>
                  {active2 ? (
                    <NavigateNextRoundedIcon style={{ color: "white" }} />
                  ) : (
                    <ExpandMoreRoundedIcon style={{ color: "white" }} />
                  )}
                </p>
                <ul className={`nav ${active2 && "nav-treeview"}`}>
                  <li className="nav-item">
                    <p
                      className={`nav-link ${
                        window.location.pathname.split("/")[1] ===
                          "game-list" && "active"
                      } d-flex`}
                      onClick={() => {
                        history("/game-list");
                        setShowPage(false);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <p
                        className="mt-1 "
                        style={{ marginLeft: "34px", color: "white" }}
                      >
                        Game List
                      </p>
                    </p>
                  </li>
                </ul>
              </li>

              {/* control */}
              {/* {(accessObject?.getNotificationList == "true" ||
                accessObject?.getNotificationListSeparate == "true" ||
                accessObject?.getVersionListAndroid == "true" ||
                accessObject?.getVersionListIos == "true" ||
                accessObject?.getBannerList == "true") && (
                <li className="nav-item mt-2">
                  <a
                    className="nav-link"
                    onClick={() => {
                      setActive1(true);
                      setActive2(true);
                      setActive3(true);
                      setActive4(true);
                      setActive5(true);
                      setActive9(true);
                      setActive6(!active6);
                      setActive7(true);
                      setActive8(true);
                    }}
                  >
                    <SettingsInputSvideoIcon
                      className="mr-2"
                      style={{ color: "white" }}
                    />
                    <p style={{ color: "white" }}>
                      Controls
                      <i
                        className={` ${
                          active6
                            ? "right fas fa-angle-left "
                            : "right fas fa-angle-down"
                        }`}
                      />
                    </p>
                  </a>
                  <ul className={`nav ${active6 && "nav-treeview"}`}>
                    {accessObject?.getNotificationList == "true" && (
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            window.location.pathname.split("/")[1] ==
                              "notification" && "active"
                          } d-flex`}
                          onClick={() => {
                            history("/notification");
                            setShowPage(false);
                          }}
                        >
                          <p
                            className="mt-1 "
                            style={{ marginLeft: "34px", color: "white" }}
                          >
                            Notification
                          </p>
                        </a>
                      </li>
                    )}
                    {accessObject?.getNotificationListSeparate == "true" && (
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            window.location.pathname.split("/")[1] ==
                              "separate-notification" && "active"
                          } d-flex`}
                          onClick={() => {
                            history("/separate-notification");

                            setShowPage(false);
                          }}
                        >
                          <p
                            className="mt-1 "
                            style={{ marginLeft: "34px", color: "white" }}
                          >
                            Separate Notification
                          </p>
                        </a>
                      </li>
                    )}
                    {(accessObject?.getVersionListAndroid == "true" ||
                      accessObject?.getVersionListIos == "true") && (
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            window.location.pathname.split("/")[1] ==
                              "version" && "active"
                          } d-flex`}
                          onClick={() => {
                            history("/version");

                            setShowPage(false);
                          }}
                        >
                          <p
                            className="mt-1 "
                            style={{ marginLeft: "34px", color: "white" }}
                          >
                            Version
                          </p>
                        </a>
                      </li>
                    )}
                    {accessObject?.getRestrictedArea == "true" && (
                      <li className="nav-item">
                        <p
                          className={`nav-link ${
                            window.location.pathname.split("/")[1] ==
                              "restrictedarea" && "active"
                          } d-flex`}
                          onClick={() => {
                            history("/restrictedarea");
                            setShowPage(false);
                            console.log("showPage::: ", showPage);
                          }}
                        >
                          <p
                            className="mt-1 "
                            style={{ marginLeft: "34px", color: "white" }}
                          >
                            Restricted Area
                          </p>
                        </p>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              */}
              <li className="nav-item mt-2">
                <p
                  className="nav-link"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setActive1(true);
                    setActive2(true);
                    setActive3(true);
                    setActive4(true);
                    setActive5(true);
                    setActive9(true);
                    setActive6(!active6);
                    setActive7(true);
                    setActive8(true);
                  }}
                >
                  <div>
                    <SettingsInputSvideoIcon
                      className="mr-2"
                      style={{ color: "white" }}
                    />
                    <p
                      style={{
                        color: "white",
                      }}
                    >
                      Controls
                    </p>
                  </div>
                  {active2 ? (
                    <NavigateNextRoundedIcon style={{ color: "white" }} />
                  ) : (
                    <ExpandMoreRoundedIcon style={{ color: "white" }} />
                  )}
                </p>
                <ul className={`nav ${active6 && "nav-treeview"}`}>
                  <li className="nav-item">
                    <p
                      className={`nav-link ${
                        window.location.pathname.split("/")[1] ===
                          "notification" && "active"
                      } d-flex`}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        history("/notification");
                        setShowPage(false);
                      }}
                    >
                      <p
                        className="mt-1 "
                        style={{ marginLeft: "34px", color: "white" }}
                      >
                        Notification
                      </p>
                    </p>
                  </li>
                  {/* <li className="nav-item">
                    <a
                      className={`nav-link ${
                        window.location.pathname.split("/")[1] ==
                          "separate-notification" && "active"
                      } d-flex`}
                      onClick={() => {
                        history("/separate-notification");

                        setShowPage(false);
                      }}
                    >
                      <p
                        className="mt-1 "
                        style={{ marginLeft: "34px", color: "white" }}
                      >
                        Separate Notification
                      </p>
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <p
                      className={`nav-link ${
                        window.location.pathname.split("/")[1] === "version" &&
                        "active"
                      } d-flex`}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        history("/version");

                        setShowPage(false);
                      }}
                    >
                      <p
                        className="mt-1 "
                        style={{ marginLeft: "34px", color: "white" }}
                      >
                        Version
                      </p>
                    </p>
                  </li>
                  <li className="nav-item">
                    <p
                      className={`nav-link ${
                        window.location.pathname.split("/")[1] ===
                          "restrictedarea" && "active"
                      } d-flex`}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        history("/restrictedarea");
                        setShowPage(false);
                        console.log("showPage::: ", showPage);
                      }}
                    >
                      <p
                        className="mt-1 "
                        style={{ marginLeft: "34px", color: "white" }}
                      >
                        Restricted Area
                      </p>
                    </p>
                  </li>
                  <li className="nav-item">
                    <p
                      className={`nav-link ${
                        window.location.pathname.split("/")[1] ===
                          "spinWheel" && "active"
                      } d-flex`}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        history("/spinWheel");
                        setShowPage(false);
                      }}
                    >
                      <p
                        className="mt-1 "
                        style={{ marginLeft: "34px", color: "white" }}
                      >
                        Spin Wheel
                      </p>
                    </p>
                  </li>
                  <li className="nav-item">
                    <p
                      className={`nav-link ${
                        window.location.pathname.split("/")[1] ===
                          "coin-shop" && "active"
                      } d-flex`}
                      onClick={() => {
                        history("/coin-shop");
                        setShowPage(false);
                        console.log("showPage::: ", showPage);
                      }}
                    >
                      <p
                        className="mt-1 "
                        style={{ marginLeft: "34px", color: "white" }}
                      >
                        Coin Shop
                      </p>
                    </p>
                  </li>
                </ul>
              </li>

              {/* Payment */}

              <li className="nav-item mt-2">
                <p
                  className="nav-link"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setActive4(!active4);
                    setActive1(true);
                    setActive2(true);
                    setActive3(true);
                    setActive5(true);
                    setActive9(true);
                    setActive6(true);
                    setActive7(true);
                    setActive8(true);
                  }}
                >
                  <div>
                    <PaymentIcon className=" mr-2" style={{ color: "white" }} />
                    <p
                      style={{
                        color: "white",
                      }}
                    >
                      Payments
                    </p>
                  </div>
                  {active2 ? (
                    <NavigateNextRoundedIcon style={{ color: "white" }} />
                  ) : (
                    <ExpandMoreRoundedIcon style={{ color: "white" }} />
                  )}
                </p>
                <ul className={`nav ${active4 && "nav-treeview"}`}>
                  <li className="nav-item">
                    <p
                      className={`nav-link ${
                        window.location.pathname.split("/")[1] === "history" &&
                        "active"
                      } d-flex`}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        history("/history");
                      }}
                    >
                      <p
                        className="mt-1 "
                        style={{ marginLeft: "34px", color: "white" }}
                      >
                        History
                      </p>
                    </p>
                  </li>

                  <li className="nav-item">
                    <p
                      className={`nav-link ${
                        window.location.pathname.split("/")[1] === "addcash" &&
                        "active"
                      } d-flex`}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        history("/addcash", { state: { data: "All" } });
                        setShowPage(false);
                      }}
                    >
                      <p
                        className="mt-1 "
                        style={{ marginLeft: "34px", color: "white" }}
                      >
                        Add Coin
                      </p>
                    </p>
                  </li>

                  {/* <li className="nav-item">
                    <p
                      className={`nav-link ${
                        window.location.pathname.split("/")[1] == "payout" &&
                        "active"
                      } d-flex`}
                      onClick={() => {
                        history("/payout", { state: { data: "All" } });
                        setShowPage(false);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <p
                        className="mt-1 "
                        style={{ marginLeft: "34px", color: "white" }}
                      >
                        Payout
                      </p>
                    </p>
                  </li> */}

                  {/* <li className="nav-item">
                    <a
                      className={`nav-link ${
                        window.location.pathname.split("/")[1] == "bet" &&
                        "active"
                      } d-flex`}
                      onClick={() => {
                        history("/bet");
                      }}
                    >
                      <p
                        className="mt-1 "
                        style={{ marginLeft: "34px", color: "white" }}
                      >
                        Bet
                      </p>
                    </a>
                  </li> */}

                  {/* <li className="nav-item">
                    <a
                      className={`nav-link ${
                        window.location.pathname.split("/")[1] == "win" &&
                        "active"
                      } d-flex`}
                      onClick={() => {
                        history("/win");
                      }}
                    >
                      <p
                        className="mt-1 "
                        style={{ marginLeft: "34px", color: "white" }}
                      >
                        Win
                      </p>
                    </a>
                  </li> */}
                </ul>
              </li>

              {/* income */}
              {/* {accessObject?.getIncomeList == "true" && (
                <li className="nav-item mt-2">
                  <a
                    className="nav-link"
                    onClick={() => {
                      setActive3(!active3);
                      setActive1(true);
                      setActive2(true);
                      setActive4(true);
                      setActive5(true);
                      setActive9(true);
                      setActive6(true);
                      setActive7(true);
                      setActive8(true);
                    }}
                  >
                    <img src={total_income_icon} className="nav-icon  mr-2" />

                    <p style={{ color: "white" }}>
                      Income
                      <i
                        className={` ${
                          active3
                            ? "right fas fa-angle-left "
                            : "right fas fa-angle-down"
                        }`}
                      />
                    </p>
                  </a>
                  <ul className={`nav ${active3 && "nav-treeview"}`}>
                    {accessObject?.getIncomeList == "true" && (
                      <li className="nav-item">
                        <p
                          className={`nav-link ${
                            window.location.pathname.split("/")[1] ==
                              "income-details" && "active"
                          } d-flex`}
                          onClick={() => {
                            history("/income-details");
                            setShowPage(false);
                          }}
                        >
                          <p
                            className="mt-1 "
                            style={{ marginLeft: "34px", color: "white" }}
                          >
                            Income Details
                          </p>
                        </p>
                      </li>
                    )}
                  </ul>
                </li>
              )} */}

              {/* <li className="nav-item mt-2">
                <a
                  className="nav-link"
                  onClick={() => {
                    setActive3(!active3);
                    setActive1(true);
                    setActive2(true);
                    setActive4(true);
                    setActive5(true);
                    setActive9(true);
                    setActive6(true);
                    setActive7(true);
                    setActive8(true);
                  }}
                >
                  <img src={total_income_icon} className="nav-icon  mr-2" />

                  <p style={{ color: "white" }}>
                    Income
                    <i
                      className={` ${
                        active3
                          ? "right fas fa-angle-left "
                          : "right fas fa-angle-down"
                      }`}
                    />
                  </p>
                </a>
                <ul className={`nav ${active3 && "nav-treeview"}`}>
                  <li className="nav-item">
                    <p
                      className={`nav-link ${
                        window.location.pathname.split("/")[1] ==
                          "income-details" && "active"
                      } d-flex`}
                      onClick={() => {
                        history("/income-details");
                        setShowPage(false);
                      }}
                    >
                      <p
                        className="mt-1 "
                        style={{ marginLeft: "34px", color: "white" }}
                      >
                        Income Details
                      </p>
                    </p>
                  </li>
                </ul>
              </li> */}

              {/* game setting */}
              {/* {(accessObject?.getSetting == "true" ||
                accessObject?.getSocial == "true") && (
               
              )} */}
              <li className="nav-item mt-2 ">
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  className="nav-link "
                  onClick={() => {
                    setActive5(true);
                    setActive1(true);
                    setActive2(true);
                    setActive3(true);
                    setActive4(true);
                    setActive6(true);
                    setActive7(true);
                    setActive8(true);
                    setActive9(!active9);
                  }}
                >
                  <div>
                    <SettingsRoundedIcon
                      className="mr-2"
                      style={{ color: "white" }}
                    />
                    <p style={{ color: "white" }}>Game Setting</p>
                  </div>
                  {active9 ? (
                    <NavigateNextRoundedIcon style={{ color: "white" }} />
                  ) : (
                    <ExpandMoreRoundedIcon style={{ color: "white" }} />
                  )}
                </p>
                <ul className={`nav ${active9 && "nav-treeview"}`}>
                  <li className="nav-item">
                    <p
                      className={`nav-link ${
                        window.location.pathname.split("/")[1] ===
                          "game-setting" && "active"
                      } d-flex`}
                      onClick={() => {
                        history("/game-setting");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <p
                        className="mt-1 "
                        style={{ marginLeft: "34px", color: "white" }}
                      >
                        Setting
                      </p>
                    </p>
                  </li>

                  <li className="nav-item">
                    <p
                      className={`nav-link ${
                        window.location.pathname.split("/")[1] === "social" &&
                        "active"
                      } d-flex`}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        history("/social");
                        setShowPage(false);
                      }}
                    >
                      <p
                        className="mt-1 "
                        style={{ marginLeft: "34px", color: "white" }}
                      >
                        Social
                      </p>
                    </p>
                  </li>
                </ul>
              </li>
              <div className="nav-item mt-2">
                <p
                  className={`nav-link ${
                    window.location.pathname.split("/")[1] ===
                      "game-analytics" && "active"
                  }`}
                  id="game-analytics"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history("/game-analytics");
                    setActive1(true);
                    setActive2(true);
                    setActive3(true);
                    setActive4(true);
                    setActive5(true);
                    setActive9(true);
                    setActive6(true);
                    setActive7(true);
                    setActive8(true);
                  }}
                >
                  <EqualizerIcon className="mr-2" style={{ color: "white" }} />
                  <p style={{ color: "white" }}>Game Analytics</p>
                </p>
              </div>
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
}
