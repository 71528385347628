import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import Pagination from "../../Pagination/Pagination";
import { useAppContext } from "../../Lib/ContextLib";
import { API_URL } from "../../config";
import Loader from "../Loader/Loader";
import moment from "moment";
import socketIO from "socket.io-client";

export default function SpinWheel() {
  let socket = socketIO(API_URL, { transports: ["websocket"] });
  const { showPage, setShowPage, accessObject } = useAppContext();
  const [alert, setAlert] = React.useState(null);
  const [tableData, setTableData] = React.useState([]);
  // const [searchSubType] = React.useState();
  const [rowValue, setRowValue] = React.useState();
  const [openValue, setOpenValue] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  const [searchTourName] = React.useState();
  const [buttonLoader, setButtonLoader] = React.useState(false);
  const [showPrePage] = React.useState(10);
  const [pagination, setPagination] = React.useState({
    start: 0,
    end: showPrePage,
  });

  React.useEffect(() => {
    getBranch();
  }, []);

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const LoginSchema = Yup.object().shape({
    coin: Yup.number().required("required"),
    bonus: Yup.number().required("required"),
    amount: Yup.number().required("required"),
    status: Yup.string().required("required"),
    name: Yup.string().required("required"),
  });

  const getBranch = () => {
    setLoader(true);
    axios(`${API_URL}/api/coin-package/get-coin-package`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        setLoader(false);
        console.log("dataTable.dataRows :::", response.data);
        setTableData(response.data.message);
      })
      .catch((error) => {
        console.log("getBranch error :::", error);
      });
  };

  const searchingData = [];
  let data = [];
  console.log("tableData 111 ---", tableData, searchTourName);
  if (searchTourName) {
    console.log("tableData 222 ---", tableData, tableData.length > 0);
    data = tableData.filter((item) => {
      return Object.values(item?.email).join("").includes(searchTourName);
    });
    console.log("data 111 ---", data);
    data?.map((dataItem) => searchingData.push(dataItem));
  } else {
    tableData.map((dataItem) => searchingData.push(dataItem));
  }

  const clickEditButton = (item) => {
    setShowPage(true);
    console.log("item edit value :::", item);
    setRowValue(item);
    setOpenValue(2);
  };

  const openAddTournament = (value) => {
    setRowValue();
    console.log("openAddTournament :::", value);
    setShowPage(true);
    setOpenValue(value);
  };

  const updateTournament = (value) => {
    console.log("updateTournament value :::", value);
    const data = { ...value, id: rowValue._id };
    console.log("data :::", data);
    axios
      .post(`${API_URL}/api/coin-package/update-coin-package`, data)
      .then((res) => {
        console.log("update :::", res.data);

        if (res.data.isValid) {
          setButtonLoader(false);
          socket.emit("coinPackageUpdate");
          successEdit(res.data.message);
        }
      })
      .catch((err) => {
        console.log("update err :::", err);
        setButtonLoader(false);
      });
  };

  const addNewTournament = (value) => {
    console.log("addNewTournament value :::", value, openValue, rowValue);
    axios(`${API_URL}/api/coin-package/add-coin-package`, {
      method: "POST",
      credentials: "include",
      data: value,
    })
      .then((result) => {
        console.log("handleSubmit result :::", result.data);
        if (result.data.isValid) {
          setButtonLoader(false);
          socket.emit("coinPackageAdd");
          successAdd(result.data.message);
        }
      })
      .catch((err) => {
        console.log("handleSubmit err :::", err);
      });
  };

  const warningWithConfirmMessage = (e) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title="Are you sure?"
        onConfirm={() => {
          deleteData(e);
          //  setIsPlayerEditButtonClicked(false);
        }}
        onCancel={() => setAlert(null)}
        // confirmBtnCssClass={classes.button + " " + classes.danger}
        // cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      ></SweetAlert>
    );
  };

  const successDeleted = (msg) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title={<h4>{msg}</h4>}
        onConfirm={() => {
          getBranch();
          setAlert(null);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      ></SweetAlert>
    );
  };

  const deleteData = (e) => {
    console.log("e===>", e._id);
    const data = { id: e._id };
    axios
      .post(`${API_URL}/api/coin-package/delete-coin-package`, data)
      .then((res) => {
        console.log(res);
        console.log(res.data);
        if (res.data.isValid) {
          successDeleted(res.data.message);
          socket.emit("coinPackageDelete");
        }
        getBranch();
      });
  };

  const successAdd = (msg) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title={<h4>{msg}</h4>}
        onConfirm={() => {
          getBranch();
          setAlert(null);
          setShowPage(false);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      ></SweetAlert>
    );
  };

  const successEdit = (msg) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title={<h4>{msg}</h4>}
        onConfirm={() => {
          getBranch();
          setAlert(null);
          setShowPage(false);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      ></SweetAlert>
    );
  };

  return (
    <>
      {alert}
      {showPage ? (
        <div class="content p-3 ">
          <div className="card p-3">
            <Box sx={{ width: "100%" }}>
              <Formik
                initialValues={{
                  name: rowValue ? rowValue?.name : "",
                  coin: rowValue ? rowValue?.coin : "",
                  bonus: rowValue ? rowValue?.bonus : "",
                  amount: rowValue ? rowValue?.amount : "",
                  status: rowValue ? rowValue?.status : "",
                }}
                validationSchema={LoginSchema}
                onSubmit={(values) => {
                  setButtonLoader(true);
                  if (parseInt(openValue) === 1) {
                    addNewTournament(values);
                  }
                  if (parseInt(openValue) === 2) {
                    updateTournament(values);
                  }
                }}
              >
                {({ touched, errors, isSubmitting, values, setFieldValue }) => (
                  <div>
                    <Form>
                      <div className="form-group">
                        <label htmlFor="password">Name</label>
                        <Field
                          type="text"
                          name="name"
                          placeholder="name"
                          className={`mt-2 form-control
                          ${touched.name && errors.name ? "is-invalid" : ""}`}
                        />
                        <ErrorMessage
                          component="div"
                          name="name"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">Coin</label>
                        <Field
                          type="number"
                          name="coin"
                          placeholder="coin"
                          className={`mt-2 form-control
                          ${touched.coin && errors.coin ? "is-invalid" : ""}`}
                        />
                        <ErrorMessage
                          component="div"
                          name="coin"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">Bonus</label>
                        <Field
                          type="number"
                          name="bonus"
                          placeholder="bonus"
                          className={`mt-2 form-control
                          ${touched.bonus && errors.bonus ? "is-invalid" : ""}`}
                        />
                        <ErrorMessage
                          component="div"
                          name="bonus"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">Amount</label>
                        <Field
                          type="number"
                          name="amount"
                          placeholder="amount"
                          className={`mt-2 form-control
                          ${
                            touched.amount && errors.amount ? "is-invalid" : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="amount"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">Status</label>
                        <Field
                          as="select"
                          name="status"
                          className={`mt-2 form-control
                          ${
                            touched.status && errors.status ? "is-invalid" : ""
                          }`}
                        >
                          <option value="">select ...</option>
                          <option value="on">ON</option>
                          <option value="off">OFF</option>
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="status"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="d-flex">
                        <button
                          style={{ width: "100px" }}
                          type="submit"
                          className="btn btn-primary btn-block mt-4 mr-2"
                          disabled={buttonLoader}
                        >
                          <div
                            className="d-flex"
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              className="d-flex"
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <b>Submit</b>
                            </div>
                            {buttonLoader && (
                              <div>
                                <div
                                  class="spinner-border ml-2 mt-1"
                                  role="status"
                                  style={{ height: "20px", width: "20px" }}
                                ></div>
                              </div>
                            )}
                          </div>
                        </button>
                        <button
                          style={{ width: "100px" }}
                          onClick={() => setShowPage(false)}
                          type="button"
                          className="btn btn-secondary btn-block mt-4 ms-2"
                        >
                          Close
                        </button>
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </Box>
          </div>
        </div>
      ) : (
        <div className="content p-3">
          <div class="card " style={{ overflow: "scroll" }}>
            <div
              class="pl-4 pr-4 pt-4"
              style={{ display: "flex", justifyContent: "" }}
            >
              {accessObject?.coinPackageAdd === "true" && (
                <>
                  <button
                    style={{ width: "118px" }}
                    onClick={() => {
                      openAddTournament(1);
                    }}
                    type="button"
                    className="btn btn-primary btn-block "
                  >
                    <AddIcon />
                    Add New
                  </button>
                </>
              )}

              {/* <div>
                <input
                  class="input-simple"
                  type="text"
                  placeholder="Search Email..."
                  value={searchSubType}
                  onChange={(e) => setSearchTourName(e.target.value)}
                />
              </div> */}
            </div>

            <div class="card-body">
              <table id="" class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>SrNo</th>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Coin</th>
                    <th>Bonus</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {accessObject?.getCoinPackageList === "true" ? (
                    <>
                      {!loader ? (
                        <>
                          {searchingData.length > 0 ? (
                            <>
                              {searchingData
                                .reverse()
                                ?.slice(pagination.start, pagination.end)
                                .map((item, index) => (
                                  <>
                                    <tr key={index}>
                                      <td>{index + 1 + pagination.start}</td>
                                      <td>
                                        {moment(item.createdAt).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </td>
                                      <td>{item.name}</td>
                                      <td>{item.coin}</td>
                                      <td>{item.bonus}</td>
                                      <td>{item.amount}</td>
                                      <td>{item.status}</td>
                                      <td className="d-flex justify-content-evenly ">
                                        <EditIcon
                                          className="mr-3 courser"
                                          onClick={() => clickEditButton(item)}
                                        />
                                        <ClearIcon
                                          className="courser text-danger"
                                          onClick={() =>
                                            warningWithConfirmMessage(item)
                                          }
                                        />
                                      </td>
                                    </tr>
                                  </>
                                ))}
                            </>
                          ) : (
                            <>
                              <tr className="text-center">
                                <td colSpan={8}>
                                  <h5>No Data Available</h5>
                                </td>
                              </tr>
                            </>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td colSpan={14} className="p-3">
                          <h5 className="text-center mb-0">Access Denied</h5>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
              {accessObject?.getCoinPackageList === "true" && (
                <div className="mt-4">{loader && <Loader />}</div>
              )}
            </div>
            {searchingData.length !== 0 && (
              <Pagination
                showPrePage={showPrePage}
                onPaginationChange={onPaginationChange}
                total={searchingData.length}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
