import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AddIcon from "@mui/icons-material/Add";
// import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import Pagination from "../../Pagination/Pagination";
import { useAppContext } from "../../Lib/ContextLib";
// import { API_URL } from "../../config";
import Loader from "../Loader/Loader";
import moment from "moment";
// import socketIO from "socket.io-client";
import DataServices from "../../services/requestApi";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import FileUpload from "react-drag-n-drop-image";

function CustomBody() {
  return (
    <div
      className="drag-file-area mt-0"
      style={{
        border: "0px",
        borderRadius: "0px",
        width: "100%",
        position: "relative",
      }}
    >
      <div>
        <CloudDownloadIcon style={{ fontSize: "50px" }} />
      </div>
      <h3 className="dynamic-message" style={{ fontFamily: "Montserrat" }}>
        Drag &amp; drop any file here
      </h3>
      <label className="">
        <span className="">
          <span
            className="browse-files-text"
            style={{
              fontFamily: "Montserrat",
              color: "#539ffe",
            }}
          >
            browse file
          </span>
          <span style={{ fontFamily: "Montserrat" }}>from device</span>
        </span>
      </label>
    </div>
  );
}

function ShowData({ files, onRemoveImage }) {
  return (
    <div className="">
      {files.map((item) => {
        return (
          <>
            <div
              className="drag-file-area mt-0"
              style={{
                border: "0px",
                borderRadius: "0px",
                position: "relative",
              }}
            >
              <div className="d-flex" style={{ justifyContent: "center" }}>
                <div style={{ height: "200px", width: "200px" }}>
                  <img
                    src={item.preview}
                    alt="preview"
                    height="100%"
                    width="100%"
                    style={{ objectFit: "contain" }}
                  />
                </div>
                <CloseIcon
                  style={{
                    cursor: "pointer",
                    color: "red",
                    position: "absolute",
                    right: 10,
                    top: 10,
                  }}
                  // onClick={() => {
                  //   setDragImage2(true);
                  //   setImage2(null);
                  // }}
                  onClick={() => onRemoveImage(item.id)}
                />
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
}

export default function BlockBlast() {
  const { showPage, setShowPage } = useAppContext();
  const [alert, setAlert] = React.useState(null);
  const [tableData, setTableData] = React.useState([]);
  const [rowValue, setRowValue] = React.useState();
  const [openValue, setOpenValue] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  const [buttonLoader, setbuttonLoader] = React.useState(false);
  const [showPrePage, setShowPrePage] = React.useState(10);
  const [isEditDataImage1, setIsEditDataImage1] = React.useState(false);
  const [showImages, setShowData] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [imageFile, setImageFile] = React.useState();
  const [checkImage, setCheckImage] = React.useState(false);
  const [filteredData, setFilteredData] = React.useState([]);
  const [status, setStatus] = React.useState(false);

  const [pagination, setPagination] = React.useState({
    start: 0,
    end: showPrePage,
  });
  //   let socket = socketIO(API_URL, { transports: ["websocket"] });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const IsEditOnRemoveImage = () => {
    setShowData(false);
    console.log("isEditOnRemove::: ");
    //  setFiles((prev) => prev.filter((i) => i.id !== id));
    setFiles([]);
    setIsEditDataImage1(false);
  };
  const onRemoveImage = (id) => {
    setShowData(false);
    setFiles((prev) => prev.filter((i) => i.id !== id));
  };
  const onError = (error) => {
    console.error("error", error);
  };
  const onChange = (e) => {
    if (files.length === 0) {
      console.log(e[0].file.name, "true");
      setShowData(true);
      console.log("e[0].file::: ", e[0].file);
      setImageFile(e[0].file);
      setFiles(e);
    }
  };

  const LoginSchema = Yup.object().shape({
    companyName: Yup.string().required("required"),
    // gameId: Yup.string().required("required"),
    gameIdDev: Yup.string().required("required"),
    gameUrl: Yup.string().required("required"),
    name: Yup.string().required("required"),
    type: Yup.string().required("required"),
  });

  React.useEffect(() => {
    getBranch();
  }, []);

  const getBranch = async () => {
    setLoader(true);
    try {
      const res = await DataServices.AllGameList();
      console.log("data::: ", res);
      setLoader(false);
      setTableData(res?.data?.data);
      setFilteredData(res?.data?.data);
    } catch (error) {
      toast.error("Error");
    }
  };

  const clickEditButton = (item) => {
    setShowPage(true);
    setIsEditDataImage1(true);
    console.log("item edit value :::", item);
    setRowValue(item);
    setOpenValue(2);

    if (item.imageUrl) {
      setIsEditDataImage1(true);
      setImageFile(item.imageUrl);
      setCheckImage(true);
    } else {
      setIsEditDataImage1(false);
      setImageFile("");
      setCheckImage(false);
    }
  };

  const openAddTournament = (value) => {
    setRowValue();
    setImageFile();
    setIsEditDataImage1(false);
    console.log("openAddTournament :::", value);
    setShowPage(true);
    setOpenValue(value);
  };

  const updateShowPerpagevalue = (value) => {
    setShowPrePage(value);
    setPagination({ start: 0, end: value });
  };

  const updateTournament = async (value) => {
    const margeData = { ...value, id: rowValue._id };
    setbuttonLoader(true);

    console.log("imageFile checkImage====>", checkImage);
    if (!checkImage) {
      console.log("imageFile::: ", true);
      try {
        setbuttonLoader(true);
        const res = await DataServices.UpdateGame(rowValue._id, margeData);
        console.log("res::: ", res);
        if (res?.data.status) {
          successEdit();
        }
        setbuttonLoader(false);
      } catch (error) {
        console.log("error::: ", error);
        toast.error("ERROR");
        setbuttonLoader(false);
      }
    } else {
      console.log("imageFile::: ", false);

      try {
        const res = await DataServices.UpdateGame(rowValue._id, margeData);
        console.log("res::: ", res);
        if (res?.data.status) {
          try {
            let img1FormData = new FormData();
            img1FormData.append("image", imageFile);
            const response = await DataServices.UploadGame(
              rowValue._id,
              img1FormData
            );
            if (response?.data.status) {
              successEdit();
            }
            successEdit();
          } catch (error) {
            setbuttonLoader(false);
          }
        }

        setbuttonLoader(false);
      } catch (error) {
        console.log("error::: ", error);
        toast.error("ERROR");
        setbuttonLoader(false);
      }
    }
  };

  const addNewTournament = async (value) => {
    value.status = String(value.status).toLowerCase() === "true";
    const merge = { ...value, imageUrl: imageFile };
    console.log("value::: ", merge);
    setbuttonLoader(true);
    try {
      const res = await DataServices.AddNewGame(value);
      console.log("res::: ", res);
      if (res?.data.status) {
        try {
          let img1FormData = new FormData();
          img1FormData.append("image", imageFile);
          console.log("res?.data::: ", res?.data, imageFile);
          const response = await DataServices.UploadGame(
            res?.data.data._id,
            img1FormData
          );
          setIsEditDataImage1(false);
          console.log("response=====>", response);
          if (response?.data.status) {
            successAdd();
          } else {
            toast.error(response?.data.message);
          }
          setbuttonLoader(false);
        } catch (error) {
          toast.error("ERROR");
          console.log("error::: ", error);
          setbuttonLoader(false);
        }
      }
    } catch (error) {
      toast.error("ERROR Image");
      setbuttonLoader(false);
    }

    // console.log("addNewTournament value :::", value, openValue, rowValue);
    // axios(`${API_URL}/api/tournament/add-blockblast-tournament`, {
    //   method: "POST",
    //   credentials: "include",
    //   data: value,
    // })
    //   .then((result) => {
    //     const data = {
    //       message: "blockblast",
    //     };
    //     if (result.data.isValid) {
    //       //   socket.emit("tournamentAdd", data);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("handleSubmit err :::", err);
    //   });
  };

  const warningWithConfirmMessage = (e) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title="Are you sure?"
        onConfirm={() => {
          deleteData(e);
          //  setIsPlayerEditButtonClicked(false);
        }}
        onCancel={() => setAlert(null)}
        // confirmBtnCssClass={classes.button + " " + classes.danger}
        // cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      ></SweetAlert>
    );
  };

  const successDeleted = () => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title="Deleted!"
        onConfirm={() => {
          getBranch();
          setAlert(null);
          // const data = {
          //   message: "blockblast",
          // };
          //   socket.emit("tournamentDelete", data);
        }}
        confirmBtnBsStyle="success"
      >
        Your Player Record has been deleted
      </SweetAlert>
    );
  };

  const deleteData = async (e) => {
    console.log("e===>", e._id);
    try {
      const res = await DataServices.DeleteGame(e._id);
      console.log("Delete res::: ", res);
      if (res.data.status) {
        successDeleted();
      }
    } catch (error) {}
    // axios
    //   .post(`${API_URL}/api/tournament/delete-blockblast-tournament`, {
    //     id: e._id,
    //   })
    //   .then((res) => {
    //     console.log(res);
    //     console.log(res.data);
    //     if (res.data.isValid) {
    //       successDeleted();
    //     }
    //   });
  };

  const successAdd = () => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title="Added successfully!"
        onConfirm={() => {
          getBranch();
          setAlert(null);
          setShowPage(false);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      >
        Your Player Record has been Added ...
      </SweetAlert>
    );
  };

  const successEdit = () => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title="Edited successfully!"
        onConfirm={() => {
          getBranch();
          setAlert(null);
          setShowPage(false);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      >
        Your Player Record has been Edited ...
      </SweetAlert>
    );
  };

  const handleFilter = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    const filtered = tableData?.filter((item) => {
      return item?.name.toLowerCase().includes(searchQuery);
    });
    setFilteredData(filtered);
  };

  const onHandelToggle = async (checked, id) => {
    setbuttonLoader(true);
    setStatus(checked);

    try {
      const res = await DataServices.UpdateGame(id, { status: checked });
      console.log("res::: ", res);
      if (res?.data.status) {
        successEdit();
      }
      setbuttonLoader(false);
    } catch (error) {
      console.log("error::: ", error);
      toast.error("ERROR");
      setbuttonLoader(false);
    }
  };

  return (
    <>
      {alert}
      {showPage ? (
        <div class="content p-3 ">
          <div className="card p-3">
            <Box sx={{ width: "100%" }}>
              <Formik
                initialValues={{
                  companyName: rowValue ? rowValue?.companyName : "",
                  // gameId: rowValue ? rowValue?.gameId : "",
                  gameIdDev: rowValue ? rowValue?.gameIdDev : "",
                  gameUrl: rowValue ? rowValue?.gameUrl : "",
                  name: rowValue ? rowValue?.name : "",
                  type: rowValue ? rowValue?.type : "",
                  status: rowValue ? rowValue?.status : "",
                }}
                validationSchema={LoginSchema}
                onSubmit={(values) => {
                  const data = {
                    companyName: values.companyName,
                    // gameId: values.gameId,
                    gameIdDev: values.gameIdDev,
                    gameUrl: values.gameUrl,
                    name: values.name,
                    type: parseInt(values.type),
                    status: status,
                  };
                  if (parseInt(openValue) === 1) {
                    addNewTournament(data);
                  }
                  if (parseInt(openValue) === 2) {
                    updateTournament(data);
                  }
                }}
              >
                {({ touched, errors, isSubmitting, values, setFieldValue }) => (
                  <div>
                    <Form>
                      <div className="form-group">
                        <label htmlFor="password">Company Name</label>
                        <Field
                          type="text"
                          name="companyName"
                          placeholder="Company Name"
                          className={`mt-2 form-control
                          ${
                            touched.companyName && errors.companyName
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="companyName"
                          className="invalid-feedback"
                        />
                      </div>
                      {/* <div className="form-group">
                        <label htmlFor="password">Game Id</label>
                        <Field
                          type="text"
                          name="gameId"
                          placeholder="Game Id"
                          className={`mt-2 form-control
                          ${
                            touched.gameId && errors.gameId ? "is-invalid" : ""
                          }`}
                          disabled
                        />
                        <ErrorMessage
                          component="div"
                          name="gameId"
                          className="invalid-feedback"
                        />
                      </div> */}
                      <div className="form-group">
                        <label htmlFor="password">Game Id Dev</label>
                        <Field
                          type="text"
                          name="gameIdDev"
                          placeholder="Game Id Dev"
                          className={`mt-2 form-control
                          ${
                            touched.gameIdDev && errors.gameIdDev
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="gameIdDev"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">Game Url</label>
                        <Field
                          type="text"
                          name="gameUrl"
                          placeholder="Game Url"
                          className={`mt-2 form-control
                          ${
                            touched.gameUrl && errors.gameUrl
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="gameUrl"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">Name</label>
                        <Field
                          type="text"
                          name="name"
                          placeholder="name"
                          className={`mt-2 form-control
                          ${touched.name && errors.name ? "is-invalid" : ""}`}
                        />
                        <ErrorMessage
                          component="div"
                          name="name"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">Type</label>
                        <Field
                          as="select"
                          name="type"
                          className={`mt-2 form-control
                          ${
                            touched.status && errors.status ? "is-invalid" : ""
                          }`}
                        >
                          <option value="">Select Type...</option>
                          <option value={0}>None</option>
                          <option value={1}>Hot</option>
                          <option value={2}>New</option>
                          <option value={3}>Features</option>
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="status"
                          className="invalid-feedback"
                        />
                      </div>
                      {/* <div className="row">
                        <div className="col-lg-2 col-md-2 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="email" className="mt-3">
                              Status
                            </label>
                            <br />
                            <label class="switch mt-3">
                              <input
                                type="checkbox"
                                name="status"
                                onChange={(e) => {
                                  setStatus(e.target.checked);
                                }}
                                defaultChecked={rowValue?.status}
                              />
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div> */}
                      <label htmlFor="password">Images</label>
                      {isEditDataImage1 ? (
                        <div
                          className="file-upload-box"
                          style={{ width: "25%" }}
                        >
                          <div
                            className="drag-file-area mt-0"
                            style={{
                              border: "0px",
                              borderRadius: "0px",
                              position: "relative",
                            }}
                          >
                            <div
                              className="d-flex"
                              style={{ justifyContent: "center" }}
                            >
                              <div
                                style={{ height: "200px", width: "200px" }}
                                className=""
                              >
                                <img
                                  src={rowValue?.imageUrl}
                                  alt="preview..."
                                  height="100%"
                                  width="100%"
                                  style={{ objectFit: "contain" }}
                                />
                              </div>
                              <CloseIcon
                                style={{
                                  cursor: "pointer",
                                  color: "red",
                                  position: "absolute",
                                  right: 10,
                                  top: 10,
                                }}
                                onClick={() => IsEditOnRemoveImage()}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="file-upload-box w-25">
                          {showImages ? (
                            <ShowData
                              files={files}
                              onRemoveImage={onRemoveImage}
                            />
                          ) : (
                            <FileUpload
                              onError={onError}
                              type={["png", "jpeg", "gif", "jpg"]}
                              body={<CustomBody />}
                              overlap={false}
                              fileValue={files}
                              onChange={(e) => onChange(e)}
                            />
                          )}
                        </div>
                      )}
                      <div className="d-flex">
                        <button
                          style={{ width: "100px" }}
                          type="submit"
                          className="btn btn-primary btn-block mt-4 mr-2"
                          disabled={buttonLoader}
                        >
                          <div
                            className="d-flex"
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              className="d-flex"
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <b>Submit</b>
                            </div>
                            {buttonLoader && (
                              <div>
                                <div
                                  class="spinner-border ml-2 mt-1"
                                  role="status"
                                  style={{ height: "20px", width: "20px" }}
                                ></div>
                              </div>
                            )}
                          </div>
                        </button>
                        <button
                          style={{ width: "100px" }}
                          onClick={() => setShowPage(false)}
                          type="button"
                          className="btn btn-secondary btn-block mt-4 ms-2"
                        >
                          Close
                        </button>
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </Box>
          </div>
        </div>
      ) : (
        <div className="content p-3">
          <div class="card ">
            <div class="pl-4 pr-4 pt-4">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  {/* {accessObject?.addBlockBlastTour == "true" && (
                    <>
                      <button
                        style={{ width: "118px" }}
                        onClick={() => {
                          openAddTournament(1);
                        }}
                        type="button"
                        className="btn btn-primary btn-block "
                      >
                        <AddIcon />
                        Add New
                      </button>
                    </>
                  )} */}
                  <>
                    <button
                      style={{ width: "118px" }}
                      onClick={() => {
                        openAddTournament(1);
                        setImageFile("");
                        setIsEditDataImage1(false);
                        setShowData(false);
                        setFiles([]);
                      }}
                      type="button"
                      className="btn btn-primary btn-block "
                    >
                      <AddIcon />
                      Add New Game
                    </button>
                  </>
                </div>
                <div className="mt-2 col-md-6 col-lg-6 d-flex justify-content-start justify-content-lg-end">
                  <div>
                    <input
                      class="input-simple"
                      type="text"
                      placeholder="Tournament Name"
                      onChange={handleFilter}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body" style={{ overflow: "scroll" }}>
              <table id="" class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>SrNo</th>
                    <th>Date</th>
                    <th>GameId</th>
                    <th>GameId Dev</th>
                    <th>Name</th>
                    <th>Game Url</th>
                    <th>Type</th>
                    <th>Company Name</th>
                    <th>status</th>
                    {/* {(accessObject?.editBlockBlastTour == "true" ||
                      accessObject?.deleteBlockBlastTour == "true") && (
                      <th style={{ textAlign: "center" }}>Action</th>
                    )} */}
                    <th style={{ textAlign: "center" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {accessObject?.getBlockBlastTour == "true" ? (
                    <>
                      {!loader ? (
                        <>
                          {filteredData.length > 0 ? (
                            <>
                              {filteredData
                                ?.slice(pagination.start, pagination.end)
                                .map((item, index) => (
                                  <>
                                    <tr key={index}>
                                      <td>{index + 1 + pagination.start}</td>
                                      <td style={{ textAlign: "center" }}>
                                        {moment(item.createdAt).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </td>
                                      <td>{item.tourName}</td>
                                      <td>{item.betAmount}</td>
                                      <td>{item.time}</td>
                                      <td>{item.bot}</td>
                                      <td>{item.botComplexity}</td>
                                      <td>{item.active}</td>
                                      {(accessObject?.editBlockBlastTour ==
                                        "true" ||
                                        accessObject?.deleteBlockBlastTour ==
                                          "true") && (
                                        <td className="justify-content-evenly ">
                                          <div className="d-flex">
                                            {accessObject?.editBlockBlastTour ==
                                              "true" && (
                                              <EditIcon
                                                className="mr-3 courser"
                                                onClick={() =>
                                                  clickEditButton(item)
                                                }
                                              />
                                            )}
                                            {accessObject?.deleteBlockBlastTour ==
                                              "true" && (
                                              <ClearIcon
                                                className="courser text-danger"
                                                onClick={() =>
                                                  warningWithConfirmMessage(
                                                    item
                                                  )
                                                }
                                              />
                                            )}
                                          </div>
                                        </td>
                                      )}
                                    </tr>
                                  </>
                                ))}
                            </>
                          ) : (
                            <>
                              <tr
                                style={{ backgroundColor: "whitesmoke" }}
                                className="text-center"
                              >
                                <td colSpan={14}>
                                  <h5>No Data Available</h5>
                                </td>
                              </tr>
                            </>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={14} className="p-3">
                        <h5 className="text-center mb-0">Access Denied</h5>
                      </td>
                    </tr>
                  )} */}
                  <>
                    {!loader ? (
                      <>
                        {filteredData.length > 0 ? (
                          <>
                            {filteredData
                              ?.slice(pagination.start, pagination.end)
                              .map((item, index) => (
                                <>
                                  <tr key={index}>
                                    <td>{index + 1 + pagination.start}</td>
                                    <td style={{ textAlign: "center" }}>
                                      {moment(item.createdAt).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>{item.gameId}</td>
                                    <td>{item.gameIdDev}</td>
                                    <td>{item.name}</td>
                                    <td>{item.gameUrl}</td>
                                    <td>
                                      {item.type === 0 && "None"}
                                      {item.type === 1 && "Hot"}
                                      {item.type === 2 && "New"}
                                      {item.type === 3 && "Features"}
                                    </td>

                                    <td>{item.companyName}</td>
                                    <td
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <label class="switch ">
                                        <input
                                          type="checkbox"
                                          name="status"
                                          onChange={(e) => {
                                            onHandelToggle(
                                              e.target.checked,
                                              item.id
                                            );
                                          }}
                                          defaultChecked={item.status}
                                        />
                                        <span class="slider round"></span>
                                      </label>
                                    </td>
                                    {/* {(accessObject?.editBlockBlastTour ==
                                      "true" ||
                                      accessObject?.deleteBlockBlastTour ==
                                        "true") && (
                                      <td className="justify-content-evenly ">
                                        <div className="d-flex">
                                          {accessObject?.editBlockBlastTour ==
                                            "true" && (
                                            <EditIcon
                                              className="mr-3 courser"
                                              onClick={() =>
                                                clickEditButton(item)
                                              }
                                            />
                                          )}
                                          {accessObject?.deleteBlockBlastTour ==
                                            "true" && (
                                            <ClearIcon
                                              className="courser text-danger"
                                              onClick={() =>
                                                warningWithConfirmMessage(item)
                                              }
                                            />
                                          )}
                                        </div>
                                      </td>
                                    )} */}
                                    <td className="justify-content-evenly ">
                                      <div className="d-flex">
                                        <EditIcon
                                          className="mr-3 courser"
                                          onClick={() => clickEditButton(item)}
                                        />

                                        <ClearIcon
                                          className="courser text-danger"
                                          onClick={() =>
                                            warningWithConfirmMessage(item)
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ))}
                          </>
                        ) : (
                          <>
                            <tr
                              style={{ backgroundColor: "whitesmoke" }}
                              className="text-center"
                            >
                              <td colSpan={14}>
                                <h5>No Data Available</h5>
                              </td>
                            </tr>
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                </tbody>
              </table>
              {/* {accessObject?.getBlockBlastTour == "true" && (
                <div className="mt-4">{loader && <Loader />}</div>
              )} */}
              <div className="mt-4">{loader && <Loader />}</div>
            </div>
            {/* {accessObject?.getBlockBlastTour == "true" &&
              filteredData.length != 0 && (
                <Pagination
                  updateShowPerpagevalue={updateShowPerpagevalue}
                  showPrePage={showPrePage}
                  onPaginationChange={onPaginationChange}
                  total={filteredData.length}
                />
              )} */}
            {filteredData.length !== 0 && (
              <Pagination
                updateShowPerpagevalue={updateShowPerpagevalue}
                showPrePage={showPrePage}
                onPaginationChange={onPaginationChange}
                total={filteredData.length}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
