import axios from "axios";
import React from "react";
// import CreatableSelect from "react-select/creatable";
// import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { API_URL } from "../../config";
import AccessData from "./AccessData";
import Loader from "../Loader/Loader";

export default function AccessUser() {
  const [tableData, setTableData] = React.useState([]);
  // const [allData, setAllData] = React.useState([]);
  // const [selectValue, setSelectValue] = React.useState("");
  const [accessObject, setAccessOject] = React.useState([]);
  const [showList, setShowList] = React.useState(false);
  const [accessDataList, setAccessDataList] = React.useState({
    dashboardCount: "false",
    getAdminList: "false",
    adminListAdd: "false",
    adminListEdit: "false",
    adminListDelete: "false",
    adminAccessUser: "false",
    getPlayerList: "false",
    playerListEdit: "false",
    playerListDelete: "false",
    getPlayerBannedList: "false",
    getLivePlayerAppList: "false",
    getLivePlayerGameList: "false",
    getTeenPattiTourList: "false",
    teenPattiTourAdd: "false",
    teenPattiTourDelete: "false",
    getAviatorTourList: "false",
    getAndarBaharTourList: "false",
    getColorPredictionTourList: "false",
    getCarRouletteTourList: "false",
    getDragonTigerTourList: "false",
    getSevenUpDownTourList: "false",
    teenPattiTourEdit: "false",
    aviatorTourEdit: "false",
    andarBaharTourEdit: "false",
    colorPredictionTourEdit: "false",
    carRouletteTourEdit: "false",
    dragonTigerTourEdit: "false",
    sevenUpDownTourEdit: "false",
    getNotificationList: "false",
    notificationAdd: "false",
    notificationDelete: "false",
    getCoinPackageList: "false",
    coinPackageAdd: "false",
    coinPackageEdit: "false",
    coinPackageDelete: "false",
    getVersionList: "false",
    versionEdit: "false",
    getSpinWheelList: "false",
    spinWheelAdd: "false",
    spinWheelEdit: "false",
    spinWheelDelete: "false",
    getNoticeList: "false",
    noticeEdit: "false",
    getBannerList: "false",
    bannerAdd: "false",
    bannerEdit: "false",
    bannerDelete: "false",
    getHistoryList: "false",
    getAddCashList: "false",
    getPayoutList: "false",
    getBetList: "false",
    getWinList: "false",
    getIncomeList: "false",
    getIncomeAviatorList: "false",
    getIncomeAndarBaharList: "false",
    getIncomeColorPredictionList: "false",
    getIncomeCarRouletteList: "false",
    getIncomeDragonTigerList: "false",
    getIncome7UpDownList: "false",
    getReportPlayerList: "false",
    getReportHistoryList: "false",
    getStaffAdminList: "false",
    getStaffPlayerList: "false",
    getMainteanceList: "false",
    mainteanceEdit: "false",
    getSocialList: "false",
    editSocial: "false",
    getBonusList: "false",
    bonusEdit: "false",
    contactDetailsList: "false",
    historyDownload: "false",
    addCashDownload: "false",
    payoutDownload: "false",
    betDownload: "false",
    winDownload: "false",
    incomeDetailsDownload: "false",
    aviatorDetailsDownload: "false",
    andarBaharDetailsDownload: "false",
    dragonTigerDetailsDownload: "false",
    carRouletteDetailsDownload: "false",
    colorPredictionDetailsDownload: "false",
    sevenUpDownDetailsDownload: "false",
    playerReportDownload: "false",
    historyReportDownload: "false",
    withdrawEdit: "false",
    getWithdrawList: "false",
  });
  const [loader, setLoader] = React.useState(false);
  const [buttonLoader, setButtonLoader] = React.useState(false);
  const [alert, setAlert] = React.useState(null);

  React.useEffect(() => {
    getPlayerList();
  }, []);

  const getPlayerList = () => {
    axios(`${API_URL}/api/admin/get-admin-player`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        console.log("dataTable.dataRows :::", response.data.message);

        if (response.data.isValid) {
          // setAllData(response.data.message);
          const data = response.data.message.map((item) => ({
            value: item.email,
            label: `${item.name} (${item.email})`,
          }));
          console.log("data::: ", data);
          // setAccessDataList();
          setTableData(data);
        }
      })
      .catch((error) => {
        console.log("getBranch error :::", error);
      });
  };

  const handelAddData = () => {
    setButtonLoader(true);

    const merge = { ...accessDataList, id: accessObject._id };
    for (const key in merge) {
      if (typeof merge[key] === "boolean") {
        merge[key] = String(merge[key]);
      }
    }

    console.log("margeData::: ", merge);
    axios
      .post(`${API_URL}/api/admin/update-admin-player-access`, merge)
      .then((res) => {
        successEdit(res.data.message);
        console.log("res::: ", res);
      })
      .catch((err) => {
        alert("False");
        console.log("update err :::", err);
      });
  };

  // React.useEffect(() => {
  //   handleChange();
  // }, []);

  const handleChange = (email) => {
    setLoader(true);
    setShowList(false);
    console.log("e.target.value::: ", email);
    // const result = allData.filter((item) => item.email == selectValue);
    // if (result) {
    //   console.log("result::: ", result);
    //   setLoader(false);
    //   setAccessOject(result);
    // }
    axios
      .post(`${API_URL}/api/admin/get-admin-player-by-email`, { email: email })
      .then((res) => {
        setLoader(false);
        setAccessOject(res.data.message);
        console.log("res.data.message::: ", res.data.message);
        setAccessDataList(res.data.message.access);
        setShowList(true);
        console.log("res::: ", res);
      })
      .catch((err) => {
        console.log("update err :::", err);
      });
  };
  const successEdit = (msg) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title=""
        onConfirm={() => {
          setAlert(null);
          setButtonLoader(false);
        }}
        confirmBtnBsStyle="success"
      >
        <h4>{msg}</h4>
      </SweetAlert>
    );
  };

  return (
    <div>
      {alert}
      <div class="content p-3 ">
        <div className="card p-3">
          <label className="mt-3 mb-3">Access User</label>
          <div className="row">
            <div className="col-12">
              {/* <Select
                isClearable
                isValidNewOption={() => false}
                options={tableData}
                onChange={handleChange}
              /> */}
              {/* accessObject?.adminAccessUser == "true" */}
              {true ? (
                <>
                  <label for="cars">Choose email:</label>
                  {console.log("tableData::: ", tableData)}
                  <select
                    name="email"
                    id="email"
                    className="form-control"
                    onChange={(e) => {
                      setLoader(true);
                      handleChange(e.target.value);
                    }}
                  >
                    <option value="">select value ...</option>
                    {tableData?.map((item) => (
                      <>
                        <option value={item.value}>{item.label}</option>
                      </>
                    ))}
                  </select>
                  {/* <div className="col-1">
              <button className="btn btn-primary" onClick={findData}>
                Show
              </button>
            </div> */}
                  {loader && <Loader />}
                  {showList && (
                    <div className="mt-2">
                      <AccessData
                        buttonLoader={buttonLoader}
                        handelAddData={handelAddData}
                        accessObject={accessObject}
                        setAccessDataList={setAccessDataList}
                        accessDataList={accessDataList}
                      />
                    </div>
                  )}
                </>
              ) : (
                <table
                  style={{
                    width: "100%",
                    backgroundColor: "rgba(0,0,0,.05)",
                  }}
                >
                  <tr>
                    <td colSpan={7} className="p-3">
                      <h5 className="text-center mb-0">Access Denied</h5>
                    </td>
                  </tr>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
