import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useAppContext } from "../../Lib/ContextLib";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_URL } from "../../config";
import Logo from "../../img/Web_Swift_logo.png";
import MailRoundedIcon from "@mui/icons-material/MailRounded";

export default function ChangeEmail() {
  const history = useNavigate();
  const [buttonLoader, setButtonLoader] = React.useState(false);

  const { setAuthenticated } = useAppContext();

  const LoginSchema4 = Yup.object().shape({
    newEmail: Yup.string().email("Invalid Email").required("required"),
  });

  return (
    <div className="hold-transition login-page">
      <div class="login-box">
        <div class="card card-outline card-success">
          <div class="card-header text-center">
            {/* <a class="h1">
              <b>Casino</b>
            </a> */}
            <img
              src={Logo}
              alt="Logo"
              style={{ opacity: "0.8", width: "70%", height: "50px" }}
            />
          </div>
          <div class="card-body">
            <p class="login-box-msg">Change Email</p>

            <Formik
              initialValues={{
                newEmail: "",
              }}
              validationSchema={LoginSchema4}
              onSubmit={(values, { resetForm }) => {
                setButtonLoader(true);
                console.log("values::: ", values);
                const getUserAccesss = sessionStorage.getItem("user");
                console.log("getUserAccesss::: ", getUserAccesss);
                const email = JSON.parse(getUserAccesss)[0].email;
                console.log("email::: ", email);
                const data = {
                  oldEmail: email,
                  newEmail: values.newEmail,
                };
                console.log("data::: ", data);

                axios(`${API_URL}/api/auth/change-email`, {
                  method: "POST",
                  credentials: "include",
                  data: data,
                })
                  .then((result) => {
                    console.log("result::: ", result);

                    if (result.data.isValid) {
                      toast.success(result.data.message);
                      history("/login");
                      setButtonLoader(false);
                      sessionStorage.clear();
                      setAuthenticated(false);
                      resetForm();
                    } else {
                      toast.warning(result.data.message);
                      setButtonLoader(false);
                      resetForm();
                    }
                  })
                  .catch((err) => {
                    console.log("err::: ", err);
                  });
              }}
            >
              {({ touched, errors, isSubmitting, values }) => (
                <div>
                  <Form>
                    <div class="input-group mb-3">
                      <Field
                        type="email"
                        name="newEmail"
                        className={`form-control
                          ${
                            touched.newEmail && errors.newEmail
                              ? "is-invalid"
                              : ""
                          }`}
                        placeholder="New Email"
                      />
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <MailRoundedIcon />
                        </div>
                      </div>
                      <ErrorMessage
                        component="div"
                        name="newEmail"
                        className="invalid-feedback"
                      />
                    </div>

                    <button
                      style={{ width: "100px" }}
                      type="submit"
                      className="btn btn-primary btn-block mt-4 mr-2"
                      disabled={buttonLoader}
                    >
                      <div
                        className="d-flex"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className="d-flex"
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <b>Submit</b>
                        </div>
                        {buttonLoader && (
                          <div>
                            <div
                              class="spinner-border ml-2 mt-1"
                              role="status"
                              style={{ height: "20px", width: "20px" }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </button>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
