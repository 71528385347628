exports.generateColorsForChart = (len) => {
  const baseColors = [
    "#826bf8",
    "#00d4bd",
    "#3949AB",
    "#00897B",
    "#558B2F",
    "#F4511E",
    "#5D4037",
    "#757575",
    "#BF360C",
    "#1A237E",
    "#E65100",
    "#263238",
    "#455A64",
    "#3E2723",
    "#424242",
    "#BF360C",
    "#004D40",
    "#212121",
    "#FF6F00",
    "#004D40",
    "#33691E",
    "#827717",
  ];
  const colors = [...baseColors];

  while (colors.length < len) {
    const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
    if (!colors.includes(randomColor)) {
      colors.push(randomColor);
    }
  }

  return colors;
};
