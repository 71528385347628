import React from "react";
// import axios from "axios";
// import { API_URL } from "../../config";
import moment from "moment";
import Pagination from "../../Pagination/Pagination";
import Loader from "../Loader/Loader";
import { useAppContext } from "../../Lib/ContextLib";
import DataServices from "../../services/requestApi";
import { toast } from "react-toastify";

export default function PlayerBanned() {
  const { accessObject } = useAppContext();
  const [dataRows, setdataRows] = React.useState([]);
  // const [searchPhoneNo, setSearchPhoneNo] = React.useState();
  const [showPrePage, setShowPrePage] = React.useState(10);
  const [loader, setLoader] = React.useState(false);
  const [filteredData, setFilteredData] = React.useState([]);

  React.useEffect(() => {
    getBranch();
  }, []);
  const [pagination, setPagination] = React.useState({
    start: 0,
    end: showPrePage,
  });
  const updateShowPerpagevalue = (value) => {
    setShowPrePage(value);
    setPagination({ start: 0, end: value });
  };
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const getBranch = async () => {
    setLoader(true);
    try {
      const res = await DataServices.AllPlayerList();
      console.log("data::: ", res);
      setLoader(false);

      const filteredBooks = res?.data?.data.filter((val) =>
        val?.playerStatus.includes("banned")
      );

      setdataRows(filteredBooks);

      setFilteredData(filteredBooks);
    } catch (error) {
      toast.error("Error");
    }
  };

  // Event handler for filtering the data
  const handleFilter = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    console.log("tableData::: ", dataRows);
    const filtered = dataRows.filter((item) => {
      return (
        item?.email.toLowerCase().includes(searchQuery) ||
        item?.username.toLowerCase().includes(searchQuery)
      );
    });
    setFilteredData(filtered);
  };

  return (
    <div>
      <div className="content p-3">
        <div class="card ">
          <div class="pl-4 pr-4 pt-4">
            <div className="row">
              <div className="col-md-6 col-lg-6">
                {" "}
                <h3 class="card-title pb-3">
                  <b>Player Banned</b>
                </h3>
              </div>
              <div className="col-md-6 col-lg-6 d-flex justify-content-start justify-content-lg-end">
                <div>
                  <input
                    class="input-simple"
                    type="text"
                    placeholder="Email And Name ..."
                    onChange={handleFilter}
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="card-body" style={{ overflow: "scroll" }}>
            <table id="" class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th scope="col" style={{ textAlign: "center" }}>
                    SrNo
                  </th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Date
                  </th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Player Id
                  </th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Name
                  </th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Email
                  </th>

                  <th scope="col" style={{ textAlign: "center" }}>
                    Status
                  </th>
                </tr>
              </thead>
              {accessObject?.getPlayerBannedList === "true" ? (
                <>
                  <tbody>
                    {!loader ? (
                      <>
                        {filteredData.length > 0 ? (
                          <>
                            {filteredData
                              ?.slice(pagination.start, pagination.end)
                              .map((row, index) => (
                                <>
                                  <tr
                                    key={index}
                                    style={{ textAlign: "center" }}
                                  >
                                    <td style={{ textAlign: "center" }}>
                                      {index + 1 + pagination.start}
                                    </td>
                                    <td>
                                      {moment(row.createdAt).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {row.playerId}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {row.username}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {row.email}
                                    </td>

                                    <td style={{ textAlign: "center" }}>
                                      {row.playerStatus}
                                    </td>
                                  </tr>
                                </>
                              ))}
                          </>
                        ) : (
                          <>
                            <tr>
                              <td colSpan={9}>
                                <h5 className="text-center">
                                  No Data Available
                                </h5>
                              </td>
                            </tr>
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </tbody>
                </>
              ) : (
                <>
                  <tr style={{ backgroundColor: "rgba(0,0,0,.05)" }}>
                    <td colSpan={9}>
                      <h5 className="text-center">Access Denied</h5>
                    </td>
                  </tr>
                </>
              )}
            </table>
            {/* {accessObject?.getPlayerBannedList == "true" && (
              <div className="mt-4">{loader && <Loader />}</div>
            )} */}
            <div className="mt-4">{loader && <Loader />}</div>
          </div>
          {filteredData.length !== 0 && (
            <Pagination
              updateShowPerpagevalue={updateShowPerpagevalue}
              showPrePage={showPrePage}
              onPaginationChange={onPaginationChange}
              total={filteredData.length}
            />
          )}
        </div>
      </div>
    </div>
  );
}
