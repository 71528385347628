import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import Pagination from "../../Pagination/Pagination";
import { useAppContext } from "../../Lib/ContextLib";
import { API_URL } from "../../config";
import Loader from "../Loader/Loader";
import moment from "moment";
import DataServices from "../../services/requestApi";
import { toast } from "react-toastify";

export default function SpinWheel() {
  const { showPage, setShowPage, accessObject } = useAppContext();
  const [alert, setAlert] = React.useState(null);
  const [tableData, setTableData] = React.useState([]);
  const [searchSubType] = React.useState();
  const [rowValue, setRowValue] = React.useState();
  const [openValue, setOpenValue] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  const [searchTourName, setSearchTourName] = React.useState();
  const [buttonLoader, setButtonLoader] = React.useState(false);
  const [showPrePage] = React.useState(10);
  const [pagination, setPagination] = React.useState({
    start: 0,
    end: showPrePage,
  });
  const [accessDataList, setAccessDataList] = React.useState({
    dashboardCount: "false",
    getAdminList: "false",
    adminListAdd: "false",
    adminListEdit: "false",
    adminListDelete: "false",
    adminAccessUser: "false",
    getPlayerList: "false",
    playerListEdit: "false",
    playerListDelete: "false",
    getPlayerBannedList: "false",
    getLivePlayerAppList: "false",
    getLivePlayerGameList: "false",
    getTeenPattiTourList: "false",
    teenPattiTourAdd: "false",
    teenPattiTourDelete: "false",
    getAviatorTourList: "false",
    getAndarBaharTourList: "false",
    getColorPredictionTourList: "false",
    getCarRouletteTourList: "false",
    getDragonTigerTourList: "false",
    getSevenUpDownTourList: "false",
    teenPattiTourEdit: "false",
    aviatorTourEdit: "false",
    andarBaharTourEdit: "false",
    colorPredictionTourEdit: "false",
    carRouletteTourEdit: "false",
    dragonTigerTourEdit: "false",
    sevenUpDownTourEdit: "false",
    getNotificationList: "false",
    notificationAdd: "false",
    notificationDelete: "false",
    getCoinPackageList: "false",
    coinPackageAdd: "false",
    coinPackageEdit: "false",
    coinPackageDelete: "false",
    getVersionList: "false",
    versionEdit: "false",
    getSpinWheelList: "false",
    spinWheelAdd: "false",
    spinWheelEdit: "false",
    spinWheelDelete: "false",
    getNoticeList: "false",
    noticeEdit: "false",
    getBannerList: "false",
    bannerAdd: "false",
    bannerEdit: "false",
    bannerDelete: "false",
    getHistoryList: "false",
    getAddCashList: "false",
    getPayoutList: "false",
    getBetList: "false",
    getWinList: "false",
    getIncomeList: "false",
    getIncomeAviatorList: "false",
    getIncomeAndarBaharList: "false",
    getIncomeColorPredictionList: "false",
    getIncomeCarRouletteList: "false",
    getIncomeDragonTigerList: "false",
    getIncome7UpDownList: "false",
    getReportPlayerList: "false",
    getReportHistoryList: "false",
    getStaffAdminList: "false",
    getStaffPlayerList: "false",
    getMainteanceList: "false",
    mainteanceEdit: "false",
    getSocialList: "false",
    editSocial: "false",
    getBonusList: "false",
    bonusEdit: "false",
    contactDetailsList: "false",
    historyDownload: "false",
    addCashDownload: "false",
    payoutDownload: "false",
    betDownload: "false",
    winDownload: "false",
    incomeDetailsDownload: "false",
    aviatorDetailsDownload: "false",
    andarBaharDetailsDownload: "false",
    dragonTigerDetailsDownload: "false",
    carRouletteDetailsDownload: "false",
    colorPredictionDetailsDownload: "false",
    sevenUpDownDetailsDownload: "false",
    playerReportDownload: "false",
    historyReportDownload: "false",
    withdrawEdit: "false",
    getWithdrawList: "false",
  });

  React.useEffect(() => {
    getBranch();
  }, []);

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const LoginSchema = Yup.object().shape({
    name: Yup.string().required("required"),
    email: Yup.string().required("required"),
    password: Yup.string().required("required"),
  });

  const getBranch = async () => {
    setLoader(true);
    try {
      const { data } = await DataServices.AllAdminList();
      setLoader(false);
      // console.log("dataTable.dataRows :::", data);
      setTableData(data?.data);
    } catch (e) {
      toast.error(e.data.message);
    }
  };

  const searchingData = [];
  let data = [];
  console.log("tableData 111 ---", tableData, searchTourName);
  if (searchTourName) {
    console.log("tableData 222 ---", tableData, tableData.length > 0);
    data = tableData.filter((item) => {
      return Object.values(item?.email).join("").includes(searchTourName);
    });
    console.log("data 111 ---", data);
    data?.map((dataItem) => searchingData.push(dataItem));
  } else {
    tableData.map((dataItem) => searchingData.push(dataItem));
  }

  const clickEditButton = (item) => {
    setShowPage(true);
    console.log("item edit value :::", item);
    setRowValue(item);
    setOpenValue(2);
  };

  const openAddTournament = (value) => {
    setRowValue();
    console.log("openAddTournament :::", value);
    setShowPage(true);
    setOpenValue(value);
  };

  const updateTournament = (value) => {
    console.log("updateTournament value :::", value);
    const data = { ...value, id: rowValue._id };

    axios
      .post(`${API_URL}/api/admin/update-admin-player-access`, data)
      .then((res) => {
        console.log("update :::", res.data);
        if (res.data.isValid) {
          setButtonLoader(false);
          successEdit(res.data.message);
        }
      })
      .catch((err) => {
        console.log("update err :::", err);
        setButtonLoader(false);
      });
  };

  const addAdmin = async (value) => {
    try {
      const { data } = await DataServices.SignUp(value);
      console.log("response::: ", data.status);
      if (data.status) {
        setButtonLoader(false);
        successAdd(data?.message);
      } else {
        console.log("failed::: ");
      }
    } catch (e) {
      console.log("e::: ", e);
      if (e?.response?.data.statusCode === 400) {
        console.log("else::: ");
        // toast.warning(e?.response?.data.message);
        setButtonLoader(false);
      }
    }
  };

  const warningWithConfirmMessage = (e) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title="Are you sure?"
        onConfirm={() => {
          deleteData(e);
          //  setIsPlayerEditButtonClicked(false);
        }}
        onCancel={() => setAlert(null)}
        // confirmBtnCssClass={classes.button + " " + classes.danger}
        // cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      ></SweetAlert>
    );
  };

  const successDeleted = (msg) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title={<h4>{msg}</h4>}
        onConfirm={() => {
          getBranch();
          setAlert(null);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      ></SweetAlert>
    );
  };

  const deleteData = (e) => {
    console.log("e===>", e._id);
    const data = { id: e._id };
    axios.post(`${API_URL}/api/admin/delete-admin-player`, data).then((res) => {
      console.log(res);
      console.log(res.data);
      if (res.data.isValid) {
        successDeleted(res.data.message);
      }
      getBranch();
    });
  };

  const successAdd = (msg) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title={<h4>{msg}</h4>}
        onConfirm={() => {
          getBranch();
          setAlert(null);
          setShowPage(false);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      ></SweetAlert>
    );
  };

  const successEdit = (msg) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title={<h4>{msg}</h4>}
        onConfirm={() => {
          getBranch();
          setAlert(null);
          setShowPage(false);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      ></SweetAlert>
    );
  };

  return (
    <>
      {alert}
      {showPage ? (
        <div class="content p-3 ">
          <div className="card p-3">
            <Box sx={{ width: "100%" }}>
              <Formik
                initialValues={{
                  name: rowValue ? rowValue?.name : "",
                  email: rowValue ? rowValue?.email : "",
                  password: rowValue ? rowValue?.password : "",
                }}
                validationSchema={LoginSchema}
                onSubmit={(values) => {
                  setButtonLoader(true);
                  if (parseInt(openValue) === 1) {
                    addAdmin(values);
                  }
                  if (parseInt(openValue) === 2) {
                    updateTournament(values);
                  }
                }}
              >
                {({ touched, errors, isSubmitting, values, setFieldValue }) => (
                  <div>
                    <Form>
                      <div className="form-group">
                        <label htmlFor="password">Name</label>
                        <Field
                          type="text"
                          name="name"
                          placeholder="Name"
                          className={`mt-2 form-control
                          ${touched.name && errors.name ? "is-invalid" : ""}`}
                        />
                        <ErrorMessage
                          component="div"
                          name="name"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">Email</label>
                        <Field
                          type="email"
                          name="email"
                          placeholder="Email"
                          className={`mt-2 form-control
                          ${touched.email && errors.email ? "is-invalid" : ""}`}
                        />
                        <ErrorMessage
                          component="div"
                          name="email"
                          className="invalid-feedback"
                        />
                      </div>
                      {/* <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <Field
                          type="password"
                          name="password"
                          placeholder="Password"
                          className={`mt-2 form-control
                          ${
                            touched.password && errors.password
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="password"
                          className="invalid-feedback"
                        />
                      </div> */}
                      <div
                        className="card pb-2"
                        style={{ background: "#F5F5F5" }}
                      >
                        <h6
                          className="text-center p-1"
                          style={{ background: "#c5bed1", color: "black" }}
                        >
                          Dashboard
                        </h6>
                        <div className="row">
                          <div className="col-sm-12 col-lg-6 col-md-6 ">
                            <table className="m-2">
                              <thead>
                                <th>Dashboard Count</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="custom-checkbox d-flex">
                                      <div class="form-group m-2">
                                        <input
                                          type="checkbox"
                                          id="CountView"
                                          defaultChecked={
                                            accessObject?.access
                                              ?.dashboardCount === "true"
                                              ? true
                                              : false
                                          }
                                          onChange={(e) => {
                                            setAccessDataList({
                                              ...accessDataList,
                                              dashboardCount:
                                                e.target.checked === true
                                                  ? "true"
                                                  : "false",
                                            });
                                          }}
                                        />
                                        <label for="CountView">View</label>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div
                        className="card pb-2"
                        style={{ background: "#F5F5F5" }}
                      >
                        <h6
                          className="text-center p-1"
                          style={{ background: "#c5bed1", color: "black" }}
                        >
                          Admin
                        </h6>
                        <div className="row">
                          <div className="col-sm-12 col-lg-6 col-md-6 ">
                            <table className="m-2">
                              <thead>
                                <th>Admin List</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="custom-checkbox d-flex">
                                      <div class="form-group m-2">
                                        <input
                                          type="checkbox"
                                          id="getAdminList"
                                          defaultChecked={
                                            accessObject?.access
                                              ?.getAdminList === "true"
                                              ? true
                                              : false
                                          }
                                          onChange={(e) => {
                                            setAccessDataList({
                                              ...accessDataList,
                                              getAdminList:
                                                e.target.checked === true
                                                  ? "true"
                                                  : "false",
                                            });
                                          }}
                                        />
                                        <label for="getAdminList">View</label>
                                      </div>
                                      {console.log(
                                        "accessDataList.getAdminList::: ",
                                        accessDataList.getAdminList
                                      )}
                                      {accessDataList.getAdminList ===
                                        "true" && (
                                        <>
                                          <div class="form-group m-2">
                                            <input
                                              type="checkbox"
                                              id="adminListAdd"
                                              defaultChecked={
                                                accessObject?.access
                                                  ?.getAdminList === "true"
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) => {
                                                setAccessDataList({
                                                  ...accessDataList,
                                                  getAdminList:
                                                    e.target.checked === true
                                                      ? "true"
                                                      : "false",
                                                });
                                              }}
                                            />
                                            <label for="adminListAdd">
                                              Add
                                            </label>
                                          </div>
                                          <div class="form-group m-2">
                                            <input
                                              type="checkbox"
                                              id="adminListEdit"
                                              defaultChecked={
                                                accessObject?.access
                                                  ?.adminListEdit === "true"
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) => {
                                                setAccessDataList({
                                                  ...accessDataList,
                                                  adminListEdit:
                                                    e.target.checked === true
                                                      ? "true"
                                                      : "false",
                                                });
                                              }}
                                            />
                                            <label for="adminListEdit">
                                              Edit
                                            </label>
                                          </div>
                                          <div class="form-group m-2">
                                            <input
                                              type="checkbox"
                                              id="adminListDelete"
                                              defaultChecked={
                                                accessObject?.access
                                                  ?.adminListDelete === "true"
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) => {
                                                setAccessDataList({
                                                  ...accessDataList,
                                                  adminListDelete:
                                                    e.target.checked === true
                                                      ? "true"
                                                      : "false",
                                                });
                                              }}
                                            />
                                            <label for="adminListDelete">
                                              Delete
                                            </label>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-sm-12 col-lg-6 col-md-6 ">
                            <table className="m-2">
                              <thead>
                                <th>Admin Access</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="custom-checkbox d-flex">
                                      <div class="form-group m-2">
                                        <input
                                          type="checkbox"
                                          id="adminAccessUser"
                                          defaultChecked={
                                            accessObject?.access
                                              ?.adminAccessUser === "true"
                                              ? true
                                              : false
                                          }
                                          onChange={(e) => {
                                            setAccessDataList({
                                              ...accessDataList,
                                              adminAccessUser:
                                                e.target.checked === true
                                                  ? "true"
                                                  : "false",
                                            });
                                          }}
                                        />
                                        <label for="adminAccessUser">
                                          View
                                        </label>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div
                        className="card pb-2"
                        style={{ background: "#F5F5F5" }}
                      >
                        <h6
                          className="text-center p-1"
                          style={{ background: "#c5bed1", color: "black" }}
                        >
                          Player
                        </h6>
                        <div className="row">
                          <div className="col-sm-12 col-lg-6 col-md-6 ">
                            {console.log(
                              ' accessObject?.access?.getPlayerList === "true"::: ',
                              accessObject?.access?.getPlayerList === "true"
                            )}
                            <table className="m-2">
                              <thead>
                                <th>Player List</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="custom-checkbox d-flex">
                                      <div class="form-group m-2">
                                        {console.log(
                                          " accessObject?.access?.getPlayerList::: ",
                                          accessObject?.access?.getPlayerList
                                        )}
                                        <input
                                          type="checkbox"
                                          id="getPlayerList"
                                          onChange={(e) => {
                                            setAccessDataList({
                                              ...accessDataList,
                                              getPlayerList:
                                                e.target.checked === true
                                                  ? "true"
                                                  : "false",
                                            });
                                          }}
                                          defaultChecked={
                                            accessObject?.access
                                              ?.getPlayerList === "true"
                                              ? true
                                              : false
                                          }
                                        />
                                        <label for="getPlayerList">View</label>
                                      </div>
                                      {accessDataList?.getPlayerList ===
                                        "true" && (
                                        <>
                                          <div class="form-group m-2">
                                            <input
                                              type="checkbox"
                                              id="playerListEdit"
                                              onChange={(e) => {
                                                setAccessDataList({
                                                  ...accessDataList,
                                                  playerListEdit:
                                                    e.target.checked === true
                                                      ? "true"
                                                      : "false",
                                                });
                                              }}
                                              defaultChecked={
                                                accessObject?.access
                                                  ?.playerListEdit === "true"
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <label for="playerListEdit">
                                              Edit
                                            </label>
                                          </div>
                                          <div class="form-group m-2">
                                            <input
                                              type="checkbox"
                                              id="playerListDelete"
                                              onChange={(e) => {
                                                setAccessDataList({
                                                  ...accessDataList,
                                                  playerListDelete:
                                                    e.target.checked === true
                                                      ? "true"
                                                      : "false",
                                                });
                                              }}
                                              defaultChecked={
                                                accessObject?.access
                                                  ?.playerListDelete === "true"
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <label for="playerListDelete">
                                              Delete
                                            </label>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-sm-12 col-lg-6 col-md-6 ">
                            <table className="m-2">
                              <thead>
                                <th>Player Banned</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="custom-checkbox d-flex">
                                      <div class="form-group m-2">
                                        <input
                                          type="checkbox"
                                          id="getPlayerBannedList"
                                          onChange={(e) => {
                                            setAccessDataList({
                                              ...accessDataList,
                                              getPlayerBannedList:
                                                e.target.checked === true
                                                  ? "true"
                                                  : "false",
                                            });
                                          }}
                                          defaultChecked={
                                            accessObject?.access
                                              ?.getPlayerBannedList === "true"
                                              ? true
                                              : false
                                          }
                                        />
                                        <label for="getPlayerBannedList">
                                          View
                                        </label>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div
                        className="card pb-2"
                        style={{ background: "#F5F5F5" }}
                      >
                        <h6
                          className="text-center p-1"
                          style={{ background: "#c5bed1", color: "black" }}
                        >
                          Controls
                        </h6>
                        <div className="row">
                          {/* Notification */}
                          <div className="col-lsm-12 col-lg-4 col-md-4">
                            <table className="m-2">
                              <thead>
                                <th>Notification</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="custom-checkbox d-flex">
                                      <div class="form-group m-2">
                                        <input
                                          type="checkbox"
                                          id="getNotificationList"
                                          onChange={(e) => {
                                            setAccessDataList({
                                              ...accessDataList,
                                              getNotificationList:
                                                e.target.checked === true
                                                  ? "true"
                                                  : "false",
                                            });
                                          }}
                                          defaultChecked={
                                            accessObject?.access
                                              ?.getNotificationList === "true"
                                              ? true
                                              : false
                                          }
                                        />
                                        <label for="getNotificationList">
                                          View
                                        </label>
                                      </div>
                                      {accessDataList?.getNotificationList ===
                                        "true" && (
                                        <>
                                          <div class="form-group m-2">
                                            <input
                                              type="checkbox"
                                              id="notificationAdd"
                                              onChange={(e) => {
                                                setAccessDataList({
                                                  ...accessDataList,
                                                  notificationAdd:
                                                    e.target.checked === true
                                                      ? "true"
                                                      : "false",
                                                });
                                              }}
                                              defaultChecked={
                                                accessObject?.access
                                                  ?.notificationAdd === "true"
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <label for="notificationAdd">
                                              Add
                                            </label>
                                          </div>
                                          <div class="form-group m-2">
                                            <input
                                              type="checkbox"
                                              id="notificationDelete"
                                              onChange={(e) => {
                                                setAccessDataList({
                                                  ...accessDataList,
                                                  notificationDelete:
                                                    e.target.checked === true
                                                      ? "true"
                                                      : "false",
                                                });
                                              }}
                                              defaultChecked={
                                                accessObject?.access
                                                  ?.notificationDelete ===
                                                "true"
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <label for="notificationDelete">
                                              Delete
                                            </label>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          {/* Separate Notification */}
                          <div className="col-lsm-12 col-lg-4 col-md-4">
                            <table className="m-2">
                              <thead>
                                <th>Separate Notification</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="custom-checkbox d-flex">
                                      <div class="form-group m-2">
                                        <input
                                          type="checkbox"
                                          id="getNotificationListSeparate"
                                          onChange={(e) => {
                                            setAccessDataList({
                                              ...accessDataList,
                                              getNotificationListSeparate:
                                                e.target.checked === true
                                                  ? "true"
                                                  : "false",
                                            });
                                          }}
                                          defaultChecked={
                                            accessObject?.access
                                              ?.getNotificationListSeparate ===
                                            "true"
                                              ? true
                                              : false
                                          }
                                        />
                                        <label for="getNotificationListSeparate">
                                          View
                                        </label>
                                      </div>
                                      {accessDataList?.getNotificationListSeparate ===
                                        "true" && (
                                        <>
                                          <div class="form-group m-2">
                                            <input
                                              type="checkbox"
                                              id="notificationSeparateSend"
                                              onChange={(e) => {
                                                setAccessDataList({
                                                  ...accessDataList,
                                                  notificationSeparateSend:
                                                    e.target.checked === true
                                                      ? "true"
                                                      : "false",
                                                });
                                              }}
                                              defaultChecked={
                                                accessObject?.access
                                                  ?.notificationSeparateSend ===
                                                "true"
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <label for="notificationSeparateSend">
                                              Send
                                            </label>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          {/* Version Android */}
                          <div className="col-lsm-12 col-lg-4 col-md-4">
                            <table className="m-2">
                              <thead>
                                <th>Version Android</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="custom-checkbox d-flex">
                                      <div class="form-group m-2">
                                        <input
                                          type="checkbox"
                                          id="getVersionListAndroid"
                                          onChange={(e) => {
                                            setAccessDataList({
                                              ...accessDataList,
                                              getVersionListAndroid:
                                                e.target.checked === true
                                                  ? "true"
                                                  : "false",
                                            });
                                          }}
                                          defaultChecked={
                                            accessObject?.access
                                              ?.getVersionListAndroid === "true"
                                              ? true
                                              : false
                                          }
                                        />
                                        <label for="getVersionListAndroid">
                                          View
                                        </label>
                                      </div>
                                      {accessDataList?.getVersionListAndroid ===
                                        "true" && (
                                        <>
                                          <div class="form-group m-2">
                                            <input
                                              type="checkbox"
                                              id="versionEditAndroid"
                                              onChange={(e) => {
                                                setAccessDataList({
                                                  ...accessDataList,
                                                  versionEditAndroid:
                                                    e.target.checked === true
                                                      ? "true"
                                                      : "false",
                                                });
                                              }}
                                              defaultChecked={
                                                accessObject?.access
                                                  ?.versionEditAndroid ===
                                                "true"
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <label for="versionEditAndroid">
                                              Edit
                                            </label>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          {/* Version IOS */}
                          <div className="col-lsm-12 col-lg-4 col-md-4">
                            <table className="m-2">
                              <thead>
                                <th>Version IOS</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="custom-checkbox d-flex">
                                      <div class="form-group m-2">
                                        <input
                                          type="checkbox"
                                          id="getVersionListIos"
                                          onChange={(e) => {
                                            setAccessDataList({
                                              ...accessDataList,
                                              getVersionListIos:
                                                e.target.checked === true
                                                  ? "true"
                                                  : "false",
                                            });
                                          }}
                                          defaultChecked={
                                            accessObject?.access
                                              ?.getVersionListIos === "true"
                                              ? true
                                              : false
                                          }
                                        />
                                        <label for="getVersionListIos">
                                          View
                                        </label>
                                      </div>
                                      {accessDataList?.getVersionListIos ===
                                        "true" && (
                                        <>
                                          <div class="form-group m-2">
                                            <input
                                              type="checkbox"
                                              id="versionEditIos"
                                              onChange={(e) => {
                                                setAccessDataList({
                                                  ...accessDataList,
                                                  versionEditIos:
                                                    e.target.checked === true
                                                      ? "true"
                                                      : "false",
                                                });
                                              }}
                                              defaultChecked={
                                                accessObject?.access
                                                  ?.versionEditIos === "true"
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <label for="versionEditIos">
                                              Edit
                                            </label>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          {/* RestrictedArea */}
                          <div className="col-lsm-12 col-lg-4 col-md-4">
                            <table className="m-2">
                              <thead>
                                <th>Restricted Area</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="custom-checkbox d-flex">
                                      <div class="form-group m-2">
                                        <input
                                          type="checkbox"
                                          id="getRestrictedArea"
                                          onChange={(e) => {
                                            setAccessDataList({
                                              ...accessDataList,
                                              getRestrictedArea:
                                                e.target.checked === true
                                                  ? "true"
                                                  : "false",
                                            });
                                          }}
                                          defaultChecked={
                                            accessObject?.access
                                              ?.getRestrictedArea === "true"
                                              ? true
                                              : false
                                          }
                                        />
                                        <label for="getRestrictedArea">
                                          View
                                        </label>
                                      </div>
                                      {accessDataList?.getRestrictedArea ===
                                        "true" && (
                                        <>
                                          <div class="form-group m-2">
                                            <input
                                              type="checkbox"
                                              id="addRestrictedArea"
                                              onChange={(e) => {
                                                setAccessDataList({
                                                  ...accessDataList,
                                                  addRestrictedArea:
                                                    e.target.checked === true
                                                      ? "true"
                                                      : "false",
                                                });
                                              }}
                                              defaultChecked={
                                                accessObject?.access
                                                  ?.addRestrictedArea === "true"
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <label for="addRestrictedArea">
                                              Add
                                            </label>
                                          </div>
                                          <div class="form-group m-2">
                                            <input
                                              type="checkbox"
                                              id="editRestrictedArea"
                                              onChange={(e) => {
                                                setAccessDataList({
                                                  ...accessDataList,
                                                  editRestrictedArea:
                                                    e.target.checked === true
                                                      ? "true"
                                                      : "false",
                                                });
                                              }}
                                              defaultChecked={
                                                accessObject?.access
                                                  ?.editRestrictedArea ===
                                                "true"
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <label for="editRestrictedArea">
                                              Edit
                                            </label>
                                          </div>
                                          <div class="form-group m-2">
                                            <input
                                              type="checkbox"
                                              id="deleteRestrictedArea"
                                              onChange={(e) => {
                                                setAccessDataList({
                                                  ...accessDataList,
                                                  deleteRestrictedArea:
                                                    e.target.checked === true
                                                      ? "true"
                                                      : "false",
                                                });
                                              }}
                                              defaultChecked={
                                                accessObject?.access
                                                  ?.deleteRestrictedArea ===
                                                "true"
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <label for="deleteRestrictedArea">
                                              Delete
                                            </label>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div
                        className="card pb-2"
                        style={{ background: "#F5F5F5" }}
                      >
                        <h6
                          className="text-center p-1"
                          style={{ background: "#c5bed1", color: "black" }}
                        >
                          Payment
                        </h6>
                        <div className="row">
                          <div className="col-lsm-12 col-lg-4 col-md-4">
                            <table className="m-2">
                              <thead>
                                <th>History</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="custom-checkbox d-flex">
                                      <div class="form-group m-2">
                                        <input
                                          type="checkbox"
                                          id="getHistoryList"
                                          onChange={(e) => {
                                            setAccessDataList({
                                              ...accessDataList,
                                              getHistoryList:
                                                e.target.checked === true
                                                  ? "true"
                                                  : "false",
                                            });
                                          }}
                                          defaultChecked={
                                            accessObject?.access
                                              ?.getHistoryList === "true"
                                              ? true
                                              : false
                                          }
                                        />
                                        <label for="getHistoryList">View</label>
                                      </div>
                                      {accessDataList?.getHistoryList ===
                                        "true" && (
                                        <>
                                          <div class="form-group m-2">
                                            <input
                                              type="checkbox"
                                              id="historyDownload"
                                              onChange={(e) => {
                                                setAccessDataList({
                                                  ...accessDataList,
                                                  historyDownload:
                                                    e.target.checked === true
                                                      ? "true"
                                                      : "false",
                                                });
                                              }}
                                              defaultChecked={
                                                accessObject?.access
                                                  ?.historyDownload === "true"
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <label for="historyDownload">
                                              Download
                                            </label>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-lsm-12 col-lg-4 col-md-4">
                            <table className="m-2">
                              <thead>
                                <th>Add Cash</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="custom-checkbox d-flex">
                                      <div class="form-group m-2">
                                        <input
                                          type="checkbox"
                                          id="getAddCashList"
                                          onChange={(e) => {
                                            setAccessDataList({
                                              ...accessDataList,
                                              getAddCashList:
                                                e.target.checked === true
                                                  ? "true"
                                                  : "false",
                                            });
                                          }}
                                          defaultChecked={
                                            accessObject?.access
                                              ?.getAddCashList === "true"
                                              ? true
                                              : false
                                          }
                                        />
                                        <label for="getAddCashList">View</label>
                                      </div>
                                      {accessDataList?.getAddCashList ===
                                        "true" && (
                                        <>
                                          <div class="form-group m-2">
                                            <input
                                              type="checkbox"
                                              id="addCashDownload"
                                              onChange={(e) => {
                                                setAccessDataList({
                                                  ...accessDataList,
                                                  addCashDownload:
                                                    e.target.checked === true
                                                      ? "true"
                                                      : "false",
                                                });
                                              }}
                                              defaultChecked={
                                                accessObject?.access
                                                  ?.addCashDownload === "true"
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <label for="addCashDownload">
                                              Download
                                            </label>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      {/* Game Setting */}
                      <div
                        className="card pb-2"
                        style={{ background: "#F5F5F5" }}
                      >
                        <h6
                          className="text-center p-1"
                          style={{ background: "#c5bed1", color: "black" }}
                        >
                          Game Setting
                        </h6>
                        <div className="row">
                          {/* Setting */}
                          <div className="col-sm-12 col-lg-6 col-md-6 ">
                            <table className="m-2">
                              <thead>
                                <th>Setting</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="custom-checkbox d-flex">
                                      <div class="form-group m-2">
                                        <input
                                          type="checkbox"
                                          id="getSetting"
                                          onChange={(e) => {
                                            setAccessDataList({
                                              ...accessDataList,
                                              getSetting:
                                                e.target.checked === true
                                                  ? "true"
                                                  : "false",
                                            });
                                          }}
                                          defaultChecked={
                                            accessObject?.access?.getSetting ===
                                            "true"
                                              ? true
                                              : false
                                          }
                                        />
                                        <label for="getSetting">View</label>
                                      </div>
                                      {accessDataList?.getSetting ===
                                        "true" && (
                                        <div class="form-group m-2">
                                          <input
                                            type="checkbox"
                                            id="editSetting"
                                            onChange={(e) => {
                                              setAccessDataList({
                                                ...accessDataList,
                                                editSetting:
                                                  e.target.checked === true
                                                    ? "true"
                                                    : "false",
                                              });
                                            }}
                                            defaultChecked={
                                              accessObject?.access
                                                ?.editSetting === "true"
                                                ? true
                                                : false
                                            }
                                          />
                                          <label for="editSetting">Edit</label>
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          {/* Social */}
                          <div className="col-sm-12 col-lg-6 col-md-6 ">
                            <table className="m-2">
                              <thead>
                                <th>Social</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="custom-checkbox d-flex">
                                      <div class="form-group m-2">
                                        <input
                                          type="checkbox"
                                          id="getSocial"
                                          onChange={(e) => {
                                            setAccessDataList({
                                              ...accessDataList,
                                              getSocial:
                                                e.target.checked === true
                                                  ? "true"
                                                  : "false",
                                            });
                                          }}
                                          defaultChecked={
                                            accessObject?.access?.getSocial ===
                                            "true"
                                              ? true
                                              : false
                                          }
                                        />
                                        <label for="getSocial">View</label>
                                      </div>
                                      {accessDataList?.getSocial === "true" && (
                                        <div class="form-group m-2">
                                          <input
                                            type="checkbox"
                                            id="editSocial"
                                            onChange={(e) => {
                                              setAccessDataList({
                                                ...accessDataList,
                                                editSocial:
                                                  e.target.checked === true
                                                    ? "true"
                                                    : "false",
                                              });
                                            }}
                                            defaultChecked={
                                              accessObject?.access
                                                ?.editSocial === "true"
                                                ? true
                                                : false
                                            }
                                          />
                                          <label for="editSocial">Edit</label>
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <button
                          style={{ width: "100px" }}
                          type="submit"
                          className="btn btn-primary btn-block mt-4 mr-2"
                          disabled={buttonLoader}
                        >
                          <div
                            className="d-flex"
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              className="d-flex"
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <b>Submit</b>
                            </div>
                            {buttonLoader && (
                              <div>
                                <div
                                  class="spinner-border ml-2 mt-1"
                                  role="status"
                                  style={{ height: "20px", width: "20px" }}
                                ></div>
                              </div>
                            )}
                          </div>
                        </button>
                        <button
                          style={{ width: "100px" }}
                          onClick={() => setShowPage(false)}
                          type="button"
                          className="btn btn-secondary btn-block mt-4 ms-2"
                        >
                          Close
                        </button>
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </Box>
          </div>
        </div>
      ) : (
        <div className="content p-3">
          <div class="card ">
            {/* <div class="pl-4 pr-4 pt-4">
              <h3 class="card-title">
                <b>Admin List</b>
              </h3>
              <div>
                <input
                  class="input-simple"
                  type="text"
                  placeholder="Search Email..."
                  value={searchSubType}
                  onChange={(e) => setSearchTourName(e.target.value)}
                />
              </div>
            </div> */}
            <div class="pl-4 pr-4 pt-4">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  {accessObject?.adminListAdd === "true" && (
                    <button
                      style={{ width: "118px" }}
                      onClick={() => {
                        openAddTournament(1);
                      }}
                      type="button"
                      className="btn btn-primary btn-block "
                    >
                      <AddIcon />
                      Add New
                    </button>
                  )}
                </div>
                <div className="mt-2 col-md-6 col-lg-6 d-flex justify-content-start justify-content-lg-end">
                  <div>
                    <input
                      class="input-simple"
                      type="text"
                      placeholder="Search Email..."
                      value={searchSubType}
                      onChange={(e) => setSearchTourName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body" style={{ overflow: "scroll" }}>
              <table id="" class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>SrNo</th>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {accessObject?.getAdminList === "true" ? (
                    !loader ? (
                      <>
                        {searchingData?.length > 0 ? (
                          <>
                            {searchingData
                              ?.reverse()
                              ?.slice(pagination.start, pagination.end)
                              ?.map((item, index) => (
                                <>
                                  <tr key={index}>
                                    <td>{index + 1 + pagination.start}</td>

                                    <td>
                                      {moment(item.createdAt).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>{item.name}</td>
                                    <td>{item.email}</td>

                                    <td className="d-flex justify-content-evenly ">
                                      {accessObject?.adminListEdit ===
                                        "true" && (
                                        <EditIcon
                                          className="mr-3 courser"
                                          onClick={() => clickEditButton(item)}
                                        />
                                      )}
                                      {accessObject?.adminListDelete ===
                                        "true" && (
                                        <ClearIcon
                                          className="courser text-danger"
                                          onClick={() =>
                                            warningWithConfirmMessage(item)
                                          }
                                        />
                                      )}
                                    </td>
                                  </tr>
                                </>
                              ))}
                          </>
                        ) : (
                          <>
                            <tr className="text-center">
                              <td colSpan={6}>
                                <h6>No Data Available</h6>
                              </td>
                            </tr>
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    <>
                      <tr className="text-center">
                        <td colSpan={6}>
                          <h6>Access Denied</h6>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
              <div className="mt-4">{loader && <Loader />}</div>
              {/* {accessObject?.getAdminList === "true" && (
                <div className="mt-4">{loader && <Loader />}</div>
              )} */}
            </div>
            {accessObject?.getAdminList === "true" &&
              searchingData.length !== 0 && (
                <Pagination
                  showPrePage={showPrePage}
                  onPaginationChange={onPaginationChange}
                  total={searchingData.length}
                />
              )}
          </div>
        </div>
      )}
    </>
  );
}
