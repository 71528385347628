import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// import axios from "axios";
// import { API_URL } from "../../config";
import Loader from "../Loader/Loader";
import { useAppContext } from "../../Lib/ContextLib";
// import socketIO from "socket.io-client";
import { toast } from "react-toastify";
import DataServices from "../../services/requestApi";

export default function ReactTables() {
  // let socket = socketIO(API_URL, { transports: ["websocket"] });

  const { accessObject } = useAppContext();
  const [dataRows, setdataRows] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [buttonLoader, setButtonLoader] = React.useState(false);

  React.useEffect(() => {
    getAllData();
    // axios(`${API_URL}/api/maintenance/admin/get-maintenance`, {
    //   method: "GET",
    //   credentials: "include",
    // })
    //   .then((response) => {
    //     //  setload(true)
    //     console.log(" gameSettings:::", response.data.message[0]);
    //     setdataRows(response.data.message);
    //     setLoader(false);
    //     // setyoutubeLink(response.data.message[0].youTubeLink)
    //   })
    //   .catch((error) => {
    //     console.log("getBranch error :::", error);
    //   });
  }, []);

  const getAllData = async () => {
    try {
      setLoader(true);
      const res = await DataServices.GetAllSocialList();
      console.log("Game Setting res::: ", res);
      if (res.data.status) {
        console.log("res.data.data::: ", res.data.data);
        setdataRows(res.data.data[0]);
        setLoader(false);
      }
    } catch (error) {
      toast.error("ERROR");
      console.log("ERROR::: ", error);
    }
  };

  const LoginSchema = Yup.object().shape({
    supportLink: Yup.string().required("required"),
    connectLink: Yup.string().required("required"),
    privacyPolicy: Yup.string().required("required"),
    termsCodition: Yup.string().required("required"),
    responsiblePlay: Yup.string().required("required"),
  });
  return (
    <>
      {!loader && (
        <div class=" p-3 ">
          <div className="card p-3 ">
            <h5 className="mt-3">Social</h5>
            {console.log("dataRows::: ", dataRows)}
            <Formik
              initialValues={{
                supportLink: dataRows?.supportLink || "",
                connectLink: dataRows?.connectLink || "",
                privacyPolicy: dataRows?.privacyPolicy || "",
                termsCodition: dataRows?.termsCodition || "",
                responsiblePlay: dataRows?.responsiblePlay || "",
              }}
              validationSchema={LoginSchema}
              onSubmit={async (values) => {
                try {
                  setButtonLoader(true);
                  const res = await DataServices.UpdateSocialDetails(
                    dataRows?._id,
                    values
                  );
                  console.log("Game Setting res::: ", res);
                  if (res.data.status) {
                    toast.success(res.data.message);
                    setButtonLoader(false);
                  }
                } catch (error) {
                  setButtonLoader(false);
                  toast.error("ERROR");
                  console.log("ERROR::: ", error);
                }

                // axios(`${API_URL}/api/maintenance/update-maintenance`, {
                //   method: "POST",
                //   credentials: "include",
                //   data: merged,
                // })
                //   .then((result) => {
                //     console.log("handleSubmit result :::", result.data);
                //     alert(result.data.message);
                //     socket.emit("maintenanceUpdate");
                //     setButtonLoader(false);
                //   })
                //   .catch((err) => {
                //     console.log("handleSubmit err :::", err);
                //   });
              }}
            >
              {({ touched, errors, isSubmitting, values }) => (
                <div style={{ width: "100%" }}>
                  <Form>
                    <div className="form-group">
                      <label htmlFor="password">Support Link</label>
                      <Field
                        type="text"
                        name="supportLink"
                        // disabled={
                        //   accessObject?.editSocial == "true" ? false : true
                        // }
                        placeholder="supportLink"
                        className={`mt-2 form-control
                          ${
                            touched.supportLink && errors.supportLink
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="supportLink"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Connect Link</label>
                      <Field
                        type="text"
                        // disabled={
                        //   accessObject?.editSocial == "true" ? false : true
                        // }
                        name="connectLink"
                        placeholder="connectLink"
                        className={`mt-2 form-control
                          ${
                            touched.connectLink && errors.connectLink
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="connectLink"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Privacy Policy</label>
                      <Field
                        type="text"
                        // disabled={
                        //   accessObject?.editSocial == "true" ? false : true
                        // }
                        name="privacyPolicy"
                        placeholder="privacyPolicy"
                        className={`mt-2 form-control
                          ${
                            touched.privacyPolicy && errors.privacyPolicy
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="privacyPolicy"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Terms & condition</label>
                      <Field
                        type="text"
                        name="termsCodition"
                        // disabled={
                        //   accessObject?.editSocial == "true" ? false : true
                        // }
                        placeholder="Terms & condition"
                        className={`mt-2 form-control
                          ${
                            touched.termsCodition && errors.termsCodition
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="termsCodition"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Responsible Play</label>
                      <Field
                        type="text"
                        name="responsiblePlay"
                        // disabled={
                        //   accessObject?.editSocial == "true" ? false : true
                        // }
                        placeholder="responsiblePlay"
                        className={`mt-2 form-control
                          ${
                            touched.responsiblePlay && errors.responsiblePlay
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="responsiblePlay"
                        // disabled={
                        //   accessObject?.editSocial == "true" ? false : true
                        // }
                        className="invalid-feedback"
                      />
                    </div>

                    {accessObject?.editSocial === "true" && (
                      <div className="d-flex">
                        <button
                          style={{ width: "100px" }}
                          type="submit"
                          className="btn btn-primary btn-block mt-4 mr-2"
                          disabled={buttonLoader}
                        >
                          <div
                            className="d-flex"
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              className="d-flex"
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <b>Submit</b>
                            </div>
                            {buttonLoader && (
                              <div>
                                <div
                                  class="spinner-border ml-2 mt-1"
                                  role="status"
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                  }}
                                ></div>
                              </div>
                            )}
                          </div>
                        </button>
                      </div>
                    )}
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      )}
      {loader && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      )}
    </>
  );
}
