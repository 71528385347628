import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import Pagination from "../../Pagination/Pagination";
import { useAppContext } from "../../Lib/ContextLib";
import { API_URL } from "../../config";
import Loader from "../Loader/Loader";

export default function GameHandle() {
  const { showPage, setShowPage, accessObject } = useAppContext();
  const [alert, setAlert] = React.useState(null);
  const [tableData, setTableData] = React.useState([]);
  const [rowValue, setRowValue] = React.useState();
  const [openValue, setOpenValue] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  // const [searchTourName] = React.useState();
  const [showPrePage] = React.useState(10);
  const [buttonLoader, setbuttonLoader] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    start: 0,
    end: showPrePage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const LoginSchema = Yup.object().shape({
    gameName: Yup.string().required("required"),
    // version: Yup.string().required("required"),
    bundleName: Yup.string().required("required"),
    gameNo: Yup.string().required("required"),
    server1: Yup.string().required("required"),
    server2: Yup.string().required("required"),
    server3: Yup.string().required("required"),
    minShowAmount: Yup.string().required("required"),
    maxShowAmount: Yup.string().required("required"),
    updateGame: Yup.string().required("required"),
    mobileType: Yup.string().required("required"),
  });

  React.useEffect(() => {
    getBranch();
  }, []);

  const getBranch = () => {
    setLoader(true);
    axios(`${API_URL}/api/game-handle/get-game-handle`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        setLoader(false);
        console.log("dataTable.dataRows :::", response.data.message);
        setTableData(response.data.message);
      })
      .catch((error) => {
        console.log("getBranch error :::", error);
      });
  };

  // const searchingData = [];
  // let data = [];
  // console.log("tableData 111 ---", tableData, searchTourName);
  // if (searchTourName) {
  //   console.log("tableData 222 ---", tableData, tableData.length > 0);
  //   data = tableData.filter((item) => {
  //     return Object.values(item?.tourName)
  //       .join("")
  //       .includes(searchTourName.toLowerCase());
  //   });
  //   console.log("data 111 ---", data);
  //   data?.map((dataItem) => searchingData.push(dataItem));
  // } else {
  //   tableData.map((dataItem) => searchingData.push(dataItem));
  // }

  const clickEditButton = (item) => {
    setShowPage(true);
    setRowValue(item);
    setOpenValue(2);
  };

  const openAddTournament = (value) => {
    setRowValue();
    console.log("openAddTournament :::", value);
    setShowPage(true);
    setOpenValue(value);
  };

  const updateTournament = (value) => {
    let merged = { ...value, id: rowValue._id };
    console.log("updateTournament merged value :::", merged);

    axios
      .post(`${API_URL}/api/game-handle/update-game-handle`, merged)
      .then((res) => {
        console.log("updateTournament update :::", res.data);
        if (res.data.isValid) {
          successEdit(res.data.message);
          setbuttonLoader(false);
        } else {
          warningAlert();
          setbuttonLoader(false);
        }
      })
      .catch((err) => {
        setbuttonLoader(false);
        console.log("updateTournament err :::", err);
        warningAlert();
      });
  };

  const addNewTournament = (value) => {
    console.log("addNewTournament ---", value);

    axios(`${API_URL}/api/game-handle/add-game-handle`, {
      method: "POST",
      credentials: "include",
      data: value,
    })
      .then((result) => {
        console.log("addNewTournament result :::", result.data);
        if (result.data.isValid) {
          setbuttonLoader(false);
          successAdd();
        } else {
          warningAlert();
          setbuttonLoader(false);
        }
      })
      .catch((err) => {
        console.log("addNewTournament err :::", err);
        warningAlert();
      });
  };

  const warningWithConfirmMessage = (e) => {
    console.log("donw");
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title="Are you sure?"
        onConfirm={() => {
          successDeleted();
          deleteData(e);
          //  setIsPlayerEditButtonClicked(false);
        }}
        onCancel={() => setAlert(null)}
        // confirmBtnCssClass={classes.button + " " + classes.danger}
        // cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      ></SweetAlert>
    );
  };

  const successDeleted = () => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title="Deleted!"
        onConfirm={() => {
          getBranch();
          setAlert(null);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      >
        Your Game Handle Record has been deleted...
      </SweetAlert>
    );
  };

  const deleteData = (e) => {
    console.log("e===>", e._id);
    axios
      .post(`${API_URL}/api/game-handle/delete-game-handle/${e._id}`)
      .then((res) => {
        console.log(res);
        console.log(res.data);
        getBranch();
      });
  };

  const successAdd = () => {
    console.log("done");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title="Added successfully!"
        onConfirm={() => {
          getBranch();
          setAlert(null);
          setShowPage(false);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      >
        Your Game Handle Record has been Added...
      </SweetAlert>
    );
  };

  const successEdit = () => {
    console.log("donw");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title="Edited successfully!"
        onConfirm={() => {
          getBranch();
          setAlert(null);
          setShowPage(false);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      >
        Your Game Handle Record has been Edited ...
      </SweetAlert>
    );
  };

  const warningAlert = () => {
    console.log("donw");
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title="Something Went Wrong .....!"
        onConfirm={() => {
          //  getBranch();
          setAlert(null);
          //  setShowPage(false);
          // setIsPlayerEditButtonClicked(false);
        }}
        confirmBtnBsStyle="success"
      ></SweetAlert>
    );
  };

  return (
    <>
      {alert}
      {showPage ? (
        <div class="content p-3 ">
          <div className="card p-3">
            <Box sx={{ width: "100%" }}>
              <Formik
                initialValues={{
                  gameName: rowValue ? rowValue.gameName : "",
                  version: rowValue ? rowValue.version : [""],
                  bundleName: rowValue ? rowValue.bundleName : "",
                  gameNo: rowValue ? rowValue.gameNo : "",
                  server1: rowValue ? rowValue.server1 : "",
                  server2: rowValue ? rowValue.server2 : "",
                  server3: rowValue ? rowValue.server3 : "",
                  minShowAmount: rowValue ? rowValue.minShowAmount : "",
                  maxShowAmount: rowValue ? rowValue.maxShowAmount : "",
                  updateGame: rowValue ? rowValue?.updateGame : "",
                  mobileType: rowValue ? rowValue?.mobileType : "",
                }}
                validationSchema={LoginSchema}
                onSubmit={(values) => {
                  if (parseInt(openValue) === 1) {
                    addNewTournament(values);
                  }
                  if (parseInt(openValue) === 2) {
                    updateTournament(values);
                  }
                }}
              >
                {({ touched, errors, isSubmitting, values, setFieldValue }) => (
                  <div>
                    <Form>
                      {/* gameName */}
                      <div className="form-group">
                        <label htmlFor="password" className="mt-3">
                          Game Name
                        </label>
                        <Field
                          type="text"
                          name="gameName"
                          placeholder="Game Name"
                          // disabled={
                          //   accessObject?.bonusEdit == "true" ? false : true
                          // }
                          className={` form-control
                          ${
                            touched.gameName && errors.gameName
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="gameName"
                          className="invalid-feedback"
                        />
                      </div>
                      {/* version */}
                      {/* <div className="form-group">
                        <label htmlFor="password" className="mt-3">
                          Version
                        </label>
                        <Field
                          type="text"
                          name="version"
                          placeholder="Version"
                          // disabled={
                          //   accessObject?.bonusEdit == "true" ? false : true
                          // }
                          className={` form-control
                          ${
                            touched.version && errors.version
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="version"
                          className="invalid-feedback"
                        />
                      </div> */}
                      <FieldArray
                        name="version"
                        render={(arrayHelpers) => (
                          <div>
                            {values.version.map((version, index) => (
                              <div key={index}>
                                {/* <Field name={`version.${index}`} /> */}
                                <div className="form-group">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {index === 0 && (
                                      <label
                                        htmlFor="password"
                                        className="mt-3"
                                      >
                                        Version
                                      </label>
                                    )}
                                    {index === 0 && (
                                      <button
                                        className="btn btn-primary m-1 "
                                        type="button"
                                        onClick={() =>
                                          arrayHelpers.insert(index, "")
                                        } // insert an empty string at a position
                                      >
                                        +
                                      </button>
                                    )}
                                  </div>

                                  <div className="d-flex">
                                    <Field
                                      type="text"
                                      name={`version.${index}`}
                                      placeholder="version"
                                      // disabled={
                                      //   accessObject?.bonusEdit == "true" ? false : true
                                      // }
                                      className={` form-control`}
                                    />
                                    <button
                                      className="btn btn-danger mx-1"
                                      type="button"
                                      disabled={values.version.length === 1}
                                      onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                    >
                                      -
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      />
                      {/* bundleName */}
                      <div className="form-group">
                        <label htmlFor="password" className="mt-3">
                          Bundle Name
                        </label>
                        <Field
                          type="text"
                          name="bundleName"
                          placeholder="Bundle Name"
                          // disabled={
                          //   accessObject?.bonusEdit == "true" ? false : true
                          // }
                          className={` form-control
                          ${
                            touched.bundleName && errors.bundleName
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="bundleName"
                          className="invalid-feedback"
                        />
                      </div>
                      {/* gameNo */}
                      <div className="form-group">
                        <label htmlFor="password" className="mt-3">
                          Game No
                        </label>
                        <Field
                          type="number"
                          name="gameNo"
                          placeholder="Game No"
                          // disabled={
                          //   accessObject?.bonusEdit == "true" ? false : true
                          // }
                          className={` form-control
                          ${
                            touched.gameNo && errors.gameNo ? "is-invalid" : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="gameNo"
                          className="invalid-feedback"
                        />
                      </div>
                      {/* server1 */}
                      <div className="form-group">
                        <label htmlFor="password" className="">
                          Server 1
                        </label>
                        <Field
                          type="text"
                          name="server1"
                          placeholder="Server1 1"
                          // disabled={
                          //   accessObject?.bonusEdit == "true" ? false : true
                          // }
                          className={` form-control
                          ${
                            touched.server1 && errors.server1
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="server1"
                          className="invalid-feedback"
                        />
                      </div>
                      {/* server2 */}
                      <div className="form-group">
                        <label htmlFor="password" className="">
                          Server 2
                        </label>
                        <Field
                          type="text"
                          name="server2"
                          placeholder="Server 2"
                          // disabled={
                          //   accessObject?.bonusEdit == "true" ? false : true
                          // }
                          className={` form-control
                          ${
                            touched.server2 && errors.server2
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="server2"
                          className="invalid-feedback"
                        />
                      </div>
                      {/* server3 */}
                      <div className="form-group">
                        <label htmlFor="password" className="">
                          Server 3
                        </label>
                        <Field
                          type="text"
                          name="server3"
                          placeholder="Server 3"
                          // disabled={
                          //   accessObject?.bonusEdit == "true" ? false : true
                          // }
                          className={` form-control
                          ${
                            touched.server3 && errors.server3
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="server3"
                          className="invalid-feedback"
                        />
                      </div>
                      {/* minShowAmount */}
                      <div className="form-group">
                        <label htmlFor="password" className="">
                          Min Show Amount
                        </label>
                        <Field
                          type="text"
                          name="minShowAmount"
                          placeholder="Min Show Amount"
                          // disabled={
                          //   accessObject?.bonusEdit == "true" ? false : true
                          // }
                          className={` form-control
                          ${
                            touched.minShowAmount && errors.minShowAmount
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="minShowAmount"
                          className="invalid-feedback"
                        />
                      </div>
                      {/* maxShowAmount */}
                      <div className="form-group">
                        <label htmlFor="password" className="">
                          Max Show Amount
                        </label>
                        <Field
                          type="text"
                          name="maxShowAmount"
                          placeholder="Max Show Amount"
                          // disabled={
                          //   accessObject?.bonusEdit == "true" ? false : true
                          // }
                          className={` form-control
                          ${
                            touched.maxShowAmount && errors.maxShowAmount
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="maxShowAmount"
                          className="invalid-feedback"
                        />
                      </div>
                      {/* updateGame */}
                      <div className="form-group">
                        <label htmlFor="password" className="mt-3">
                          Update Game
                        </label>
                        <Field
                          as="select"
                          name="updateGame"
                          className={` form-control
                          ${
                            touched.updateGame && errors.updateGame
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="">Select Status</option>
                          <option value="on">On</option>
                          <option value="off">Off</option>
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="updateGame"
                          className="invalid-feedback"
                        />
                      </div>
                      {/* mobileType */}
                      <div className="form-group mt-2">
                        <label htmlFor="password">Mobile Type</label>
                        <Field
                          as="select"
                          name="mobileType"
                          className={`mt-2 form-control
                          ${
                            touched.mobileType && errors.mobileType
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="">Select Mobile Type ..</option>
                          <option value="android">Android</option>
                          <option value="ios">IOS</option>
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="mobileType"
                          className="invalid-feedback"
                        />
                      </div>
                      <button
                        style={{ width: "100px" }}
                        type="submit"
                        className="btn btn-primary btn-block mt-4 mr-2"
                        disabled={buttonLoader}
                      >
                        <div
                          className="d-flex"
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            className="d-flex"
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <b>Submit</b>
                          </div>
                          {buttonLoader && (
                            <div>
                              <div
                                class="spinner-border ml-2 mt-1"
                                role="status"
                                style={{ height: "20px", width: "20px" }}
                              ></div>
                            </div>
                          )}
                        </div>
                      </button>
                    </Form>
                  </div>
                )}
              </Formik>
            </Box>
          </div>
        </div>
      ) : (
        <div className="content p-3">
          <div class="card ">
            {/* <div
              class="pl-4 pr-4 pt-4"
              style={{ display: "flex", justifyContent: "space-between" }}
                          > */}
            <div className="row pl-4 pr-4 pt-4">
              <div className="col-5">
                {accessObject?.editGameHandle === "true" && (
                  <button
                    style={{ width: "118px" }}
                    onClick={() => {
                      openAddTournament(1);
                    }}
                    type="button"
                    className="btn btn-primary btn-block "
                  >
                    <AddIcon />
                    Add New
                  </button>
                )}
              </div>
              <div className="col-7">
                <h3 class="card-title">
                  <b>Game Handle</b>
                </h3>
              </div>
            </div>

            {/* <div style={{ display: "none" }}>
                <b className="mr-2">Tournament Name :</b>
                <input
                  placeholder="Enter Tournament Name"
                  value={searchSubType}
                  onChange={(e) => setSearchTourName(e.target.value)}
                />
              </div> */}
            {/* </div> */}

            <div class="card-body" style={{ overflow: "scroll" }}>
              <table id="" class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>SrNo</th>
                    <th>Game Name</th>
                    <th>Version</th>
                    <th>Bundle Name</th>
                    <th>Game No</th>
                    <th>Min Show Amount</th>
                    <th>Max Show Amount</th>
                    {(accessObject?.editGameHandle === "true" ||
                      accessObject?.deleteGameHandle === "true") && (
                      <th style={{ textAlign: "center" }}>Action</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {!loader ? (
                    <>
                      {tableData.length > 0 ? (
                        <>
                          {tableData
                            ?.slice(pagination.start, pagination.end)
                            .map((item, index) => (
                              <>
                                <tr key={index}>
                                  <td>{index + 1 + pagination.start}</td>
                                  <td>{item.gameName}</td>
                                  <td>{item.version}</td>
                                  <td>{item.bundleName}</td>
                                  <td>{item.gameNo}</td>
                                  <td>{item.minShowAmount}</td>
                                  <td>{item.maxShowAmount}</td>
                                  {(accessObject?.editGameHandle === "true" ||
                                    accessObject?.deleteGameHandle ===
                                      "true") && (
                                    <td className="justify-content-evenly ">
                                      <div className="d-flex">
                                        {accessObject?.editGameHandle ===
                                          "true" && (
                                          <EditIcon
                                            className="mr-3 courser"
                                            onClick={() =>
                                              clickEditButton(item)
                                            }
                                          />
                                        )}
                                        {accessObject?.deleteGameHandle ===
                                          "true" && (
                                          <ClearIcon
                                            className="courser text-danger"
                                            onClick={() =>
                                              warningWithConfirmMessage(item)
                                            }
                                          />
                                        )}
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              </>
                            ))}
                        </>
                      ) : (
                        <>
                          <tr
                            style={{ backgroundColor: "whitesmoke" }}
                            className="text-center"
                          >
                            <td colSpan={6}>
                              <h5>No Data Available</h5>
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
              <div className="mt-4">{loader && <Loader />}</div>
            </div>
            {tableData.length !== 0 && (
              <Pagination
                showPrePage={showPrePage}
                onPaginationChange={onPaginationChange}
                total={tableData.length}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
